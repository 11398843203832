import "./TermsConditions.scss";
import { useEffect } from "react";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import TermsConditionsComponent from "./TermsConditionsComponent";
import { useTranslation } from "react-i18next";

const TermsConditions = () => {

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <Header />
      <div className="term-condition-wrapper">
        <div className="term-condition-head">
          <h3 className="h3">{t('terms_Conditions')}</h3>
        </div>
        <TermsConditionsComponent />
      </div>
      <Footer />
    </div>
  );
};

export default TermsConditions;
