import {useState, useEffect, useRef, useLayoutEffect} from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MyMapComponent = withScriptjs(withGoogleMap((props) => {

    const qatarBounds = {
      north: 26.2900, // Upper latitude
      south: 24.4850, // Lower latitude
      east: 51.6363, // Eastern longitude
      west: 50.7500, // Western longitude
    };

    let [latitude, setLatitude] = useState(25.31810373877699)
    let [longitude, setLongitude] = useState(51.50027361022315)
    let [center, setCenter] = useState({ lat: 25.31810373877699, lng: 51.50027361022315 });
    let [mapDefault, setMapDefault] = useState({ lat: 25.31810373877699, lng: 51.50027361022315 });
    let [mapCenter, setMapCenter] = useState({ lat: 25.31810373877699, lng: 51.50027361022315 });
    const [showSearch, setShowSearch] = useState(false);

    const refMap = useRef(null);

    const handleBoundsChanged = () => {
      const mapCenter = refMap.current.getCenter();
      const latitude = mapCenter.lat();
      const longitude = mapCenter.lng();
      setCenter(mapCenter);
      setLatitude(latitude);
      setLongitude(longitude);
    };

    const setLatLng = (lat, lng) => {
        setLatitude(lat)
        setLongitude(lng)
    }

    const handleDragEnd = () => {
      props.ChangeAddress(latitude, longitude)
    }


    useLayoutEffect(() => {
      if (props.lat !== '' || props.lng !== '') {
        latitude = props.lat !== '' && props.lat !== 0 ? parseFloat(props.lat) : 25.31810373877699;
        longitude = props.lng !== '' && props.lng !== 0? parseFloat(props.lng) : 51.50027361022315;
        setLatitude(latitude)
        setLongitude(longitude)
        mapCenter = { lat: latitude, lng: longitude };
        setMapCenter(mapCenter)
      }
    }, [props])

    useEffect(() => {
      const getData = setTimeout(() => {
        if (typeof center?.lat === 'function') {
          setLatLng(center?.lat(), center?.lng());
        } else {
          setLatLng(center?.lat, center?.lng);
        }
      }, 500);
      return () => clearTimeout(getData);
    }, [center]);

    useEffect(() => {
      setTimeout(() => {
        setShowSearch(true)
      }, 1000);
    }, [])

  return (
    <GoogleMap
      ref={refMap}
      defaultZoom={17}
      defaultCenter={mapDefault}
      center={mapCenter}
      onBoundsChanged={handleBoundsChanged}
      onDragEnd={() => {
        handleDragEnd();
      }}
      defaultOptions={{
        restriction: {
          latLngBounds: qatarBounds,
          strictBounds: true,
        },
      }}
    ><Marker position={{ lat: latitude, lng: longitude }} /></GoogleMap>
)}))

export default MyMapComponent