import { createSlice } from "@reduxjs/toolkit";

export const storePayouts = createSlice({
  name: "storePayouts",
  initialState: {
    value: null,
  },
  reducers: {
    changeStorePayoutsData: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeStorePayoutsData } = storePayouts.actions;
export const currentStorePayoutsData = (state) => state.storePayouts.value;
export default storePayouts.reducer;
