import { useEffect, useState } from "react";
import "./addProduct.scss";
import TextField from "@mui/material/TextField";
import { Grid, InputLabel } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import arrowLeftBlack from "../../../assets/images/structure/arrow-left-black.svg";
import defaultImage from "../../../assets/images/structure/default.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
// import { currentLocalData } from "../../../redux/reducers/localData";
import { API, PAGES } from "../../../Enum";
import globalRequest from "../../../global-modules/globalRequest";
import { currentMainProductData } from "../../../redux/reducers/mainEditProductData";
import { API_URL } from "../../../BaseUrl";
import { changePage } from "../../../redux/reducers/page";
import { useTranslation } from "react-i18next";
import { getAllBarcodes } from "../../../Helpers/Helpers";

const EditProduct = () => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  // const localData = useSelector(currentLocalData);
  // const tokenData = localData.token;
  // const adminCommission = tokenData?.store?.admin_commission;
  const currentProductData = useSelector(currentMainProductData);

  let [productName, setProductName] = useState("");
  let [productNameArabic, setProductNameArabic] = useState("");
  let [uoms, setUoms] = useState([]);
  let [unitData, setUnitData] = useState([]);
  
  const getUnitData = () => {
    globalRequest("get", API.GET_UNIT, {}, {}, true, true).then((res) => {
      let data = res?.data;
      if (data?.ack == 1) {
        unitData = data?.Units;
        setUnitData(unitData);
      }
    }).catch((err) => {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t("somethingWentWrong"),
          state: "error",
        })
      );
    });
  };
  
  useEffect(() => {
    getUnitData();
    globalRequest("get", API.EDIT_PRODUCT_REQUEST(currentProductData.id), {}, {}, true, true).then((res) => {
      if (res?.data?.ack === 1) {
        let productData = res?.data?.product;
        if (productData !== null) {
          productName = productData?.title;
          setProductName(productName);
          productNameArabic = productData?.title_arabic;
          setProductNameArabic(productNameArabic);
          const uom_data = productData?.uom_data !== ''?
            JSON.parse(productData?.uom_data):[];
          uoms = [];
          if (uom_data.length > 0) {
            uom_data.filter(
              (obj) => obj?.type !== 'new'
            ).forEach(element => {
              if (
                element?.addingBarcode  ||
                element?.addingImage ||
                element?.uomName ||
                element?.price ||
                element?.salePrice ||
                element?.relationWithBase
              ) {
                let ImageObject = [];
                if (element?.addingImage && element?.addingImage.length > 0) {
                  const Images = element?.addingImage
                  const frontImage = Images?.find((obj) => obj?.type === 'front');
                  const openImage = Images?.find((obj) => obj?.type === 'open');
                  const closeupImage = Images?.find((obj) => obj?.type === 'closeup');
                  const topImage = Images?.find((obj) => obj?.type === 'top');
                  const sideImage = Images?.find((obj) => obj?.type === 'side');
                  if (frontImage) {
                    ImageObject.push({
                      name: t("manageProducts_FrontViewFeatured"),
                      type: "front",
                      product_image: API_URL + frontImage?.image
                    })
                  }
                  if (openImage) {
                    ImageObject.push({
                      name: t("manageProducts_OpenView"),
                      type: "open",
                      product_image: API_URL + openImage?.image
                    })
                  }
                  if (closeupImage) {
                    ImageObject.push({
                      name: t("manageProducts_CloseUpView"),
                      type: "closeup",
                      product_image: API_URL + closeupImage?.image
                    })
                  }
                  if (topImage) {
                    ImageObject.push({
                      name: t("manageProducts_TopView"),
                      type: "top",
                      product_image: API_URL + topImage?.image
                    })
                  }
                  if (sideImage) {
                    ImageObject.push({
                      name: t("manageProducts_SideView"),
                      type: "side",
                      product_image: API_URL + sideImage?.image
                    })
                  }
                }
                uoms.push({
                  addingBarcode: (element?.addingBarcode && element?.addingBarcode !== '')?element.addingBarcode:'',
                  removeBarcode: (element?.removeBarcode && element?.removeBarcode !== '')?element.removeBarcode:'',
                  addingImage: ImageObject,
                  uomName: (element?.uomName && element?.uomName !== '')?element.uomName:'',
                  unitId: (element?.unitId && element?.unitId !== '')?element.unitId:'',
                  price: (element?.price && element?.price !== '')?element.price:'',
                  salePrice: (element?.salePrice && element?.salePrice !== '')?element.salePrice:'',
                  uomId: (element?.uomId && element?.uomId !== '')?element.uomId:'',
                  relationWithBase: (element?.relationWithBase && element?.relationWithBase !== '')?element.relationWithBase:'',
                });
              }
            });
          }
          const newUoms = productData?.product?.uoms.filter((obj) => obj?.status === 'pending');
          if (newUoms.length > 0) {
            newUoms.forEach(element => {
              let ImageObject = [];
              if (element?.uom_images && element?.uom_images.length > 0) {
                const Images = element?.uom_images
                const frontImage = Images?.find((obj) => obj?.type === 'front');
                const openImage = Images?.find((obj) => obj?.type === 'open');
                const closeupImage = Images?.find((obj) => obj?.type === 'closeup');
                const topImage = Images?.find((obj) => obj?.type === 'top');
                const sideImage = Images?.find((obj) => obj?.type === 'side');
                if (frontImage) {
                  ImageObject.push({
                    name: t("manageProducts_FrontViewFeatured"),
                    type: "front",
                    product_image: API_URL + frontImage?.image
                  })
                }
                if (openImage) {
                  ImageObject.push({
                    name: t("manageProducts_OpenView"),
                    type: "open",
                    product_image: API_URL + openImage?.image
                  })
                }
                if (closeupImage) {
                  ImageObject.push({
                    name: t("manageProducts_CloseUpView"),
                    type: "closeup",
                    product_image: API_URL + closeupImage?.image
                  })
                }
                if (topImage) {
                  ImageObject.push({
                    name: t("manageProducts_TopView"),
                    type: "top",
                    product_image: API_URL + topImage?.image
                  })
                }
                if (sideImage) {
                  ImageObject.push({
                    name: t("manageProducts_SideView"),
                    type: "side",
                    product_image: API_URL + sideImage?.image
                  })
                }
              }
              uoms.push({
                addingBarcode: getAllBarcodes(element?.uom_barcode_relations),
                removeBarcode: '',
                addingImage: ImageObject,
                uomName: element.uomName,
                unitId: element.unitId,
                price: element.price,
                salePrice: element.salePrice,
                uomId: element.id,
                relationWithBase: element.relationWithBase,
              });
            });
          }

          setUoms(uoms);
        }
      } else {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("somethingWentWrong"),
            state: "error",
          })
        );
      }
    }).catch((err) => {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t("somethingWentWrong"),
          state: "error",
        })
      );
    });

  }, [currentProductData]);

  return (
    <div className="main-mid-wrapper">
      <div className="add-product-wrapper">
        <div className="add-product-head">
          <img
            src={arrowLeftBlack}
            className="icon24 rotate180"
            alt="arrowLeftBlack"
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(changePage(PAGES.MANAGE_PRODUCT))}
          />
          <h3 className="h3">
            {t("manageProducts_editProduct")}
          </h3>
        </div>

        {productName !== '' || productNameArabic !== ''?
          <div className="card-white">
          <h6 className="h6">{t("manageProducts_BasicInfo")}</h6>
          <Grid
            container
            columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
            xs={12} sm={12} md={12} lg={10}
          >
            {productName !== ''?<Grid item xs={12}>
              <div className="form-group noEvent">
                <TextField
                  value={productName}
                  label={t("manageProducts_ProductTitle_256characters")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="mobile-bottom">
                        {productName.length}/256
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 256 }}
                />
              </div>
            </Grid>: null}
            {productNameArabic !== ''?<Grid item xs={12}>
              <div className="form-group noEvent">
                <TextField
                  value={productNameArabic}
                  label={t("manageProducts_ProductTitle_256characters")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="mobile-bottom">
                        {productNameArabic.length}/256
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 256 }}
                />
              </div>
            </Grid>: null}
          </Grid>
        </div>: null}

        {uoms.length > 0?
        <div className="card-white">
          <h6 className="h6">{t("manageProducts_UOMInfo")}</h6>
          {uoms.map((uom, index) => 
          <span key={`uom-${index}`}>
            <Grid
              container
              columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
              xs={12} sm={12} md={12} lg={10}
            >
            {uom?.addingBarcode !== ''?<Grid item xs={12} sm={6}>
              <div className="form-group noEvent">
                <TextField
                  value={uom?.addingBarcode}
                  label={t("manageProducts_Barcode")}
                />
              </div>
            </Grid>: null}
            {uom?.uomName !== ''?<Grid item xs={12} sm={6}>
              <div className="form-group noEvent">
                <TextField
                  label={t("manageProducts_Quantity_Unit")}
                  value={uom?.uomName}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="bg-pink">
                        {unitData.find(unit => unit.id === uom?.unitId)?.unit_locales[0]?.name}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>: null}
              {uom?.price !== '' && uom?.price !== 0?<Grid item xs={12} sm={6}>
                <div className="form-group noEvent">
                  <TextField
                    label={t("manageProducts_Price")}
                    value={uom?.price}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="bg-pink">
                          {t("manageProducts_QAR")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>:null}
              {uom?.salePrice !== '' && uom?.salePrice !== 0?<Grid item xs={12} sm={6}>
                <div className="form-group noEvent">
                  <TextField
                    value={uom?.salePrice}
                    label={t("manageProducts_SalePrice")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="bg-pink">
                          {t("manageProducts_QAR")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>: null}
              {uom?.relationWithBase!=='' && uom?.relationWithBase!==0? <Grid item xs={12} sm={6}>
                <div className="form-group noEvent">
                  <TextField
                    value={uom?.relationWithBase}
                    label={t("manageProducts_RelationWithBase")}
                  />
                </div>
              </Grid>: null}
            </Grid>
            {uom?.addingImage.length > 0?<div>
              <h6 className="h6 mb-4">{t("manageProducts_ProductImages")}</h6>
              <Grid container xs={12} sm={12} md={12} lg={10}>
                <div className="uploads-row">
                  {uom?.addingImage.map((obj, index) =>
                    <div className="" key={index}>
                      <div className="upload-multiple">
                        <img src={obj.product_image} alt=".."
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = defaultImage;
                          }}
                        className="id-img" />
                      </div>
                      <InputLabel >{obj?.name}</InputLabel>
                    </div>
                  )}
                </div>
              </Grid>
            </div>: null}
            {((uoms.length -1) !== index)?<hr className="mt-4 mb-4" />:null}
          </span>)}
        </div>: null}
      </div>
    </div>
  );
};

export default EditProduct;
