import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const SearchSelectStore = ({
  options,
  value,
  changeSelectedStores,
}) => {

  const handleOptionsChange = (event, newValues) => {
    let selectedValues = newValues.map((option) => option.value)
    if (newValues.length < value.length) {
      if (value.includes(0) && !selectedValues.includes(0)) {
        selectedValues = [];
      } else {
        selectedValues = selectedValues.filter((value) => value != 0)
      }
    } else {
      if (selectedValues.includes(0)) {
        selectedValues = options.map((option) => option.value)
      }
    }
    let checkForAll = selectedValues.filter((value) => value != 0)
    if (checkForAll.length === options.length - 1) {
      selectedValues.push(0)
    }
    changeSelectedStores(selectedValues);
  };

  return (
    <>
      <Autocomplete
        required
        options={options}
        getOptionLabel={(option) => option.label}
        value={options.filter((option) => value.includes(option.value))}
        onChange={handleOptionsChange}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disableCloseOnSelect
        disabled={options.length === 1}
        multiple
        renderInput={(params) => (
          <TextField {...params} label="Stores" variant="outlined" />
        )}
        renderOption={(props, option) => (
          <List className="store-dropdown">
            <ListItem {...props}>
              <Checkbox
                checked={
                  value.findIndex((value) => value === option.value) > -1
                }
              />
              <ListItemText
                primary={option.label}
                secondary={`${option.secondLabel}`}
              />
            </ListItem>
          </List>
        )}
        renderTags={(value, getTagProps) =>
          value.length === options.length && options.length > 1 ?
          <div>All Stores</div> :
          value.map((option, index) => (
            <div key={index} {...getTagProps({ index })}>
              {option.label} {value.length -1 != index?  ", ":""}
            </div>
          ))
        }
      />
    </>
  );
};

export default SearchSelectStore;
