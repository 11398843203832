import { configureStore } from "@reduxjs/toolkit";
import editProductData from "./reducers/editProductData";
import loader from "./reducers/loader";
import localData from "./reducers/localData";
import mainEditProductData from "./reducers/mainEditProductData";
import orderTab from "./reducers/orderTab";
import page from "./reducers/page";
import snackbar from "./reducers/snackbar";
import editStoreData from "./reducers/editStoreData";
import recallGetStore from "./reducers/recallGetStore";
import earningAndPayoutData from "./reducers/earningAndPayoutData";
import  myNewOrder  from "./reducers/myNewOrder";
import notification from "./reducers/notification";
import storeTiming from "./reducers/storeTiming";
import storePayouts from "./reducers/storePayouts";
import baladiInfo from "./reducers/baladiInfo";

export default configureStore({
  reducer: {
    loader: loader,
    snackbar: snackbar,
    localData: localData,
    page: page,
    editProductData: editProductData,
    mainEditProductData: mainEditProductData,
    orderTab: orderTab,
    editStoreData: editStoreData,
    recallGetStore: recallGetStore,
    earningAndPayoutData: earningAndPayoutData,
    myNewOrder:myNewOrder,
    notification:notification,
    storeTiming: storeTiming,
    storePayouts: storePayouts,
    baladiInfo: baladiInfo,
  },
});
