/**
 * @description - constant for Months in a year
 * @type {string[]} - array of months
 */
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/**
 * @description - get last 10 years from current year
 * @returns {number[]} - array of last 10 years
 * @example - getLastTenYears() => [2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011]
 */
export const getLastTenYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < 10; i++) {
    years.push(currentYear - i);
  }
  return years;
};

/**
 * @description - This function is used to display price in the format of 1,000.00
 * @param {number} price - Price to be formatted
 * @returns {string} - Formatted price
 * @example - formatPrice(1000) => 1,000.00
 * @example - formatPrice(1000.5) => 1,000.50
 */
export const formatPrice = (price) => {
  return price ? price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") : '-';
};

/**
 * @description convert time to 12 hour format
 * @param {*} time - time in 24 hour format
 * @returns string - time in 12 hour format
 */
export const convertTimeTo12Hour = (time) => {
  const hours = parseInt(time.split(":")[0], 10);
  const minutes = parseInt(time.split(":")[1], 10);
  const am_pm = hours >= 12 ? "PM" : "AM";
  let hour = hours % 12;
  hour = hour === 0 ? 12 : hour;
  const hourStr = hour < 10 ? `0${hour}` : hour;
  const minuteStr = minutes < 10 ? `0${minutes}` : minutes;
  return `${hourStr}:${minuteStr} ${am_pm}`;
};

/**
 * @description validate Password based on regex pattern
 * @should contain at least 8 characters and at most 20
 *  characters and must contain at least one numeric digit and any string of characters and (@, #, $, *, _,)
 * @param {*} password - password string
 * @returns boolean - true if password is valid else false
 */
export const validatePassword = (password) => {
  const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9@#$*_]+){8,20}$/;
  return passwordRegex.test(password);
};

/**
 * @description get front image url in the array
 * @param {*} images - array of images
 * @returns string - front image url
 */
export const getFrontImage = (images) => {
  if (images?.length > 0) {
    const frontImage = images?.find((image) => image.type === "front");
    return frontImage ? frontImage?.image : images[0].image;
  }
};

/**
 * @description get back en locale
 * @param {*} locale - locale string
 * @returns string - locale data based on locale type
 */
export const getdataByKey = (data, key='', type='en') => {
  let newData = "";
  data?.forEach((item) => {
    if (item?.locale == type) newData = item[key]
  });
  if (newData == "") {
    data?.forEach((item) => {
      newData = item[key]
    });
  }
  return newData;
};

/**
 * @description get back locale data based on locale
 * @param {*} locale - locale string 
 * @returns string - locale data
 */
export const getDataByKeyLocale = (data, key='') => {
  const type = localStorage.getItem('i18nextLng');
  let newData = "";
  data?.forEach((item) => {
    if (item?.locale == type) newData = item[key]
  });
  if (newData == "") {
    data?.forEach((item) => {
      newData = item[key]
    });
  }
  return newData;
};

/**
 * @description get back all barcodes in a string format separated by comma
 * @param {*} barcodes - array of barcodes
 * @returns string - all barcodes separated by comma
 */
export const getAllBarcodes = (barcodes) => {
  let allBarcodes = [];
  barcodes?.forEach((barcode) => {
    allBarcodes.push(barcode.barcode);
  });
  return allBarcodes.join(", ");
}

/**
 * @description get back count based on status
 * @param {*} orders - array of orders
 * @param {*} status - status string
 * @returns number - count of orders based on status
 */

export const getCountByStatus = (orders, status) => {
  return orders?.find((order) => order.type === status)?.count || 0;
}

/**
 * @description function to get all hours in a day with 10 minutes interval
 * @returns 
 */
export const generateHours = (timeDifference=10) => {
  const hours = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += timeDifference) {
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      const time = `${formattedHour}:${formattedMinute}`;
      hours.push(time);
    }
  }
  hours.push(`23:59`);
  return hours;
};


/**
 * @description function to arrange days in a week sunday to saturday
 * @returns 
 */

export const arrangeDaysInWeek = (temp) => {
  let temp1 = [];
  temp.forEach((val, ind) => {
    if (val?.day == "sunday") {
      temp1[0] = val;
    } else if (val?.day == "monday") {
      temp1[1] = val;
    } else if (val?.day == "tuesday") {
      temp1[2] = val;
    } else if (val?.day == "wednesday") {
      temp1[3] = val;
    } else if (val?.day == "thursday") {
      temp1[4] = val;
    } else if (val?.day == "friday") {
      temp1[5] = val;
    } else if (val?.day == "saturday") {
      temp1[6] = val;
    }
  });
  return temp1;
}

export const imagesToBase64 = async (filesArray) => {
  try {
    const base64Promises = filesArray.map((file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          reject(new Error("An error occurred while reading the file."));
        };
        fileReader.readAsDataURL(file);
      });
    });

    const base64Images = await Promise.all(base64Promises);
    return base64Images[0];
  } catch (error) {
    console.error(error);
    throw new Error("Failed to convert images to Base64.");
  }
};

export const getNextTimeSlot = (time, timeDifference = 10) => {
  const hours = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += timeDifference) {
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      const time = `${formattedHour}:${formattedMinute}`;
      hours.push(time);
    }
  }
  hours.push(`23:59`);
  const nextHour = hours.filter((item) => item > time);
  return nextHour[0];
};