/**
 * @file This file contains all the Arabic language translations
 * Arabic Language
 * @type {Object}
 * @returns {Object} language - The language object
 */

import common from "./ar/common.json";
import set_password from "./ar/set_password.json";
import payout from "./ar/payout.json";
import notification from "./ar/notification.json";
import change_password from "./ar/change_password.json";
import account_setup from "./ar/account_setup.json";
import dashboardpage from "./ar/dashboardPage.json";
import header_footer from "./ar/header_footer.json";
import home from "./ar/home.json";
import sidebar from "./ar/sidebar.json";
import manageProducts from "./ar/manageProducts.json";
import manageModifier from "./ar/manageModifier.json";
import manageStore from "./ar/manageStore.json";
import manageCategory from "./en/manageCategory.json";
import order from "./ar/order.json";
import signup from "./ar/signup.json";
import login from "./ar/login.json";
import forgot from "./ar/forgot.json";
import holiday from "./ar/holiday.json";
import termsCondition from "./ar/termsCondition.json";
import faq from "./ar/faq.json";
import static_pages from "./ar/static_pages.json";
import myOffer from "./ar/myOffer.json";
import ManageUser from "./ar/manageUsers.json";

const ar = () => {
  const language = {
    ...common,
    ...set_password,
    ...payout,
    ...notification,
    ...change_password,
    ...account_setup,
    ...dashboardpage,
    ...header_footer,
    ...home,
    ...sidebar,
    ...manageProducts,
    ...manageModifier,
    ...manageStore,
    ...manageCategory,
    ...order,
    ...signup,
    ...login,
    ...forgot,
    ...holiday,
    ...termsCondition,
    ...faq,
    ...static_pages,
    ...myOffer,
    ...ManageUser,
  };
  return language;
};

export default ar;
