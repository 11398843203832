import { createSlice } from "@reduxjs/toolkit";
import { ORDER_STATUS } from "../../Enum";

export const orderTab = createSlice({
  name: "orderTab",
  initialState: {
    value: ORDER_STATUS.PLACED,
  },
  reducers: {
    changeTab: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeTab } = orderTab.actions;
export const currentTab = (state) => state.orderTab.value;
export default orderTab.reducer;
