import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Boxed } from "../../../Styles-Elements/Box";
import AddItemsOffer from "../../../dialog/AddItemsOffer";
import ThankyouOffer from "../../../dialog/ThankyouOffer";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Chip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../MyOffer.scss";
import { Typography } from "antd";

const AddOffer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openAddItemsOffer, setOpenAddItemsOffer] = useState(false);  
  const [openThankyou, setOpenThankyou] = useState(false);
  const [DiscountType, setDiscountType] = useState("");
  const [StartTime, setStartTime] = useState("");
  const [EndTime, setEndTime] = useState("");
  const [Day, setDay] = useState("");
  const handleChange = (event) => {
    setDiscountType(event.target.value);
    setStartTime(event.target.value);
    setEndTime(event.target.value);
    setDay(event.target.value);
  };
  return (
    <>
      <div className="main-mid-wrapper">
        <Boxed className="top-flex-row">
          <h3 className="h3">{t("myOffers_AddOffer")}</h3>
        </Boxed>
        <div className="card-white">
          <Grid
            container
            columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
            xs={12}
            sm={12}
            md={12}
            lg={10}
          >
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="form-group">
                <TextField required label={t("myOffers_DiscountName")} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  {t("myOffers_DiscountType")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={DiscountType}
                  label={t("myOffers_DiscountType")}
                  onChange={handleChange}
                >
                  <MenuItem value={0}>Flat Discount</MenuItem>
                  <MenuItem value={1}>Percentage Discount</MenuItem>
                  <MenuItem value={2}>BOGO (Buy one get one)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="form-group">
                <TextField
                  required
                  label={t("myOffers_DiscountAmount")}
                  id="DiscountAmount"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="bg-pink">
                        {t("myOffers_QAR")}
                      </InputAdornment>
                    ),
                  }}
                />
                <span className="redspan">{"Discount Amount error"}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="form-group">
                <TextField
                  required
                  label={t("myOffers_MinDiscountValue")}
                  id="MinDiscountValue"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="bg-pink">
                        {t("myOffers_QAR")}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs} required>
                <DatePicker
                  className="calender-icon"
                  // displayEmpty
                  // value={startDate}
                  label={t("myOffers_StartDate")}
                  inputProps={{ "aria-label": "Without label" }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs} required>
                <DatePicker
                  className="calender-icon"
                  // displayEmpty
                  // value={startDate}
                  label={t("myOffers_EndDate")}
                  inputProps={{ "aria-label": "Without label" }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              container
              columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Boxed className="top-flex-row" mb={"10px"}>
                  <h6 className="h6">{t("myOffers_AddHappyHours")}</h6>
                  <Boxed display={"flex"} alignItems={"center"}>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ width: "150px" }}
                    >
                      {t("myOffers_AddDay")}
                    </Button>
                  </Boxed>
                </Boxed>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    {t("myOffers_StartTime")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={StartTime}
                    label={t("myOffers_StartTime")}
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>10:00 AM</MenuItem>
                    <MenuItem value={1}>10:30 AM</MenuItem>
                    <MenuItem value={2}>11:00 AM</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    {t("myOffers_EndTime")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={EndTime}
                    label={t("myOffers_EndTime")}
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>11:00 AM</MenuItem>
                    <MenuItem value={1}>11:30 AM</MenuItem>
                    <MenuItem value={2}>12:00 PM</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    {t("myOffers_Day")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Day}
                    label={t("myOffers_Day")}
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>Monday</MenuItem>
                    <MenuItem value={1}>Tuesday</MenuItem>
                    <MenuItem value={2}>Wednesday</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ width: "150px", marginTop: "20px" }}
                >
                  {t("myOffers_AddDay")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Boxed className="top-flex-row">
          <h4 className="h4" sx={{ marginTop: "30px" }}>
            {t("myOffers_ApplyDiscountOn")}
          </h4>
        </Boxed>
        <div className="card-white">
          <Grid
            container
            columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
            xs={12}
            sm={12}
            md={12}
            lg={10}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <RadioGroup
                style={{ display: "flex", flexDirection: "row", width: "100%",margin: '0px -10px' }}
              >
                <Boxed>
                  <Radio value="Choose from Menu" />
                  Choose from Menu
                </Boxed>
                <Boxed style={{ margin: "0px 30px" }}>
                  <Radio value="Entire Store" />
                  Entire Store
                </Boxed>
              </RadioGroup>
              <Boxed style={{ display: "flex", alignItems: 'center'}}>
                <Chip label="Chinese Platter" className="select-chips" onDelete={() => {}} />
                <Chip label="Crispy Corn" className="select-chips" onDelete={() => {}} />
                <Chip label="Veg Momos Steamed (6 Pcs)" className="select-chips" onDelete={() => {}} />
                <Typography style={{marginTop: '10px', fontWeight: 600,display: 'inline-block'}}>+5 More</Typography>
              </Boxed>

              <Button  onClick={() => setOpenAddItemsOffer(true)}
                variant="contained"
                size="small"
                sx={{ width: "150px", marginTop: "20px" }}
              >
                {t("myOffers_AddMore")}
              </Button>
            </Grid>
          </Grid>
        </div>
        <Boxed style={{ display: "flex", justifyContent: 'flex-end'}}>
            <Button onClick={() => setOpenThankyou(true)}
                  variant="contained"
                  size="small"
                  sx={{ width: "150px", marginTop: "20px" }}
                >
                  {t("myOffers_Save")}
            </Button>
        </Boxed>
      </div>

      <AddItemsOffer
        isOpen={openAddItemsOffer}
        handleClose={() => setOpenAddItemsOffer(false)}
      />

      <ThankyouOffer
        isOpen={openThankyou}
        handleClose={() => setOpenThankyou(false)}
      />
    </>
  );
};

export default AddOffer;
