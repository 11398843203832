import "./addProduct.scss";
import { useEffect, useState, useLayoutEffect } from "react";
import {
  Grid, Switch, Button, Select, MenuItem, TextField, Menu, Chip, InputAdornment, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, InputLabel, Tooltip
} from "@mui/material";
import {
  getAllBarcodes, getdataByKey, generateHours, convertTimeTo12Hour, getDataByKeyLocale
} from "../../../Helpers/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { currentLocalData } from "../../../redux/reducers/localData";
import { API, PAGES } from "../../../Enum";
import { changeLoader } from "../../../redux/reducers/loader";
import { changePage } from "../../../redux/reducers/page";
import { changeMainProductData, currentMainProductData } from "../../../redux/reducers/mainEditProductData";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import arrowLeftBlack from "../../../assets/images/structure/arrow-left-black.svg";
import uomEmpty from "../../../assets/images/structure/uom-empty.png";
import threeDotsBlack from "../../../assets/images/structure/three-dots-black.svg";
import editBlack from "../../../assets/images/structure/edit-black.svg";
import deleteBlack from "../../../assets/images/structure/delete-black.svg";
import globalRequest from "../../../global-modules/globalRequest";
import AddUOM from "../../../dialog/AddUOM";
import Category from "./ProductComponent/Category";
import ActiveInactiveUomForStore from "../../../dialog/ActiveInactiveUomForStore";
import UomInactiveModal from "../../../dialog/UomInactiveModal";
import { currentBaladiInfo } from "../../../redux/reducers/baladiInfo";

let itemCodeTimer = null;

const AddProduct = () => {

  const allHours = generateHours(15);
  const dispatch = useDispatch();
  const baladiInfo = useSelector(currentBaladiInfo);
  const { t } = useTranslation();
  const localData = useSelector(currentLocalData);
  const tokenData = localData.token;
  const tempIdAddUom = `${tokenData?.store?.id}_${(new Date()).getTime()}`;
  const tokenStoreId = tokenData?.role === "vendor"?  tokenData?.brand_id: tokenData?.store?.parent_id;
  const editProductData = useSelector(currentMainProductData);
  const businessTypeId = tokenData?.store?.businessTypeId;
  let [categoryArray, setCategoryArray] = useState([]);
  const [productName, setProductName] = useState("");
  const [productNameErr, setProductNameErr] = useState(true);
  const [productNameArabic, setProductNameArabic] = useState("");
  const [productNameArabicErr, setProductNameArabicErr] = useState(true);
  const [itemCode, setItemCode] = useState("");
  const [itemCodeErr, setItemCodeErr] = useState(true);
  const [category, setCategory] = useState(0);
  const [categoryErr, setCategoryErr] = useState(true);
  const [subCategory, setSubCategory] = useState(0);
  const [subCategoryErr, setSubCategoryErr] = useState(true);
  const [subSubCategory, setSubSubCategory] = useState(0);
  const [subSubCategoryErr, setSubSubCategoryErr] = useState(true);
  const [description, setDescription] = useState("");
  const [descriptionErr, setDescriptionErr] = useState(true);
  const [descriptionArabic, setDescriptionArabic] = useState("");
  const [descriptionArabicErr, setDescriptionArabicErr] = useState(true);
  const [inStock, setInStock] = useState(true);
  const [tag, setTag] = useState([]);
  const [tagValue, setTagValue] = useState("");
  const [tagArabic, setTagArabic] = useState([]);
  const [tagValueArabic, setTagValueArabic] = useState("");
  const [unitData, setUnitData] = useState([]);
  const categoryLevel = tokenData?.store?.category_level;
  const [isItemDup, setIsItemDup] = useState(true);
  const [isItemDupErr, setIsItemDupErr] = useState(true);
  // const [quantity, setQuantity] = useState("");
  const [categoryClick, setCategoryClick] = useState(false);
  const [subCategoryClick, setSubCategoryClick] = useState(false);
  const [subSubCategoryClick, setSubSubCategoryClick] = useState(false);
  let [canEdit, setCanEdit] = useState(true);
  let [editRequest, setEditRequest] = useState(false);
  let [reasonOfReject, setReasonOfReject] = useState('');
  let [categoryData, setCategoryData] = useState([]);
  let [selectedCategory, setSelectedCategory] = useState(null);
  let [subCategoryDatas, setSubCategoryDatas] = useState([]);
  let [selectedSubCategory, setSelectedSubCategory] = useState(null);
  let [subSubCategoryDatas, setSubSubCategoryDatas] = useState([]);
  let [selectedSubSubCategory, setSelectedSubSubCategory] = useState(null);
  let [uoms, setUoms] = useState([]);
  let [uom, setUom] = useState(null);
  let [dataChanged, setDataChanged] = useState(true);
  let [deletedUom, setDeletedUom] = useState([]);
  let [requestCreated, setRequestCreated] = useState(false);
  let [addOns, setAddOns] = useState([]);
  let [storeId, setStoreId] = useState(tokenData?.brand_id);
  let [startTime, setStartTime]= useState(null);
  let [startTimeError, setStartTimeError]= useState('');
  let [endTime, setEndTime]= useState(null);
  let [endTimeError, setEndTimeError]= useState('');
  let [storeOptions, setStoreOptions] = useState([]);

  // uom edit for multiple store
  let [uomToEdit, setUomToEdit] = useState(null);
  const [showUomInactiveModal, setShowUomInactiveModal] = useState(false);
  
  let [selectedUom, setSelectedUom] = useState(null);
  // model for Active stores
  let [activeStores, setActiveStores] = useState([]);
  let [showActiveUomModal, setShowActiveUomModal] = useState(false);

  // model for Inactive stores
  let [inactiveStores, setInactiveStores] = useState([]);
  let [showInactiveUomModal, setShowInactiveUomModal] = useState(false);
  const [openAddUOM, setOpenAddUOM] = useState(false);

  const BasicMenu = ({ index }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleDeleteUOM = (index) => {
      const selectedUom = uoms[index];
      if (selectedUom?.isDefault === 1) {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("manageProducts_cannotDeleteDefaultUOM"),
            state: "error",
          })
        );
      } else {
        if (selectedUom?.id) {
          deletedUom.push(selectedUom.id);
          setDeletedUom(deletedUom);
        }
        const allUoms = [...uoms];
        allUoms.splice(index, 1);
        uoms = allUoms;
        setUoms(uoms)
      }
    }
  
    const handleEditUOM = (index) => {
      dispatch(changeLoader(true));
      const selectedUom = uoms[index];
      let uomId = '';
      if (selectedUom?.id && selectedUom?.isEdited === undefined) {
        uomId = selectedUom.id;
        globalRequest("get", API.UOM.GET(uomId), {}, {}, true, true).then(({data, status}) => {
          if (status == 200) {
            if (data?.ack == 1) {
              uom = data?.uomData
              uom.index = index;
              uom.activeStore = selectedUom?.activeStore;
              uom.inactiveStore = selectedUom?.inactiveStore;
              setUom(uom)
              setOpenAddUOM(true);
              dispatch(changeLoader(false));
            } else {
              dispatch(changeLoader(false));
              dispatch(
                changeSnackbar({
                  isOpen: true,
                  message: data?.msg,
                  state: "error",
                })
              );
            }
          } else {
            dispatch(changeLoader(false));
            dispatch(
              changeSnackbar({
                isOpen: true,
                message: data?.msg,
                state: "error",
              })
            );
          }
        }).catch((err) => {
          dispatch(changeLoader(false));
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: t("somethingWentWrong"),
              state: "error",
            })
          );
        })
      } else {
        uom = selectedUom
        uom.index = index;
        setUom(uom)
        dispatch(changeLoader(false));
        setOpenAddUOM(true);
      }
    }

    return (
      <div>
        <Button
          id="basic-button" aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true" aria-expanded={open ? "true" : undefined}
          onClick={handleClick} ><img src={threeDotsBlack} alt="" /></Button>
        <Menu
          id={"basic-menu"} anchorEl={anchorEl} open={open}
          onClose={handleClose} MenuListProps={{ "aria-labelledby": "basic-button", }}
        >
          <MenuItem onClick={() => { handleEditUOM(index); handleClose(); }} >
            <img src={editBlack} alt="" />
            <span className="p2-drop-menu">{t("manageProducts_edit")}</span>
          </MenuItem>
          <MenuItem onClick={() => { handleDeleteUOM(index); handleClose(); }} >
            <img src={deleteBlack} alt="" />
            <span className="p2-drop-menu">{t("manageProducts_delete")}</span>
          </MenuItem>
        </Menu>
      </div>
    );
  };

  const changeTime = (e, type) => {
    if (e.target.value === 'all') {
      startTime = e.target.value;
      setStartTime(startTime)
      endTime = e.target.value;
      setEndTime(endTime)
      setDataChanged(true);
      return false;
    }
    if (type === 'start') {
      startTime = e.target.value;
      setStartTime(startTime)
    } else {
      endTime = e.target.value;
      setEndTime(endTime)
    }
    if (parseInt(startTime?.split(':')?.join('')) > parseInt(endTime?.split(':')?.join(''))) {
      setStartTimeError('Start time is vot valid')
      setEndTimeError('End time is vot valid')
    } else {
      setStartTimeError('')
      setEndTimeError('')
    }
    setDataChanged(true);
  }

  const getStoreOptions = async () => {
    dispatch(changeLoader(true));
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + tokenData.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    storeOptions =[];
    if (response.data.ack === 1) {
      storeOptions = [{ label: "All Stores", value: 0, secondLabel: "" }]
      storeOptionArray = response.data.stores
        .filter((store) => (store.is_approved === null || store.is_approved === true)).map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address
        }
      });
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    storeOptions = [...storeOptions, ...storeOptionArray];
    setStoreOptions(storeOptions);
    dispatch(changeLoader(false));
  };

  const submit = () => {
    // let cat = categoryLevel >= 2 ? subCategory !== 0 : true;
    // let sub = categoryLevel >= 3 ? subSubCategory !== 0 : true;
    // if (!cat) { setSubCategoryErr(false); }
    // if (!sub) { setSubSubCategoryErr(false); }
    let categoryIds = [];
    switch (categoryLevel) {
      case 1:
        categoryIds = categoryArray.filter((obj) => obj.parent !== "").map((obj) => obj.parent);
        break;
      case 2:
        categoryIds = categoryArray.filter((obj) => obj.subCategory !== "").map((obj) => obj.subCategory);
        break;
      case 3:
        categoryIds = categoryArray.filter((obj) => obj.subSubCategory !== "").map((obj) => obj.subSubCategory);
        break;
      default:
        categoryIds = [];
        break;
    }
    let itemC = false;
    if (editProductData !== null) {
      if (editProductData?.itemCode == itemCode) { itemC = true; }
      else { itemC = !isItemDup; }
    } else { itemC = !isItemDup; }
    if (!itemC) { window.scrollTo(0, 0); }
    let uonIds = uoms.map((obj) => obj.id);
    let defaultUom = uoms.filter((obj) => obj.isDefault === 1);
    if (
      productName && itemCode && productNameArabic && itemC &&
      description !== '' && descriptionArabic !== '' && uonIds?.length > 0 && defaultUom?.length > 0 && categoryIds.length > 0
    ) {
      let catId = categoryIds.join(",");
      // if (categoryLevel === 2) { catId = subCategory; }
      // else if (categoryLevel === 3) { catId = subSubCategory; }
      let tagging = [];
      tag.forEach((obj) => { tagging.push(obj.name); });
      let baseUom = 0;
      uoms = uoms.map((obj) => {
        if (obj?.old) {
          obj.barcode = getAllBarcodes(obj.uom_barcode_relations),
          obj.relationWithBase = parseInt(obj.relationWithBase),
          obj.price = parseFloat(obj.price),
          obj.salePrice = parseFloat(obj.salePrice),
          obj.image = obj.uom_images.map((obj) => {
            return { name: obj?.image, type: obj?.type }
          }),
          obj.addons = obj?.uom_addon_relations?
            obj?.uom_addon_relations?.map((obj) => {return { addonId: obj.addonId };}): []
        }
        if (obj.relationWithBase === 1) {
          baseUom++;
        }
        return obj;
      });
      if (baseUom > 1) {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t('manageProducts_MultiBaseUomFound'),
            state: "error",
          })
        );
        dispatch(changeLoader(false));
        return false
      }
      let data = {
        title: productName,
        title_arabic: productNameArabic,
        itemCode: itemCode,
        categoryId: catId,
        description: description,
        description_arabic: descriptionArabic,
        stock: inStock ? 1 : 0,
        status: "active",
        tags: tagging.join(","),
        availability_start_time: startTime === 'all' ? '' : startTime,
        availability_end_time: endTime === 'all' ? '' : endTime,
        uoms: uoms
      };
      if(deletedUom?.length > 0) {
        data.deletedUomId = deletedUom.join(",");
      }
      let method = "post";
      let url = API.ADD_PRODUCT;
      if (editProductData !== null) {
        method = "put";
        url = API.EDIT_PRODUCT(editProductData?.id);
      }
      dispatch(changeLoader(true));
      globalRequest(method, url, data, {}, true, true).then((res) => {
        const data = res?.data;
        if (data?.ack === 1) {
          dispatch(changeMainProductData(null));
          if (editProductData !== null) {
            if (editRequest) {
              if (editProductData?.is_approved === 2) {
                sessionStorage.setItem("productCreateList", true);    
              } else {
                if (requestCreated) {
                  sessionStorage.setItem("productEditList", true);
                }
              }
            }
          } else {
            sessionStorage.setItem("productCreateList", true);
          }
          dispatch(changePage(PAGES.MANAGE_PRODUCT));
        }
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: data?.msg,
            state: data?.ack == 1 ? "success" : "error",
          })
        );
        dispatch(changeLoader(false));
      }).catch((err) => { dispatch(changeLoader(false)); });
    } else {
      setProductNameErr(false);
      setCategoryErr(false);
      setDescriptionErr(false);
      setItemCodeErr(false);
      setProductNameArabicErr(false);
      setDescriptionArabicErr(false);
      setIsItemDupErr(false);
      if (categoryIds.length === 0) {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("manageProducts_PleaseSelectCategory"),
            state: "error",
          })
        );
        dispatch(changeLoader(false));
      }
      if (uonIds?.length === 0) {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("manageProducts_PleaseAddAtLeastOneUOM"),
            state: "error",
          })
        );
        dispatch(changeLoader(false));
      } else if (defaultUom?.length === 0) {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("manageProducts_PleaseSelectOneUOMAsBase"),
            state: "error",
          })
        );
        dispatch(changeLoader(false));
      }
    }
  };

  const insertTag = (e) => {
    if (e.key == "Enter") {
      if (tagValue.trim() !== "") { 
        setTag((tag) => [...tag, { name: tagValue, index: parseInt(Math.random() * 9999) }]);
        setTagValue("");
      }
    }
  };

  const deleteTag = (index) => {
    setTag(tag.filter((a) => a.index !== index));
  };

  const getAllAddOns = async () => {
    dispatch(changeLoader(true));
    let params = {
      page: 1,
      limit: 1000,
      sortBy: 'id',
      orderBy: 'desc'
    };
    globalRequest('get', API.ADDONS.GET, {}, {params: params}, true, true).then(({data, status}) => {
      if(data.ack == '1') {
        addOns = data?.addons;
        setAddOns(addOns);
      } else {
        addOns = [];
        setAddOns(addOns);
      }
      dispatch(changeLoader(false));
    }).catch((err) => {
      addOns = [];
        setAddOns(addOns);
      dispatch(changeLoader(false));
    });
  };
  
  const getCategory = () => {
    // let url = API.GET_CATEGORY(businessTypeId);
    let url = API.GET_CATEGORY_BY_BRAND(tokenStoreId);
    globalRequest("get", url, {}, {}, true, true).then((res) => {
      let data = res?.data;
      if (data?.ack == 1) {
        categoryData = data?.results.map((obj) => {
          obj.name = obj.name;
          return obj;
        });
        setCategoryData(categoryData);
        categoryArray = [{ parent: "", subCategory: "", subSubCategory: "" }]
        setCategoryArray(categoryArray)
      }
    }).catch((err) => {});
  };

  const checkItemCode = () => {
    let bool = true;
    if (editProductData !== null) {
      if (itemCode === editProductData?.itemCode) {
        bool = false;
      }
    }
    if (bool) {
      let url = API.CHECK_ITEM_CODE;
      globalRequest("post", url, { itemCode: itemCode }, {}, true, true).then((res) => {
        let data = res?.data;
        if (data?.ack == 1) {
          setIsItemDup(false);
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: t("manageProducts_useThisItemCode"),
              state: "success",
            })
          );
        } else {
          setIsItemDup(true);
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: data?.msg,
              state: "error",
            })
          );
        }
      }).catch((err) => {});
    }
  };

  useEffect(() => {
    clearTimeout(itemCodeTimer);
    itemCodeTimer = setTimeout(() => {
      if (itemCode) { checkItemCode(); }
    }, 2000);
  }, [itemCode]);

  const getUnitData = () => {
    globalRequest("get", API.GET_UNIT, {}, {}, true, true).then((res) => {
      let data = res?.data;
      if (data?.ack == 1) {
        setUnitData(data?.Units.map(
          (obj) => {
            obj.name = obj.unit_locales[0]?.name;
            return obj
          }
        ).sort(
          (a, b) => {
            if (b?.name > a?.name) { return -1; }
          }
        ));
      }
    }).catch((err) => {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t("somethingWentWrong"),
          state: "error",
        })
      );
    });
  };

  const subCategoryData = (categoryId) => {
    let filter = categoryData.filter((a) => a.id === categoryId);
    subCategoryDatas = [];
    if (filter.length > 0) {
      let data = filter[0] ? filter[0].subCategory : [];
      subCategoryDatas = data?.map((obj) => {
        obj.name = obj.name;
        return obj;
      });
    }
    setSubCategoryDatas(subCategoryDatas);
    setSubSubCategoryDatas([]);
  };

  const subSubCategoryData = (subCategoryId) => {
    let filter = subCategoryDatas.filter((a) => a.id === subCategoryId);
    subSubCategoryDatas = [];
    if (filter.length > 0) {
      let data = filter[0] ? filter[0].chiledCategory: [];
      subSubCategoryDatas = data?.map((obj) => {
        obj.name = obj.name;
        return obj;
      })
    }
    setSubSubCategoryDatas(subSubCategoryDatas);
  };

  const sortAsc = (type) => {
    if (type == "barcode") {
      uoms = [...uoms].sort((a, b) => {
        if (b?.uom_barcode_relations.length > 0 && b?.uom_barcode_relations[0]?.barcode > a?.uom_barcode_relations[0]?.barcode) {return -1;}
      });
    }
    if (type == "uomName") {
      uoms = [...uoms].sort((a, b) => {
        if (b?.uomName > a?.uomName) {return -1;}
      });
    }
    if (type == "relationWithBase") {
      uoms = [...uoms].sort((a, b) => {
        if (b?.relationWithBase > a?.relationWithBase) {return -1;}
      });
    }
    if (type == "price") {
      uoms = [...uoms].sort((a, b) => {
        if (b?.price > a?.price) {return -1;}
      });
    }
    if (type == "stock") {
      uoms = [...uoms].sort((a, b) => {
        if (b?.stock > a?.stock) {return -1;}
      });
    }
    setUoms(uoms);
  };

  const sortDesc = (type) => {
    if (type == "barcode") {
      uoms = [...uoms].sort((a, b) => {
        if (a?.uom_barcode_relations.length > 0 && a?.uom_barcode_relations[0]?.barcode < b?.uom_barcode_relations[0]?.barcode) {return 1;} else {return -1;}
      });
    }
    if (type == "uomName") {
      uoms = [...uoms].sort((a, b) => {
        if (a?.uomName < b?.uomName) {return 1;} else {return -1;}
      });
    }
    if (type == "relationWithBase") {
      uoms = [...uoms].sort((a, b) => {
        if (a?.relationWithBase < b?.relationWithBase) {return 1;} else {return -1;}
      });
    }
    if (type == "price") {
      uoms = [...uoms].sort((a, b) => {
        if (a?.price < b?.price) {return 1;} else {return -1;}
      });
    }
    if (type == "stock") {
      uoms = [...uoms].sort((a, b) => {
        if (a?.stock < b?.stock) {return 1;} else {return -1;}
      });
    }
    setUoms(uoms);
  }

  useEffect(() => {
    getCategory();
    getUnitData();
    getAllAddOns();
  }, []);

  const handleInputChange = () => { setDataChanged(true); }

  const handleRequestChange = () => {
    setRequestCreated(true); 
  }

  const getEditProductData = (id) => {
    let url = API.GET_PRODUCT_DETAILS(id);
    globalRequest( "get", url, {}, {}, true, true ).then( ( { data } ) => {
      let catArray = [];
      data.categoriesObject.forEach(element => {
        const parent = element?.find((a) => a?.parent_id === 0);
        const subCategory = element?.find((a) => a?.parent_id === parent?.id);
        const subSubCategory = element?.find((a) => a?.parent_id === subCategory?.id);
        catArray.push({ parent: parent?.id, subCategory: subCategory?.id, subSubCategory: subSubCategory?.id })
      });
      setCategoryArray(catArray);
      const specificData = data.product.length > 0? data.product[0]:{};
      reasonOfReject = (specificData?.is_approved === 2) ?
        specificData?.reason_of_reject : '';
      setReasonOfReject(reasonOfReject)
      uoms = specificData?.uoms || [];
      uoms.map((obj) => {
        if (localData.token.role === 'vendor_users') {
          const isInactive = obj.manage_store_products.filter((store) => store.storeId == localData.token.store.id).length > 0;
          obj.status = isInactive?'inactive': 'active';
        }
        obj.old = true;
        const allStores = storeOptions?.filter((store) => store.value !== 0).length
        const totalActiveStore = allStores - obj?.manage_store_products.length;
        obj.activeStore = totalActiveStore
        obj.inactiveStore = obj?.manage_store_products.length;
        return obj;
      })
      setUoms(uoms)
      setCanEdit(specificData.is_approved == 0 ? false : true);
      if (sessionStorage.getItem('canedit') && sessionStorage.getItem('canedit') == 'false') {
        canEdit = false;
        setCanEdit(canEdit);
        sessionStorage.removeItem('canedit');
      }
      setIsItemDup(false);
      setIsItemDupErr(true);
      setItemCode(specificData?.itemCode);
      const englishLocals = specificData?.products_locales.find((a) => a.locale == "en");
      const arabicLocals = specificData?.products_locales.find((a) => a.locale == "ar");
      if (englishLocals) {
        setProductName(englishLocals?.title);
        setDescription(englishLocals?.description);
      }
      if (arabicLocals) {
        setProductNameArabic(arabicLocals?.title);
        setDescriptionArabic(arabicLocals?.description);
      }
      
      startTime = specificData?.availability_start_time;
      setStartTime(startTime);
      
      endTime = specificData?.availability_end_time;
      setEndTime(endTime);
  
      let tg = [];
      specificData?.tags.forEach((obj) => {
        if (obj?.tag && obj?.tag != "") {
          tg.push({ name: obj.tag, index: parseInt(Math.random() * 9999), });
        }
      });
      setTag(tg);
      if (categoryLevel === 1) {
        let id = specificData?.category?.id;
        setSelectedCategory(categoryData.find((a) => a.id === id));
        setCategory(id);
      } else if (categoryLevel === 2) {

        const parentId = specificData?.category?.parentCategories?.id;
        const parentSubId = specificData?.category?.id;
        setCategory(parentId);
        setSelectedCategory(categoryData?.find((a) => a.id === parentId));
        subCategoryData(parentId);
        setSubCategory(parentSubId);
        setSelectedSubCategory(subCategoryDatas?.find((a) => a.id === parentSubId));
        subSubCategoryData(parentSubId);
      } else if (categoryLevel === 3) {
        const parentId = specificData?.category?.parentCategories?.parentCategories?.id;
        const parentSubId = specificData?.category?.parentCategories?.id;
        const parentSubSubId = specificData?.category?.id;
        setCategory(parentId);
        setSelectedCategory(categoryData?.find((a) => a.id === parentId));
        subCategoryData(parentId);
        setSubCategory(parentSubId);
        setSelectedSubCategory(subCategoryDatas?.find((a) => a.id === parentSubId));
        subSubCategoryData(parentSubId);
        setTimeout(() => {
          setSubSubCategory(parentSubSubId);
          setSelectedSubSubCategory(subSubCategoryDatas?.find((a) => a.id === parentSubSubId));
        }, 500);

      }
      setDataChanged(false);
    }).catch( ( err ) => { 
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t("somethingWentWrong"),
          state: "error",
        })
      );
     });
  }

  useEffect(() => {
    if (editProductData !== null && categoryData !== null && categoryData.length > 0) {
      categoryArray = [];
      setCategoryArray(categoryArray);
      getEditProductData(editProductData?.id);
    } else {
      setCanEdit(true);
      setCategoryClick(true);
      setSubCategoryClick(true);
      setSubSubCategoryClick(true);
    }
  }, [editProductData, categoryData]);

  useEffect(() => {
    if (editProductData !== null) {
      if (categoryLevel === 3) {
        let id = editProductData?.categories1?.id;
        setSelectedSubCategory(editProductData?.categories1);
        setSubCategory(id);
        subSubCategoryData(id);
      } else if (categoryLevel === 2) {
        let id = editProductData?.category?.id;
        setSelectedSubCategory(editProductData?.category);
        setSubCategory(id);
      }
    }
  }, [category]);

  useEffect(() => {
    if (editProductData !== null) {
      if (categoryLevel === 3) {
        let id = editProductData?.category?.id;
        setSelectedSubSubCategory(editProductData?.category);
        setSubSubCategory(id);
      }
    }
  }, [subCategory]);

  const Sorting = ({ sortAsc, sortDesc }) => {
    return (
      <>
        <ArrowDropUpIcon onClick={sortAsc} />
        <ArrowDropDownIcon onClick={sortDesc} />
      </>
    );
  };

  useLayoutEffect(() => {
    getStoreOptions();
  }, [])

  return (
    <div className="main-mid-wrapper">
      <div className="add-product-wrapper">
        <div className="add-product-head">
          <img
            src={arrowLeftBlack}
            className="icon24 rotate180"
            alt="MANAGE PRODUCT"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (sessionStorage.getItem("returnto") == 'categories') {
                sessionStorage.removeItem("returnto");
                dispatch(changePage(PAGES.MANAGE_CATEGORIES))
              } else {
                sessionStorage.removeItem("returnto");
                dispatch(changePage(PAGES.MANAGE_PRODUCT))
              }
            }}
          />
          {canEdit ?<h3 className="h3">
            { editProductData === null ? t("manageProducts_addProduct"): t("manageProducts_editProduct") }
          </h3>: <h3 className="h3">{t('manageProducts_ViewProduct')}</h3>}
        </div>
        <div className="card-white">
          {reasonOfReject !== ''? <div className="">
            <h3 className="h3 mx-0">{t("manageProducts_ReasonOfReject")}</h3>
            <p>{reasonOfReject}</p>
          </div>: null}
          <h6 className="h6">{t("manageProducts_BasicInfo")}</h6>
          <Grid
            container
            columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
            xs={12} sm={12} md={12} lg={12}
          >
            <Grid item xs={12}>
              <div className="form-group">
                <TextField
                  required
                  label={t("manageProducts_ProductTitle_256characters")}
                  disabled={localData.token.role === 'vendor_users'}
                  onChange={(e) => {
                    setProductName(e.target.value);
                    setProductNameErr(false);
                    setEditRequest(true)
                    handleInputChange()
                    handleRequestChange()
                  }}
                  className={`${canEdit ? "" : "no-event"}`}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end" className="mobile-bottom">{productName.length}/256</InputAdornment>
                  }}
                  inputProps={{ maxLength: 256, }}
                  value={productName}
                />
                {!productName && !productNameErr ?
                  <span className="redspan">{t("manageProducts_productName")}</span> :
                  null}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <TextField
                  required
                  label={t("manageProducts_ProductTitle_256characters_Arabic")}
                  disabled={localData.token.role === 'vendor_users'}
                  onChange={(e) => {
                    setProductNameArabic(e.target.value);
                    setEditRequest(true)
                    handleInputChange()
                    handleRequestChange()
                  }}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end" className="mobile-bottom">{productNameArabic.length}/256</InputAdornment>
                  }}
                  className={`${canEdit ? "" : "no-event"}`}
                  inputProps={{maxLength: 256,}}
                  value={productNameArabic}
                />
                {!productNameArabic && !productNameArabicErr ?
                  <span className="redspan">{t("manageProducts_productNameArabic")}</span>:
                  null}
              </div>
            </Grid>

          {categoryArray.length > 0?
          <Grid item xs={12}
          style={localData.token.role === 'vendor_users'?{
            pointerEvents: 'none',
            opacity: 0.4,
          }:{}}
          
          >
            {tokenStoreId === baladiInfo.BALADI_BRAND_Id?
            <div className="add-product-head uom-head">
              <h3 className="h3 mx-0">{t("manageProducts_AddCategory")}</h3>
              {canEdit?<Button
                variant="contained"
                size="small"
                className="min-width148"
                onClick={() => {
                  categoryArray = [...categoryArray, { parent: "", subCategory: "", subSubCategory: "" }];
                  setCategoryArray(categoryArray);
                }}
              >{t("manageProducts_AddCategory")}</Button>:null}
            </div>
            : null}

            {categoryArray.map((obj, index) => 
            <Grid
              container
              columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
              xs={12} sm={12} md={12} lg={12}
              key={`category_${index}`}
            >
              <Grid item xs={11}>
                <Category
                  categoryArray={categoryArray}
                  categoryIndex={index}
                  item={obj}
                  canEdit={canEdit}
                  categoryLevel={categoryLevel}
                  categoryData={categoryData}
                  isEdit={editProductData !== null}
                  updateParent={(id) => {
                    let categoryArrayTemp = [...categoryArray];
                    categoryArrayTemp[index].parent = id;
                    setCategoryArray(categoryArrayTemp);
                    handleInputChange()
                  }}
                  updateSubCategory={(id) => {
                    let categoryArrayTemp = [...categoryArray];
                    categoryArrayTemp[index].subCategory = id;
                    setCategoryArray(categoryArrayTemp);
                    handleInputChange()
                  }}
                  updateSubSubCategory={(id) => {
                    let categoryArrayTemp = [...categoryArray];
                    categoryArrayTemp[index].subSubCategory = id;
                    setCategoryArray(categoryArrayTemp);
                    handleInputChange()
                  }}
                />
              </Grid>
              <Grid item xs={1}>
              {
                canEdit
                && categoryArray.length > 1
                && tokenStoreId === baladiInfo.BALADI_BRAND_Id
              ?
              <Button
                variant="contained"
                size="small"
                className="min-width148"
                onClick={() => {
                  const newArray = categoryArray.filter((a, i) => i !== index);
                  setCategoryArray(newArray);
                  handleInputChange()
                }}
              >{t("manageProducts_remove")}</Button>
              :null}
              </Grid>
            </Grid>
            )}
            {/* <Divider/> */}
          </Grid>: null}
            <Grid item xs={12} sm={6} md={3} lg={3} className="mt-2">
              <div className="form-group clock-picker">
              <InputLabel id="openTime">{t("manageStore_openTime")}</InputLabel>
              <Select
                labelId="openTime"
                label={t("manageStore_openTime")}
                disabled={localData.token.role === 'vendor_users'}
                className={`${canEdit ? "" : "no-event"}`}
                value={startTime}
                onChange={(e) => changeTime(e, 'start')}
              >
                <MenuItem key={0} value={'all'}> All time</MenuItem>
                {allHours.map((obj1, ind) => <MenuItem key={ind} value={obj1}>{convertTimeTo12Hour(obj1)}</MenuItem>)}
              </Select>
              <span className="redspan">{startTimeError}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="form-group clock-picker">
              <InputLabel id="openTime">{t("manageStore_closeTime")}</InputLabel>
              <Select
                labelId="openTime"
                className={`${canEdit ? "" : "no-event"}`}
                disabled={localData.token.role === 'vendor_users'}
                value={endTime}
                onChange={(e) => changeTime(e, 'end')}
              >
                <MenuItem key={0} value={'all'}> All time</MenuItem>
                {allHours.map((obj1, ind) => <MenuItem key={ind} value={obj1}>{convertTimeTo12Hour(obj1)}</MenuItem>)}
              </Select>
              <span className="redspan">{endTimeError}</span>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="form-group">
                <TextField
                  id="ItemCode"
                  value={itemCode}
                  onChange={(e) => setItemCode(e.target.value)}
                  disabled={localData.token.role === 'vendor_users'}
                  inputProps={{ maxLength: 25, }}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end" className="mobile-bottom">{itemCode.length}/25</InputAdornment>
                  }}
                  label={t("manageProducts_ItemCode") + '*'}
                  className={`${canEdit ? "" : "no-event"}`}
                />
                {!itemCode && !itemCodeErr ? <span className="redspan">{t("manageProducts_itemCode")}</span> : null}
                {itemCode && !isItemDupErr && isItemDup ? <span className="redspan">{t("manageProducts_itemCodeDup")}</span>: null}
              </div>
            </Grid>
            <Grid item xs={12}
              style={localData.token.role === 'vendor_users'?{
                pointerEvents: 'none',
                opacity: 0.4,
              }:{}}
            >
              <div className={`form-group ${canEdit ? "" : "no-event"}`}>
                <CKEditor
                  config={{
                    placeholder: "Description - English",
                    toolbar: {
                      items: [
                        "heading", "|", "bold", "italic", "underline", "|", "link",
                        "|", "bulletedList", "numberedList", "|", "undo", "redo",
                      ],
                    },
                  }}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                    handleInputChange()
                  }}
                  data={description}
                />
                {!description && !descriptionErr ?
                  <span className="redspan">{t("manageProducts_productDescription")}</span> :
                  null}
              </div>
            </Grid>
            <Grid item xs={12}
              style={localData.token.role === 'vendor_users'?{
                pointerEvents: 'none',
                opacity: 0.4,
              }:{}}
            >
              <div className={`form-group ${canEdit ? "" : "no-event"}`}>
                <CKEditor
                  config={{
                    placeholder: "Description - Arabic",
                    toolbar: {
                      items: [
                        "heading", "|", "bold", "italic", "underline", "|", "link",
                        "|", "bulletedList", "numberedList", "|", "undo", "redo",
                      ],
                    },
                  }}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescriptionArabic(data);
                    handleInputChange()
                  }}
                  data={descriptionArabic}
                />
                {!descriptionArabic && !descriptionArabicErr ?
                  <span className="redspan">{t("manageProducts_productDescriptionArabic")}</span>: null}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <TextField
                  value={tagValue}
                  disabled={localData.token.role === 'vendor_users'}
                  onChange={(e) => {
                    setTagValue(e.target.value)
                    handleInputChange()
                  }}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end" className="mobile-bottom">{tagValue.length}/20</InputAdornment>
                  }}
                  inputProps={{
                    maxLength: 20,
                  }}
                  onKeyDown={(e) => insertTag(e)}
                  label={t("manageProducts_Tags")}
                  className={`${canEdit ? "" : "no-event"}`}
                />
                {tag.length ? tag.map((obj, ind) => <Chip
                  key={`tag-${ind}`}
                  className={`select-chips ${canEdit ? "" : "no-event"}`}
                  label={obj.name}
                  onDelete={(e) => {
                    deleteTag(obj.index)
                    handleInputChange()
                  }}
                />) : null}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="card-white">
          <div className="add-product-head uom-head">
            <h3 className="h3 mx-0">{t("manageProducts_AddUnitOfMeasurement")}</h3>
            {canEdit && localData.token.role === 'vendor'?<Button
              variant="contained"
              size="small"
              className="min-width148"
              onClick={() => {
                uom = null;
                setUom(uom);
                setOpenAddUOM(true);
              }}
            >{t("manageProducts_AddUOM")}</Button>:null}
          </div>
          {/* UOM empty  */}
          {uoms.length === 0 ? <div className="card-white uom-empty">
            <img src={uomEmpty} alt=".." className="empty-img" />
            <Button
              variant="contained"
              size="small"
              className="min-width148"
              onClick={() => {
                uom = null;
                setUom(uom);
                setOpenAddUOM(true);
              }}
            >{t("manageProducts_AddUOM")}</Button>
          </div>: 
          <div className="table-block mb-30">
            <div className="table-wrapper">
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div className="short-div">
                          {t("manageProducts_Barcode")}
                          <div className="short-box">
                            <Sorting
                              sortAsc={() => sortAsc("barcode")}
                              sortDesc={() => sortDesc("barcode")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          {t("manageProducts_UOMCodeAndName")}
                          <div className="short-box">
                            <Sorting
                              sortAsc={() => sortAsc("uomName")}
                              sortDesc={() => sortDesc("uomName")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          {t("manageProducts_RelationWithBase")}
                          <div className="short-box">
                            <Sorting
                              sortAsc={() => sortAsc("relationWithBase")}
                              sortDesc={() => sortDesc("relationWithBase")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          {t("manageProducts_SellingPrice")}
                          <div className="short-box">
                            <Sorting
                              sortAsc={() => sortAsc("price")}
                              sortDesc={() => sortDesc("price")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      {editProductData !== null?<TableCell>
                        <div className="short-div">
                          {t("manageProducts_status")}
                        </div>
                      </TableCell>: null}
                      {localData.token.role === 'vendor' && editProductData !== null?<TableCell>
                        <div className="short-div">
                          {t("manageProducts_activeStores")}
                        </div>
                      </TableCell>: null}
                      {localData.token.role === 'vendor' && editProductData !== null?<TableCell>
                        <div className="short-div">
                          {t("manageProducts_inactiveStores")}
                        </div>
                      </TableCell>: null}
                      {canEdit && localData.token.role === 'vendor'?<TableCell></TableCell>:null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uoms.map((uom, index) => (
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={`uom-${index}`}
                        className={uom?.status && uom.status ==='pending'?"pendingUom":''}
                      >
                        <TableCell style={{ position: "relative" }}>
                          <span style={{
                            maxWidth: "150px", display: "inline-block", overflow: "hidden",
                            textOverflow: "ellipsis", whiteSpace: "nowrap"
                          }}>#{uom?.barcode ? uom?.barcode :getAllBarcodes(uom?.uom_barcode_relations)}</span>
                          {uom?.isDefault === 1?<sup style={{
                            marginLeft: "5px", color: "#000000", fontSize: "12px",
                            padding: "3px", backgroundColor: "rgb(187 229 223)", borderRadius: "5px"
                          }}>{t('manageProducts_DefaultUOM')}</sup>:null}
                        </TableCell>
                        <TableCell>{uom?.uomName} {uom?.uomNameEr?`(${uom?.uomNameEr})`: ''}</TableCell>
                        <TableCell>{uom?.relationWithBase} </TableCell>
                        <TableCell>QAR {uom?.salePrice !== 0 && uom?.salePrice !== '' && uom?.salePrice !== '0' ? uom?.salePrice : uom?.price}</TableCell>
                        {editProductData !== null?
                        <TableCell>

                          {uom.activeStore !== undefined?<Tooltip title={
                            uom?.isDefault === 1 || uom?.id === undefined?t('manageProducts_defaultUomCannotBeDisabled'): ''
                          } placement="top" arrow>
                          <Switch
                          style={uom?.isDefault === 1 || uom?.id === undefined?{ opacity: 0.4, cursor: 'default' }: {}}
                            checked={
                              uom?.id?
                              uom?.status === 'active' && uom?.activeStore > 0: true}
                            onChange={(e) => {
                              if (uom?.isDefault !== 1 && uom?.id !== undefined) {
                                uomToEdit =  { status: e.target.checked ? "active" : "inactive", uom: uom }
                                setUomToEdit(uomToEdit)
                                setShowUomInactiveModal(true)
                              }
                            }}
                          />
                          </Tooltip>: '-'}

                        </TableCell>: null}

                        {localData.token.role === 'vendor' && editProductData !== null?<TableCell
                          style={
                            uom?.status && uom.status !=='pending' && uom?.activeStore > 0?{ textDecoration: "underline", cursor: "pointer" }: {}}
                          onClick={() => {
                            if (uom?.status && uom.status !=='pending' && uom?.activeStore > 0) {
                              const inactiveStoreIds = uom?.manage_store_products.map((store) => store.storeId);
                              activeStores = storeOptions?.filter((store) => !inactiveStoreIds.includes(store.value));
                              setActiveStores(activeStores);
                              selectedUom = uom;
                              setSelectedUom(selectedUom);
                              setShowActiveUomModal(true);
                            }
                          }}
                        >{
                          uom?.id?
                        uom?.activeStore || '0': '-'}</TableCell>: null }
                        {localData.token.role === 'vendor' && editProductData !== null?<TableCell
                          style={uom?.status && uom.status !=='pending' && uom?.inactiveStore > 0  && uom?.isDefault !== 1?{ textDecoration: "underline", cursor: "pointer" }: {}}
                          onClick={() => {
                            if(uom?.status && uom.status !=='pending' && uom?.inactiveStore > 0  && uom?.isDefault !== 1) {
                              const activeStoreIds = uom?.manage_store_products.map((store) => store.storeId);
                              inactiveStores = storeOptions?.filter((store) => activeStoreIds.includes(store.value));
                              setInactiveStores(inactiveStores);
                              selectedUom = uom;
                              setSelectedUom(selectedUom);
                              setShowInactiveUomModal(true);
                            }
                          }}
                        >{
                          uom?.id?
                        uom?.inactiveStore || '0': '-'}</TableCell>: null}
                        {canEdit && localData.token.role === 'vendor'?<TableCell><BasicMenu obj={uom} index={index} /></TableCell>:null}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>}
        </div>
        {canEdit && localData.token.role === 'vendor'? <div className="btn-row" style={{ marginBottom: "20px" }}>
          <Button
            onClick={submit}
            variant="contained"
            size="large"
            className="min-width148 ml-auto saveBtn"
            disabled={!dataChanged}
          >{t("manageProducts_Save")}</Button>
        </div>: null}
      </div>
      {openAddUOM?<AddUOM
        storeId={storeId}
        updateEditValue={(newUon, editRequest2, requestCreated) => {
          handleInputChange()
          if (requestCreated) {
            handleRequestChange()
          }
          if (editRequest2) {
            editRequest = editRequest2;
            setEditRequest(editRequest);
          }
          uoms = uoms.map((obj, index) => {
            if (newUon.index === index) { obj = newUon; }
            return obj;
          });
          setUoms(uoms);
          setOpenAddUOM(false);
        }}
        updateValue={(newUon, editRequest2, requestCreated) => {
          handleInputChange()
          if (requestCreated) {
            handleRequestChange()
          }
          if (editRequest2) {
            editRequest = editRequest2;
            setEditRequest(editRequest);
          }
          let allUoms = [...uoms];
          allUoms.push(newUon);
          uoms = allUoms;
          setUoms(uoms);
          setOpenAddUOM(false);
        }}
        uom={uom}
        addOnOption={addOns.map((obj) => {
          return { label: getdataByKey(obj.addons_locales, 'name', 'en'), id: obj.id };
        })}
        uomsCount={uoms.length}
        editProductData={editProductData}
        isOpen={openAddUOM}
        unitData={unitData}
        tempIdAddUom={tempIdAddUom}
        handleClose={() => { setOpenAddUOM(false); }}
      />: null}
      {showActiveUomModal? <ActiveInactiveUomForStore
          type="active"
          handleClose={() => { setShowActiveUomModal(false); }}
          stores={activeStores.filter((store) => store.value !== 0)}
          selectedUom={selectedUom}
          getProduct={() => {
            getEditProductData(editProductData?.id);
            setShowActiveUomModal(false);
          }}
        />: null}
      {showInactiveUomModal? <ActiveInactiveUomForStore
          type="inactive"
          handleClose={() => { setShowInactiveUomModal(false); }}
          stores={inactiveStores.filter((store) => store.value !== 0)}
          selectedUom={selectedUom}
          getProduct={() => {
            getEditProductData(editProductData?.id);
            setShowInactiveUomModal(false);
          }}
        />: null}
      {showUomInactiveModal?<UomInactiveModal
        handleClose={() => { setShowUomInactiveModal(false); }}
        uomToEdit={uomToEdit}
        storeOptions={storeOptions}
        getProduct={() => {
          getEditProductData(editProductData?.id);
          setShowUomInactiveModal(false);
        }}
      />: null}
    </div>
  );
};

export default AddProduct;
