import { Button, Dialog, DialogContent, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import { VisibilityOff } from "@mui/icons-material";
import globalRequest from "../global-modules/globalRequest";
import { API } from "../Enum";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import { useTranslation } from "react-i18next";

  const ChangePassword = ({ handleClose }) => {

    const {t} = useTranslation();
    const [oldPassword, setOldPassword] = useState("");
    const [oldPasswordErr, setOldPasswordErr] = useState(true);
    const [password, setPassword] = useState("");
    const [passwordErr, setPasswordErr] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordErr, setConfirmPasswordErr] = useState(true);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(changeLoader(true));
        let isValid = true;
        if (oldPassword === "") {
            setOldPasswordErr(t('change_password_old_password_error'));
            isValid = false;
        }
        if (password === "") {
            setPasswordErr(t('change_password_password_error'));
            isValid = false;
        }
        if (confirmPassword === "") {
            setConfirmPasswordErr(t('change_password_confirm_password_error'));
            isValid = false;
        }
        if (password !== confirmPassword) {
            setConfirmPasswordErr(t('change_password_password_not_match'));
            isValid = false;
        }
        if (isValid) {
          globalRequest('post', API.CHANGE_PASSWORD, {
            old_password: oldPassword,
            password: password
          }, {}, true,true).then(({data, status}) => {
						if (status == 400) {
							dispatch(changeLoader(false));
							dispatch(
								changeSnackbar({
									isOpen: true,
									state: "error",
									message: t('somethingWentWrong'),
								})
							);
						}
						if (data.ack == 1) {
							dispatch(changeLoader(false));
							dispatch(
								changeSnackbar({
									isOpen: true,
									state: "success",
									message: data.msg
								})
							);
							handleClose();
						} else {
              dispatch(changeLoader(false));
              if (data.msg !== '') {
                dispatch(
                  changeSnackbar({
                    isOpen: true,
                    state: "error",
                    message: data.msg
                  })
                );
              } else {

                data.errMsg.forEach((element) => {
                  for (const [key, value] of Object.entries(element)) {
                    if (key === 'old_password') {
                      setOldPasswordErr(value);
                    }
                    if (key === 'password') {
                      setPasswordErr(value);
                    }
                    if (key === 'confirm_password') {
                      setConfirmPasswordErr(value);
                    }
                  }
                });
              }
            }
					}).catch((err) => {
            dispatch(changeSnackbar({
              open: true,
              message: t('somethingWentWrong'),
              type: 'error'
            }));
            dispatch(changeLoader(false));
          });
        } else {
            dispatch(changeLoader(false));
        }
    }

    return (
      <Dialog open={true} onClose={handleClose} className="main-modal maxwidth-430">
        <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
        <DialogContent>
          <h2 className="h3 text-center">{t('change_password')}</h2>
          <p className="p3 text-center">{t('change_password_mustIncludePasswordText')}</p>
          
          <form onSubmit={handleSubmit}>

          <Grid container spacing={0}>

            <Grid item xs={12}>
              <div className="form-group">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">{t('change_password_old_password')}</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showOldPassword ? "text" : "password"}
                    value={oldPassword}
                    onChange={(e) => {
                        setOldPassword(e.target.value);
                        setOldPasswordErr('');
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => setShowOldPassword(!showOldPassword)}
                          onMouseDown={(e) => {
                            e.preventDefault();
                          }}
                          edge="end"
                        >
                          {!showOldPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t('change_password_old_password')}
                  />
                  <span className="errorspan">{oldPasswordErr}</span>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">{t('change_password_new_password')}</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                        setPasswordErr('');
                    }}
                    required={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => setShowPassword(!showPassword)}
                          onMouseDown={(e) => {
                            e.preventDefault();
                          }}
                          edge="end"
                        >
                          {!showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t('change_password_new_password')}
                  />
                   <span className="errorspan">{passwordErr}</span>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    {t('change_password_confirm_new_password')}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                        setConfirmPasswordErr('');
                    }}
                    required={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => setShowConfirmPassword(!showConfirmPassword)}
                          onMouseDown={(e) => {
                            e.preventDefault();
                          }}
                          edge="end"
                        >
                          {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={t('change_password_confirm_new_password')}
                  />
                   <span className="errorspan">{confirmPasswordErr}</span>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{ width: 1, marginTop: "16px" }}
              >
                {t('change_password_submit')}
              </Button>
            </Grid>
          </Grid>
          </form>


        </DialogContent>
      </Dialog>
    );
  };
  
  export default ChangePassword;
  