import { createSlice } from '@reduxjs/toolkit'

export const notification = createSlice({
  name: 'notification',
  initialState: {
    value: false,
  },
  reducers: {
    changeNotification: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeNotification } = notification.actions
export const currentNotification = (state) => state.notification.value;
export default notification.reducer
