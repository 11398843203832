import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import modalClose from "../assets/images/structure/close-black.svg";
import CalenderIcon from "../assets/images/structure/Calendar.svg";
import ClockIcon from "../assets/images/structure/clock-black.svg";
import { Boxed } from "../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import {
  Box, Button, Divider, FormControl, Radio, RadioGroup, Dialog, DialogContent
} from "@mui/material";
import SearchSelectStore from "../shared/SearchSelectStore";
import SearchSelectSingleStore from "../shared/SearchSelectSingleStore";
import { changeSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import globalRequest from "../global-modules/globalRequest";
import { API, COUNTRY_CODE, ROLE, STORAGE } from "../Enum";

const OfflineAllStore = ({
  isOpen = false,
  handleClose,
  stores,
  storeId,
  storeStatusOnOf,
  bulkEdit = true,
  handleGetStore
}) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedValueRadio, setSelectedValueRadio] = useState('0');
  let [selectedStores, setSelectedStores] = useState([]);
  
  const handleChangeRadio = (event) => {
    setSelectedValueRadio(event.target.value);
  };

  const handleSave = async () => {
    dispatch(changeLoader(true));
    const allStoreIds = bulkEdit?selectedStores:[selectedStores.toString()]
    if (allStoreIds.length === 0) {
      dispatch(changeSnackbar({ 
        isOpen: true,
        state: "error",
        message: t("manageStore_selectStoreToChangeStatusofStores")
      }));
      dispatch(changeLoader(false));
    } else {
      const body = {
        storeId: allStoreIds.join(','),
        status: storeStatusOnOf,
        online_status_not_update_by_cron: parseInt(selectedValueRadio),
      };
      const response = await globalRequest('post', API.UPDATE_STORE_ONLINE_OFFLINE, body, {}, true, true);
      if (response.data.ack === 1) {
        dispatch(changeLoader(false));
        dispatch(changeSnackbar({ 
          isOpen: true,
          state: "success",
          message: response.data.msg
        }));
        handleGetStore();
      } else {
        dispatch(changeSnackbar({ 
          isOpen: false,
          state: "error",
          message: response.data.msg
  
        }));
      }
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    selectedStores = storeId;
    setSelectedStores(selectedStores);
  }, []);

  return (
    <Dialog open={isOpen} onClose={handleClose} className="main-modal maxwidth-430" >
      <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h4 className="h4" style={{ marginBottom: "8px" }}>
          {storeStatusOnOf==='offline'?
            bulkEdit?
              t("manageStore_allStoreOffline"):
              t("manageStore_thisStoreOffline"):
            bulkEdit?
              t("manageStore_allStoreOnline"):
              t("manageStore_thisStoreOnline")
          }
        </h4>
        <p className="p3">
          {storeStatusOnOf==='offline'?
            bulkEdit?
              t("manageStore_allStoreOfflineText"):
              t("manageStore_thisStoreOfflineText"):
            bulkEdit?
              t("manageStore_allStoreOnlineText"):
              t("manageStore_thisStoreOnlineText")
          }
        </p>
        {bulkEdit?
        <SearchSelectStore
          options={stores}
          value={selectedStores}
          changeSelectedStores={setSelectedStores}
        />:<SearchSelectSingleStore
          options={stores}
          value={stores.find((store) => store.value === storeId)}
          disabled={true}
        />}

       {storeStatusOnOf==='offline'? <>
          <h6 className="h6">Type</h6>
          <div className="">
            <RadioGroup
              className="background-box"
              value={selectedValueRadio}
              onChange={handleChangeRadio}
            >
              <FormControl sx={{ flexDirection: 'row', gap: 2 }} >
                <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                  <img src={CalenderIcon} alt="calender icon" className="" />
                  <p className="p3 mx-2 mb-0">Unavailable until further notice</p>
                </Box>
                <Radio name="radio-buttons" value={'1'}/>
              </FormControl>
              <Divider />
              <FormControl sx={{ flexDirection: 'row', gap: 2 }} >
                <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                  <img src={ClockIcon} alt="calender icon" className="" />
                  <p className="p3 mx-2 mb-0">Unavailable until next day</p>
                </Box>
                <Radio name="radio-buttons" value={'0'} />
              </FormControl>
            </RadioGroup>
          </div>
        </>: null}
        <Boxed>
          <Button
            type="button" variant="contained" sx={{ width: "100%", marginTop: "20px" }}
            onClick={handleSave}
          >{t("manageStore_Yes")}</Button>
        </Boxed>
      </DialogContent>
    </Dialog>
  );
};

export default OfflineAllStore;
