import { API } from "../Enum";
import globalRequest from "./globalRequest";

const skipReg = async (step) => {
  return await globalRequest(
    "get",
    API.SKIP_STEPS,
    {},
    {
      params: {
        skipped_number: step,
      },
    },
    true,
    true
  );
};

export default skipReg;
