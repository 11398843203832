import { createSlice } from "@reduxjs/toolkit";

export const editStoreData = createSlice({
  name: "editStoreData",
  initialState: {
    value: null,
    option: null,
    holidays: null,
  },
  reducers: {
    changeStoreData: (state, action) => {
      state.value = action.payload;
    },
    changeStoreOption: (state, action) => {
      state.option = action.payload;
    },
    changeHolidays: (state, action) => {
      state.holidays = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeStoreData, changeStoreOption, changeHolidays } =
  editStoreData.actions;
export const currentStoreData = (state) => state.editStoreData.value;
export const currentStoreOption = (state) => state.editStoreData.option;
export const currentHolidays = (state) => state.editStoreData.holidays;
export default editStoreData.reducer;
