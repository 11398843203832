import React from "react";
import "./Sidebar.scss";
import sidebarArrowIcon from "../../../assets/images/structure/sidebar-arrow-icon.png";
import dashboardIcon from "../../../assets/images/structure/dashboard_icon.svg";
import manageProductIcon from "../../../assets/images/structure/manage-product-icon.svg";
// import manageModidiersIcon from "../../../assets/images/structure/manage-product-icon.svg";
import myOrderIcon from "../../../assets/images/structure/my-order-icon.svg";
import payoutReportIcon from "../../../assets/images/structure/payout-report-icon.svg";
import notificationIcon from "../../../assets/images/structure/notification-icon.svg";
import manageCategories from "../../../assets/images/structure/manage-Category icon.svg"
import settingIcon from "../../../assets/images/structure/setting-icon.svg";
import myOfferIcon from "../../../assets/images/structure/my-offer-icon.svg";
import addonIcon from "../../../assets/images/structure/manage-addon-icon.svg";
import payoutIcon from "../../../assets/images/structure/manage-payout-icon.svg";
import userIcon from "../../../assets/images/structure/manage-user-icon.svg";
import holidayIcon from "../../../assets/images/structure/manage-holiday-icon.svg";
import timeIcon from "../../../assets/images/structure/manage-time-icon.svg";

import { useEffect, useState } from "react";
import { PAGES } from "../../../Enum";
import { useDispatch, useSelector } from "react-redux";
import { changePage } from "../../../redux/reducers/page";
import { useTranslation } from "react-i18next";
import { currentNewOrder } from "../../../redux/reducers/myNewOrder";
import { currentNotification } from "../../../redux/reducers/notification";
import { currentLocalData } from "../../../redux/reducers/localData";
import { Collapse, List, ListItemButton, ListItemIcon } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const Sidebar = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localData = useSelector(currentLocalData)
  const NewOrder = useSelector(currentNewOrder)
  const Notification = useSelector(currentNotification)
  const [isActive, setIsActive] = useState(false);
  // const [newOrders , setNewOrders] = useState(false)
  let [currentPage, setCurrentPage] = useState("dashboard");
  // const [open, setOpen] = React.useState(false);

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  let [sideBar, setSideBar] = useState([{
    name: t("sidebar_dashboard"),
    page: PAGES.DASHBOARD,
    img: dashboardIcon,
    isSignal: false,
    id: 'dashboard'
  }]);

  const toggleClass = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    let sideBar = [];
    sideBar.push({
      name: t("sidebar_dashboard"),
      page: PAGES.DASHBOARD,
      img: dashboardIcon,
      isSignal: false,
      id: 'dashboard'
    });
    sideBar.push({
      name: t("sidebar_myOrders"),
      page: PAGES.MY_ORDER,
      img: myOrderIcon,
      isSignal: NewOrder,
      id: 'order'
    })
    let manageOrderChild = [];
    manageOrderChild.push({
      name: t("sidebar_manageStore"),
      page: PAGES.MANAGE_STORE,
      img: settingIcon,
      isSignal: false,
      id: 'store'
    })
    if (localData?.token?.role === 'vendor') {
      manageOrderChild.push({
        name: t("sidebar_manageUser"),
        page: PAGES.MANAGE_USER,
        img: userIcon,
        isSignal: false,
        id: 'user'
      });
    }
    manageOrderChild.push({
      name: t("sidebar_manageTiming"),
      page: PAGES.MANAGE_TIMING,
      img: timeIcon,
      isSignal: false,
      id: 'timing'
    })
    manageOrderChild.push({
      name: t("sidebar_manageHolidays"),
      page: PAGES.MANAGE_HOLIDAYS,
      img: holidayIcon,
      isSignal: false,
      id: 'Holidays'
    })
    manageOrderChild.push({
      name: t("sidebar_managePayouts"),
      page: PAGES.MANAGE_PAYOUTS,
      img: payoutIcon,
      isSignal: false,
      id: 'Payouts'
    })
    manageOrderChild.push({
      name: t("sidebar_manageCategories"),
      page: PAGES.MANAGE_CATEGORIES,
      img: manageCategories,
      isSignal: false,
      id: 'manageCategories'
    })

    sideBar.push({
      name: t("sidebar_StoreManagent"),
      img: settingIcon,
      isSignal: false,
      isOpen: false,
      id: 'store-main',
      child: manageOrderChild
    });
    if (localData?.token?.role === 'vendor') {
      sideBar.push({
        name: t("sidebar_manageProducts"),
        page: PAGES.MANAGE_PRODUCT,
        img: manageProductIcon,
        isSignal: false,
        id: 'product'
      })
      sideBar.push({
        name: t("manageModifier_ManageAddons"),
        page: PAGES.MANAGE_MODIFIER,
        img: addonIcon,
        isSignal: false,
        id: 'modifier'
      });
    }
    sideBar.push({
      name: t("sidebar_earningsAndPayouts"),
      page: PAGES.EARNINGS_PAYOUTS,
      img: payoutReportIcon,
      isSignal: false,
      id: 'payout'
    })
    sideBar.push({
      name: t("sidebar_notification"),
      page: PAGES.NOTIFICATION,
      img: notificationIcon,
      isSignal: Notification,
      id: 'notification'
    })
    // sideBar.push({
    //   name: t("sidebar_myOffer"),
    //   page: PAGES.MY_OFFER,
    //   img: myOfferIcon,
    //   isSignal: false,
    //   id: 'myOffer'
    // })
    setSideBar(sideBar)
  }, [NewOrder, Notification]);

  return (
    <>
      <div className={isActive ? "sidebar-wrapper show" : "sidebar-wrapper"}>
        <div className="show-arrow" onClick={toggleClass}>
          <img src={sidebarArrowIcon} alt="show-arrow" className="show-arrow-icon" />
        </div>
        <div className="sidebar-block">
          {sideBar.map((obj, ind) => {
            if (obj.child) {
              return (
                <span key={ind} >
                <ListItemButton  className={`sidebar-links ${obj?.isOpen? 'active' : ''}`}
                  onClick={() => {
                    sideBar = sideBar.map((obj2, i) => {
                      if (i === ind) {
                        obj2.isOpen = !obj2.isOpen
                      }
                      return obj2
                    })
                    setSideBar(sideBar)
                  }}
                >
                  <img src={obj.img} className="sidebar-icon" alt="" />
                  <span className="p2">{obj.name}</span>   
                  <span className="ml-auto">{obj.isOpen ? <ExpandLess /> : <ExpandMore />}</span>                   
                </ListItemButton>

                <Collapse in={obj?.isOpen} >
                  {obj.child.map((obj2, ind2) => 
                  <List
                    component="div"
                    className={`sidebar-links ${obj2.page === currentPage ? "active" : ""}`}
                    key={`clild-${ind}-${ind2}`}
                    onClick={() =>{
                      currentPage = obj2.page
                      setCurrentPage(currentPage)
                      dispatch(changePage(obj2.page))
                    }}
                  >
                    <img src={obj2.img} className="sidebar-icon" alt="" />
                    <span className="p2">{obj2.name}</span>
                  </List>)}
                </Collapse>
                </span>
              )
            } else {
              return (
                <div
                  key={ind}
                  onClick={() =>{
                    dispatch(changePage(obj.page))
                    setCurrentPage(obj.page)
                    if (obj.page === PAGES.MY_ORDER) {
                      sessionStorage.removeItem('orderSearch');
                      sessionStorage.setItem('orderTab', 'placed');
                    }
                  }}
                  className={`sidebar-links ${
                    obj.page === currentPage ? "active" : ""
                  }`}
                >
                  <img src={obj.img} className="sidebar-icon" alt="" />
                  <span className="p2">{obj.name}</span>
                  <span id={obj.id} className={obj.isSignal?`notification-signal`:''}></span>
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
