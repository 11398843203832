// ActiveInactiveAddOnsForStore.js

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import modalClose from "../assets/images/structure/close-black.svg";
import emptyImg from "../assets/images/structure/no-product.png";
import storeLogo from "../assets/images/products/store-logo1.svg";
import { Boxed } from "../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import {
   Button, Dialog, DialogContent, Switch
} from "@mui/material";
import { changeLoader } from "../redux/reducers/loader";
import { changeSnackbar } from "../redux/reducers/snackbar";
import globalRequest from "../global-modules/globalRequest";
import { API } from "../Enum";
import { currentLocalData } from "../redux/reducers/localData";

const ActiveInactiveAddOnsForStore = ({
  handleClose,
  type,
  stores,
  selectedAddon,
  getAddons
}) => {

  const tokenData = useSelector(currentLocalData);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let [activeStores, setActiveStores] = useState([]);
  let [inactiveStores, setInactiveStores] = useState([]);
  
  const handleSave = async () => {
    dispatch(changeLoader(true));
    if ((type === "active" && inactiveStores.length === 0) || (type === "inactive" && activeStores.length === 0)) {
      dispatch(changeSnackbar({
        message: t('manageProducts_pleaseUpdateStore'),
        isOpen: true,
        state: "error",
      }));
      dispatch(changeLoader(false));
      return;
    }
    const data = {
      status: type === "active" ? "inactive" : "active",
      addonId: selectedAddon.id.toString(),
      storeId: type === "active" ? inactiveStores.join(',') : activeStores.join(',')
    };
    globalRequest('post', API.MANAGE_PRODUCTS.CHANGE_ADDON_STATUS, data, {}, true, true)
      .then((res) => {
        if (res.data.ack === 1) {
          dispatch(changeSnackbar({
            isOpen: true,
            message: res.data.msg,
            state: "success",
          }));
          getAddons();
        } else {
          if (res.data.errMsg) {
            res.data.errMsg?.forEach(element => {
              for (const [key, value] of Object.entries(element)) {
                dispatch(changeSnackbar({
                  isOpen: true,
                  message: value,
                  state: "error",
                }));
              }
            });
          } else {
            dispatch(changeSnackbar({
              message: res.data.msg,
              isOpen: true,
              state: "error",
            }));
          }
        }
      }).catch((err) => {
        dispatch(changeSnackbar({
          message: t('somethingWentWrong'),
          isOpen: true,
          state: "error",
        }));
      });
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (type === "active") {
      activeStores = stores.map((store) => store.value);
      setActiveStores(activeStores);
    } else {
      inactiveStores = stores.map((store) => store.value);
      setInactiveStores(inactiveStores);
    }
  }, [stores, type]);

  return (
    <Dialog open={true} onClose={handleClose} className="main-modal maxwidth-430" >
      <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h4 className="h4" style={{ marginBottom: "10px",marginTop: '0px', textTransform: 'capitalize' }}>
          {t('manageProducts_AllActiveInactiveStorePopupTitle', {
            type: type === "active" ? t("manageProducts_Active") : t("manageProducts_Inactive"),
            count: stores.length
          })}
        </h4>
        <p className="p3" style={{ marginBottom: "16px" }}>
          {t('manageProducts_AllActiveInactiveStorePopupText', {
              currentStatus: type === "active" ? t("manageProducts_Active") : t("manageProducts_Inactive"),
              futureStatus: type === "active" ? t("manageProducts_Inactive") : t("manageProducts_Active")
            })}
        </p>
        <div className="store-switch-box">
          {stores.length > 0 && stores.map((store, index) => (
            <div className="store-switch-flex" key={`store-switch-${index}`} >
            <Boxed style={{ display: "flex", alignItems: 'start'}}>
              <img src={storeLogo} alt="store-logo" className="store-logo" />
              <div className="relative">
                <h6 className="h6 mt-1">{store.label}</h6>
                <p className="p3 m-0">{store.secondLabel}</p>
              </div>
            </Boxed>
            <Switch
              checked={activeStores.includes(store.value)}
              onChange={(e) => {
                if (e.target.checked) {
                  activeStores = [...activeStores, store.value];
                  setActiveStores(activeStores);
                  inactiveStores = inactiveStores.filter((item) => item !== store.value);
                  setInactiveStores(inactiveStores);
                } else {
                  activeStores = activeStores.filter((item) => item !== store.value);
                  setActiveStores(activeStores);
                  inactiveStores = [...inactiveStores, store.value];
                  setInactiveStores(inactiveStores);
                }
              }}
            />
          </div>))}
        </div>
        <Boxed>
          <Button
            type="button" variant="contained" sx={{ width: "100%", marginTop: "20px" }}
            onClick={handleSave}
          >{t("manageStore_save")}</Button>
        </Boxed>
      </DialogContent>
    </Dialog>
  );
};

export default ActiveInactiveAddOnsForStore;
