import pageNotFound from "../../assets/images/structure/page-not-found.png";
import Header from "../layout/header/Header";

const PageNotFound = () => {
  return (
    <>
    <Header />
    <div className="page-not-found">
      <img src={pageNotFound} alt="404" className="image404" />
    </div>
    </>
  );
}

export default PageNotFound;