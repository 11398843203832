import "./ModifierView.scss";
import { useLayoutEffect, useState, useEffect } from "react";
import { Boxed } from "../../../Styles-Elements/Box";
import {
  Button, Switch, Pagination, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Menu, MenuItem
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchInput from "../../../shared/SearchInput";
import threeDotsBlack from "../../../assets/images/structure/three-dots-black.svg";
import editBlack from "../../../assets/images/structure/edit-black.svg";
import deleteBlack from "../../../assets/images/structure/delete-black.svg";
import globalRequest from "../../../global-modules/globalRequest";
import ConfirmBox from "../../../dialog/ConfirmBox";
import { useDispatch, useSelector } from "react-redux";
import { currentLocalData } from "../../../redux/reducers/localData";
import { changeLoader } from "../../../redux/reducers/loader";
import { API, PAGES } from "../../../Enum";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { changePage } from "../../../redux/reducers/page";
import { changeMainProductData } from "../../../redux/reducers/mainEditProductData";
import { useTranslation } from "react-i18next";
import { getdataByKey, getDataByKeyLocale } from "../../../Helpers/Helpers";
import noProduct from "../../../assets/images/structure/no-product.png";
import ActiveInactiveAddOnsForStore from "../../../dialog/ActiveInactiveAddOnsForStore";
import AddonInactiveModal from "../../../dialog/AddonInactiveModal";

const ModifierView = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tokenData = useSelector(currentLocalData);
  const limit = 10
  let [page, setPage] = useState(1);
  let [sortBy, setSortBy] = useState("id");
  let [orderBy, setOrderByd] = useState("asc");
  let [search, setSearch] = useState("");
  let [addOns, setAddOns] = useState([]);
  let [total, setTotal] = useState(1);
  let [deleteId, setDeleteId] = useState(null);
  let [ConfirmBoxOpen, setConfirmBoxOpen] = useState(false);
  let [storeOptions, setStoreOptions] = useState([]);
  let [selectedAddon, setSelectedAddon] = useState(null);

  // model for Active stores
  let [activeStores, setActiveStores] = useState([]);
  let [showActiveProductModal, setShowActiveProductModal] = useState(false);

  // model for Inactive stores
  let [inactiveStores, setInactiveStores] = useState([]);
  let [showInactiveProductModal, setShowInactiveProductModal] = useState(false);

  // 
  const [showAddonInactiveModal, setShowAddonInactiveModal] = useState(false);
  let [addonToEdit, setAddonToEdit] = useState(null);

  const BasicMenu = ({ obj }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {setAnchorEl(event.currentTarget);};
    
    const handleClose = () => {setAnchorEl(null);};

    return (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          ><img src={threeDotsBlack} alt="" /></Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{"aria-labelledby": "basic-button",}}
        >
          <MenuItem
            onClick={() => {    
                handleClose();
                dispatch(changeMainProductData(obj));
                dispatch(changePage(PAGES.ADD_ADDON));
            }}
          >
            <img src={editBlack} alt="" />
            <span className="p2-drop-menu">{t("manageModifier_Edit")}</span>
          </MenuItem>
         {tokenData.token.role === 'vendor'? <MenuItem
            onClick={() => {
              deleteId = obj?.id;
              setDeleteId(deleteId);
              setConfirmBoxOpen(true);
              handleClose();
            }}
          >
            <img src={deleteBlack} alt="" />
            <span className="p2-drop-menu">{t("manageModifier_Delete")}</span>
          </MenuItem>: null}
        </Menu>
      </div>
    );
  };
  
  const sortAsc = (column) => {
    sortBy = column;
    setSortBy(sortBy);
    orderBy = "asc";
    setOrderByd(orderBy);
    getAllAddOns();
  };

  const sortDesc = (column) => {
    sortBy = column;
    setSortBy(sortBy);
    orderBy = "desc";
    setOrderByd(orderBy);
    getAllAddOns();
  };

  const Sorting = ({ sortAsc, sortDesc }) => {
    return (
      <>
        <ArrowDropUpIcon onClick={sortAsc} />
        <ArrowDropDownIcon onClick={sortDesc} />
      </>
    );
  };

  const getAllAddOns = async () => {
    dispatch(changeLoader(true));
    let params = {
      page: page,
      limit: limit,
      sort_by: sortBy,
      order_by: orderBy,
      search: search
    };
    globalRequest('get', API.ADDONS.GET, {}, {params: params}, true, true).then(({data, status}) => {
      if(data.ack == '1'){
        addOns = data?.addons.map((obj) => {
          if (tokenData.token.role === 'vendor_users') {
            const isInactive = obj?.manage_store_addons.filter((store) => store.storeId === tokenData.token.store.id).length > 0;
            obj.status = isInactive?'inactive': 'active';
          }
          const allStores = storeOptions?.filter((store) => store.value !== 0).length;
          const totalActiveStore = allStores - obj?.manage_store_addons.length;
          obj.activeStore = totalActiveStore
          obj.inactiveStore = obj?.manage_store_addons.length;
          return obj
        });
        setAddOns(addOns);
        total = data?.totalCount;
        setTotal(total)
      } else {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: 'Something went wrong!',
            state: "error",
          })
        );
      }
      dispatch(changeLoader(false));
    }).catch((err) => {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: 'Something went wrong!',
          state: "error",
        })
      );
    });
    dispatch(changeLoader(false));
  };

  const handleStatusChange = (id, status) => {
    dispatch(changeLoader(true));
    const data = {
      status: status
    };
    globalRequest('put', API.ADDONS.UPDATE_STATUS(id), data, {}, true, true).then(({data, status}) => {
      if (data.ack == 1) {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: data?.msg,
            state: "success",
          })
        );
        getAllAddOns();
      } else {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: data?.msg,
            state: "error",
          })
        );
      }
    }).catch((err) => {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: 'Something went wrong!',
          state: "error",
        })
      );
    });
  };

  const getStoreOptions = async () => {
    dispatch(changeLoader(true));
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + tokenData.token.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    storeOptions =[];
    if (response.data.ack === 1) {
      storeOptions = [{ label: "All Stores", value: 0, secondLabel: "" }]
      storeOptionArray = response.data.stores
        .filter((store) => (store.is_approved === null || store.is_approved === true)).map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address
        }
      });
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    storeOptions = [...storeOptions, ...storeOptionArray];
    setStoreOptions(storeOptions);
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      getAllAddOns()
    }, 500);
    return () => clearTimeout(getData);
  }, [search]);
  

  useLayoutEffect(() => {
    getStoreOptions();
  }, []);
  
  // useEffect(() => {
  //   getAllAddOns();
  // }, []);

  return (
    <div className="main-mid-wrapper pb-0">
      <div className="order-listing-wrapper">
        <Boxed className="top-flex-row" mb={"20px"}>
          <div className="store-detail-head" style={{ marginBottom: "0px" }}>
            <h3 className="h3">{t("manageModifier_ManageAddons")}</h3>
          </div>
          <Boxed
            display={"flex"}
            alignItems={"center"}
            className="filter-search"
          >
            <SearchInput
              onChange={(e) => {
                search = e.target.value;
                setSearch(search);
              }}
            />
            {tokenData.token.role === 'vendor'?<Button
              onClick={() => {
                dispatch(changeMainProductData(null));
                dispatch(changePage(PAGES.ADD_ADDON));
              }}
              variant="contained"
              size="small"
              sx={{ width: "150px" }}
            >
              {t("manageModifier_AddAddon")}
            </Button>: null}
          </Boxed>
        </Boxed>

        {/* <Boxed
          display={"flex"}
          alignItems={"center"}
          justifyContent={"start"}
          mb={"20px"}
          className="table-switch-box"
        >
          <Switch 
            onClick={() => {

            }}  />
          <div className="relative mx-3">
            <h6 className="h6">Make Add ons Inactive</h6>
            <span className="p3">If you mark it as inactive, all the add ons listed in it will get disabled.</span>
          </div>
        </Boxed> */}
        <div className="">
          {addOns?.length > 0?<div className="table-block">
            <div className="table-wrapper">
              <TableContainer
                component={Paper}
                sx={{ maxHeight: "calc(100vh - 265px)" }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div className="short-div">
                          {t("manageModifier_AddonGroupName_Engilsh")}
                          <div className="short-box">
                            <Sorting
                              sortAsc={() => sortAsc("name")}
                              sortDesc={() => sortDesc("name")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          {t("manageModifier_AddonGroupName_Arabic")}
                          <div className="short-box">
                            <Sorting
                              sortAsc={() => sortAsc("name_arabic")}
                              sortDesc={() => sortDesc("name_arabic")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          {t("manageModifier_AddonSelectType")}
                          <div className="short-box">
                            <Sorting
                              sortAsc={() => sortAsc("type")}
                              sortDesc={() => sortDesc("type")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center">{t("manageProducts_Active_Inactive")}</TableCell>
                      {tokenData.token.role === 'vendor'?<TableCell align="center">{t("manageProducts_ActiveStores")}</TableCell>: null}
                      {tokenData.token.role === 'vendor'?<TableCell align="center">{t("manageProducts_InactiveStores")}</TableCell>: null}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addOns?.length > 0 ? addOns.map((obj, index) =>
                      <TableRow
                        key={`table-row-addons-${index}`}
                      className={"pointer"}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{getdataByKey(obj?.addons_locales, 'name', 'en')}</TableCell>
                      <TableCell>{getdataByKey(obj?.addons_locales, 'name', 'ar')}</TableCell>
                      <TableCell>{obj?.type}</TableCell>
                      <TableCell align="center" className="pointer">
                        <Switch
                          checked={obj?.status == 'active' ? true : false}
                          onClick={(e) => {
                            addonToEdit = { status: e.target.checked ? "active" : "inactive", addon: obj }
                            setAddonToEdit(addonToEdit)
                            setShowAddonInactiveModal(true)
                          }}
                        ></Switch>
                      </TableCell>
                     {tokenData.token.role === 'vendor'? <TableCell align="center"
                        onClick={() => {
                          if (obj?.activeStore > 0) {
                            selectedAddon = obj;
                            setSelectedAddon(selectedAddon);
                            const inactiveStoreIds = obj?.manage_store_addons.map((store) => store.storeId);
                            activeStores = storeOptions?.filter((store) => !inactiveStoreIds.includes(store.value))
                            setActiveStores(activeStores)
                            setShowActiveProductModal(true)
                          }
                        }}
                      >
                        <span style={obj?.activeStore > 0?{textDecoration: 'underline'}: {}}>{obj?.activeStore}</span>
                      </TableCell>: null}
                     {tokenData.token.role === 'vendor'? <TableCell align="center"
                        onClick={() => {
                          if (obj?.inactiveStore > 0) {
                            selectedAddon = obj;
                            setSelectedAddon(selectedAddon);
                            const inactiveStoreIds = obj?.manage_store_addons.map((store) => store.storeId);
                            inactiveStores = storeOptions?.filter((store) => inactiveStoreIds.includes(store.value))
                            setInactiveStores(inactiveStores)
                            setShowInactiveProductModal(true)
                          }
                        }}
                      >
                        <span style={obj?.inactiveStore > 0?{textDecoration: 'underline'}: {}}>{obj?.inactiveStore}</span>
                      </TableCell>: null}

                      <TableCell>
                      <BasicMenu obj={obj} />
                      </TableCell>

                    </TableRow>) : null}
                  </TableBody>
                </Table>
              </TableContainer>
              {Math.ceil(total / limit) > 1?<div className="pagination-wrap">
                <Pagination
                  count={Math.ceil(total / limit)}
                  onChange={(e, newpage) => {
                    page = newpage;
                    setPage(page);
                    getAllAddOns();
                  }}
                />
              </div>: null}
            </div>
          </div>:
           <div className="empty-box">
           <img src={noProduct} alt="..." className="empty-img" />
             <h4 className="h4">{t("manageProducts_noAddonsAvailable")}</h4>
             <p className="p2">{t("manageProducts_noAddonsAvailableText")}</p>
             <Button
              onClick={() => {
                dispatch(changeMainProductData(null));
                dispatch(changePage(PAGES.ADD_ADDON));
              }}
              variant="contained"
              size="small"
              sx={{ width: "150px" }}
            >
              {t("manageModifier_AddAddon")}
            </Button>
         </div>}
        </div>
      </div>

      {ConfirmBoxOpen ? <ConfirmBox
        isOpen={ConfirmBoxOpen}
        handleClose={() => {
          setConfirmBoxOpen(false);
        }}
        confirm={() => {
          setConfirmBoxOpen(false);
          dispatch(changeLoader(true));
          handleStatusChange(deleteId, 'deleted');
        }}
        message={t("manageModifier_AreYouSureYouWantToDeleteThisAddon")}
      />: null}

      {showActiveProductModal ? <ActiveInactiveAddOnsForStore
        handleClose={() => setShowActiveProductModal(false)}
        type={"active"}
        stores={activeStores.filter((store) => store.value !== 0)}
        selectedAddon={selectedAddon}
        getAddons={() => {
          getAllAddOns()
          setShowActiveProductModal(false)
        }}
      />: null}
      {showInactiveProductModal ? <ActiveInactiveAddOnsForStore
        handleClose={() => setShowInactiveProductModal(false)}
        type={"inactive"}
        stores={inactiveStores.filter((store) => store.value !== 0)}
        selectedAddon={selectedAddon}
        getAddons={() => {
          getAllAddOns()
          setShowInactiveProductModal(false)
        }}
      />: null}
      {
        showAddonInactiveModal? <AddonInactiveModal
          handleClose={() => setShowAddonInactiveModal(false)}
          addonToEdit={addonToEdit}
          storeOptions={storeOptions}
          getAddons={() => {
            getAllAddOns()
            setShowAddonInactiveModal(false)
          }}
        />: null
      }
      
    </div>
  );
};

export default ModifierView;

