import { API_URL } from "./BaseUrl";

const version = "/v6";
const APIURL = API_URL + version;

export const API = {
  GET_BALADI_INFO: APIURL + "/auth/get-baladi-info", // GET
  GET_TOP_PRODUCTS: APIURL + "/dashboard/top-10-products", // GET
  GET_GRAPH_DATA: APIURL + "/dashboard/revenue", // GET
  SIGN_UP: APIURL + "/auth/signup", // POST
  SIGN_UP_NEW: APIURL + "/auth/signup-new", // POST
  GET_SIGNUP_OTP: APIURL + "/auth/get-signup-otp", // POST
  CHECK_SET_PASSWORD: APIURL + "/auth/check-set-password", // POST
  SET_PASSWORD: APIURL + "/auth/set-password", // POST
  VERIFY_SIGNUP_OTP: APIURL + "/auth/verify-signup-otp", // POST
  X_API_KEY: "baladi_dev_pX2Rldl9LSFlVSVM3TDN4YTA3ZHNmZ2R5V0E4ZE1S", // development
  // X_API_KEY: "baladi_dev_KHYUIS7L3xa07dsfgdyWA8dMRRsFkc1sfd3432jLB", // staging
  BUSINESS_TYPE: APIURL + "/business-type/get-all-list", // GET
  GET_SING_IN_OTP: APIURL + "/auth/get-signin-otp", //POST
  SIGN_IN_PASSWORD: APIURL + "/auth/signin", //POST
  SIGN_IN_OTP: APIURL + "/auth/signin-otp", //POST
  FORGET_PASSWORD_OTP_SEND: APIURL + "/auth/forgot-password", //POST
  FORGET_PASSWORD_VERIFY: APIURL + "/auth/verify-forgot-password", //POST
  FORGET_UPDATE_PASSWORD: APIURL + "/auth/set-password", //POST
  MANAGE_WORKING_HOUR: APIURL + "/working-hour/manage-working-hour", //POST
  PAYOUT_DETAIL: APIURL + "/payout-detail/add-account", //POST
  MANAGE_HOLIDAY: APIURL + "/working-hour/manage-working-holiday", //POST
  GET_WORKING_HOLIDAY: APIURL + "/working-hour/get-working-holiday", //GET
  SKIP_STEPS: APIURL + "/auth/skipsteps",
  ADD_STORE: APIURL + "/stores/add-store",
  UPDATE_STORE: (storeId) => `${APIURL}/stores/edit-store/${storeId}`,
  UPDATE_STORE_ONLINE_OFFLINE: APIURL + "/stores/make-status-online-offline",
  GET_ALL_MY_STORES: APIURL + "/stores/get-all-my-stores",
  GET_TIMEZONE: APIURL + "/auth/get-time-zone",
  CHANGE_LANGUAGE: APIURL + "/auth/change-language",
  UPDATE_WORKING_HOLIDAY: (id) => `${APIURL}/working-hour/manage-working-holiday/${id}`,
  DELETE_WORKING_HOLIDAY: (id) => `${APIURL}/working-hour/manage-working-holiday/${id}`,
  ADD_WORKING_HOLIDAY: () => `${APIURL}/working-hour/manage-working-holiday`,
  GET_SPECIFIC_STORE: (id) => `${APIURL}/stores/get-specific/${id}`,
  ACTIVE_INACTIVE_STORE: (id) => `${APIURL}/stores/store-status/${id}`,
  EDIT_STORE: (id) => `${APIURL}/stores/edit-store/${id}`,
  EDIT_ACCOUNT: (id) => `${APIURL}/payout-detail/edit-account/${id}`,
  MANAGE_WORKING_HOURS_BY_ID: (id) => `${APIURL}/working-hour/manage-working-hour-byid/${id}`,
  EDIT_HOLIDAY_BY_ID: (id) => `${`${APIURL}/working-hour/manage-working-holiday/${id}`}`,
  GET_WORKING_HOUR: `${APIURL}/working-hour/get-working-hour`,
  GET_PAYOUT_DETAIL: `${APIURL}/payout-detail/get-account`,
  GET_STORE_DETAIL: (id) => `${APIURL}/stores/detail/${id}`,
  GET_STORE_SPECIFIC: (id) => `${APIURL}/stores/get-specific/${id}`,
  GET_UNIT: `${APIURL}/units/all`,
  GET_CATEGORY: (id) => `${APIURL}/category/get-all-categories/${id}`,
  GET_CATEGORY_BY_STORE: (id) => `${APIURL}/store-category-realtion/get-categories/${id}`,
  GET_CATEGORY_BY_BRAND: (id) => `${APIURL}/store-category-realtion/get-brand-categories/${id}`,
  CHECK_ITEM_CODE: `${APIURL}/products/check-itemcode`,
  ADD_PRODUCT: `${APIURL}/products/add-product`,
  EDIT_PRODUCT_REQUEST: (id) => `${APIURL}/products/get-edit-product-specific/${id}`,
  GET_PRODUCT: `${APIURL}/products/get-all-my-products`,
  GET_PRODUCT_DETAILS: (productId) => `${APIURL}/products/get-specific/${productId}`,
  DELETE_PRODUCT: (productId) => `${APIURL}/products/delete-product/${productId}`,
  UPDATE_PRODUCT_STOCK_STATUS: (productId) => `${APIURL}/products/edit-product-stock/${productId}`,
  UPDATE_PRODUCT: (productId) => `${APIURL}/products/edit-product/${productId}`,
  UPLOAD_PRODUCT_IMAGE: `${APIURL}/products/upload-product-image`,
  UPLOAD_PRODUCT_IMAGE_UOM: `${APIURL}/products/upload-uom-image`,
  EDIT_PRODUCT: (productId) => `${APIURL}/products/edit-product/${productId}`,
  GET_EDIT_REQUEST_DATA: `${APIURL}/products/get-all-edit-products`,
  GET_REGISTRATION_STEP: `${APIURL}/users/get-signup-steps-status`,
  // GET_ORDER: `${APIURL}/orders/get-all-my-orders`,
  GET_ORDER: `${APIURL}/orders/get-all-my-orders-new`,
  GET_ORDER_DETAILS: (orderId) => `${APIURL}/orders/order-detail/${orderId}`,
  GET_ORDER_COUNT: `${APIURL}/orders/get-all-order-count`,
  UPDATE_ORDER_STATUS: `${APIURL}/orders/update-status`,
  UPDATE_ORDER_STATUS_MARKETPLACE: `${APIURL}/orders/update-order-status`,
  UPDATE_ORDER_CONFIRM: (orderId) => `${APIURL}/orders/update-pending-order/${orderId}`,
  UPDATE_PASSWORD: `${APIURL}/auth/set-password`,
  GET_USER_DETAILS: (userId) => `${APIURL}/users/get-specific/${userId}`,
  REFRESH_TOKEN: `${APIURL}/auth/refresh_token?platform=vendor-web-abdul`,
  CHANGE_VENDOR_STORE: (storeId) => `${APIURL}/auth/refresh_token/${storeId}`,
  GET_All_MY_PAYOUTS: `${APIURL}/payouts/get-all-my-payouts`,
  EXPORT_ALL_MY_PAYOUTS: `${APIURL}/payouts/export-all-payouts`,
  GET_ORDER_PAYOUT_DETAILS: (id) => `${APIURL}/payouts/detail/${id}`,
  GET_ORDER_PAYOUT_DETAILS_DOWNLOAD: (id) => `${APIURL}/payouts/export-detail/${id}`,
  CHANGE_STORE_STATUS: `${APIURL}/users/change-online-status`,
  GET_NOTIFICATIONS: `${APIURL}/notifications/get-all`,
  MARK_AS_READ: `${APIURL}/notifications/mark-read-all`,
  CHANGE_PASSWORD: `${APIURL}/users/change-password`,
  GET_ONLINE_OFFLINE_STATUS: `${APIURL}/users/get-online-status`,
  GET_ORDER_INVOICE: (orderId) => `${APIURL}/orders/invoice/${orderId}`,
  REJECT_PENDING_ORDER: (orderId) => `${APIURL}/orders/reject-pending-order/${orderId}`,
  CHANGE_PRODUCT_STATUS: (productId) => `${APIURL}/products/update-product-status/${productId}`,
  UPDATE_GENERAL_SETTINGS: (store_id) => `${APIURL}/users/update-general-setting/${store_id}`,
  GET_CUISINE: `${APIURL}/cuisines/get-all-cuisine`,
  UOM: {
    CHECK_BARCODE: `${APIURL}/products/check-barcode`,
    ADD: `${APIURL}/products/add-product-uom`,
    DELETE: (id) => `${APIURL}/products/delete-uom/${id}`,
    GET: (id) => `${APIURL}/products/get-uom-detail/${id}`,
    EDIT: (id) => `${APIURL}/products/edit-product-uom/${id}`,
  },
  STATIC_PAGES: {
    GET_STATIC_PAGES: (slug) => `${APIURL}/cms/get-one/${slug}?role=vendor`,
    GET_FAQS: `${APIURL}/faq/get-all?role=vendor`,
  },
  ADDONS: {
    GET: `${APIURL}/addons/get-all-my-addons`,
    ADD: `${APIURL}/addons/add`,
    UPDATE_STATUS: (id) => `${APIURL}/addons/update-status/${id}`,
    GET_SPECIFIC: (id) => `${APIURL}/addons/specific/${id}`,
    UPDATE: (id) => `${APIURL}/addons/edit/${id}`,
  },
  CATEGORY_MANAGEMENT: {
    GET_ALL_CATEGORY: (store_id) => `${APIURL}/store-category-realtion/get-categories/${store_id}`,
    GET_PRODUCTS: `${APIURL}/store-category-realtion/get-all-products`,
    CATEGORY_IMAGE_UPLOAD: `${APIURL}/store-category-realtion/update-category-image`,
    GET_WORKING_HOURS: `${APIURL}/working-hour/get-working-hour`,
    UPDATE_CATEGORY_HOURS: `${APIURL}/store-category-realtion/update-category-new`,
    CHANGE_SEQUENCE: `${APIURL}/store-category-realtion/change-sequence-new`,
    UPDATE_PRODUCT_STATUS: (productId) => `${APIURL}/products/update-product-status/${productId}`,
    SYNC_CATEGORY: (store_id) => `${APIURL}/category/create-store-category-json/${store_id}`,
    BULK_STORE_CATEGORY_UPDATE : `${APIURL}/store-category-realtion/bulk-store-category-update`,
    INACTIVE_CAT_STORE_LIST : (brand_id, categoryId) => `${APIURL}/store-category-realtion/get-inactive-cat-store-list/${brand_id}/${categoryId}`,
  },
  MANAGE_USERS: {
    GET_STORES_FOR_DROPDOWN: `${APIURL}/stores/get-all-stores-list-for-dropdown`,
    GET_USERS: `${APIURL}/stores/get-all-vendor-user`,
    ADD_USER: `${APIURL}/stores/add-vendor-user`,
    GET_USERS_BY_ID: (userId) => `${APIURL}/stores/get-vendor-user/${userId}`,
    DELETE_USER: (userId) => `${APIURL}/stores/delete-vendor-user/${userId}`,
    UPDATE_USER: (userId) => `${APIURL}/stores/edit-vendor-user/${userId}`,
    CHANGE_STATUS: (userId) => `${APIURL}/stores/change-vendor-user-status/${userId}`,
  },
  MANAGE_STORE_TIMING: {
    GET_STORE_TIMING: `${APIURL}/working-hour/get-stores-with-working-hour`,
    UPDATE_WORKING_HOURS_STORE: (storeId) => `${APIURL}/working-hour/manage-working-hour-byid/${storeId}`,
    UPDATE_WORKING_HOURS_ALL_STORES: `${APIURL}/working-hour/manage-working-hour-for-all`,
    CHECK_STORE_OVERWRITE: `${APIURL}/working-hour/overwrite-working-hour-check`,
    GET_STORE_TIMING_USER: `${APIURL}/working-hour/get-working-hour`,
  },
  MANAGE_PAYOUTS: {
    GET_MANAGE_PAYOUTS: `${APIURL}/payout-detail/get-all-stores-with-account`,
    SAVE_PAYOUT_DETAIL_FOR_STORE: (storeId) => `${APIURL}/payout-detail/edit-account/${storeId}`,
    CHECK_STORE_OVERWRITE: `${APIURL}/payout-detail/check-payout-account-overwrite`,
    SAVE_PAYOUT_DETAIL_FOR_ALL_STORE: `${APIURL}/payout-detail/add-account-for-all`,
  },
  MANAGE_HOLIDAYS: {
    GET_ALL_HOLIDAYS: `${APIURL}/working-hour/get-working-holiday`,
    GET_ALL_HOLIDAYS_FOR_BRAND: `${APIURL}/working-hour/get-all-working-holiday`,
    ADD_HOLIDAYS_BY_STORE: `${APIURL}/working-hour/manage-working-holiday-for-all`,
    EDIT_HOLIDAYS_FOR_BRAND: (holidayId) => `${APIURL}/working-hour/edit-manage-working-holiday/${holidayId}`,
    DELETE_HOLIDAY: (holidayId) => `${APIURL}/working-hour/manage-working-holiday/${holidayId}`,
    DELETE_HOLIDAY_FOR_BRAND: (holidayId) => `${APIURL}/working-hour/manage-working-holiday-for-brand/${holidayId}`,
  },
  MANAGE_PRODUCTS: {
    GET_ALL_PRODUCTS: `${APIURL}/products/get-all-my-products-new`,
    CHANGE_PRODUCT_STATUS: `${APIURL}/products/update-product-status-for-stores`,
    CHANGE_UOM_STATUS: `${APIURL}/products/update-uom-status-for-stores`,
    CHANGE_ADDON_STATUS: `${APIURL}/addons/update-store-status`,
  }
};

export const API_GET_GEOLOCATION = `https://services.gisqatar.org.qa/server/rest/services/Vector/QARS1/MapServer/0/query`;
export const VALIDATE_COORDINATES = `https://epsg.io/trans`;

export const STORAGE = {
  TOKEN: "nc1P98LKd",
  EMAIL: "ionjku0olk",
  MANAGE_WORKING_HOUR: "pou9078ujh",
};

export const KEYS = {
  GOOGLE_RECAPTCHA_SITE_KEY: "6LcjpiIkAAAAAP5s9sGtWwCPQgu9IRVCSZp4rqAQ",
  GOOGLE_RECAPTCHA_SECRET_KEY: "6LcjpiIkAAAAACkoTMbw3JGv8VtBORZx7u7-n1DT",
  GOOLGE_MAP_ADDRESS_KEY: "AIzaSyDZcgHBjIRYDXnj91drlzpOJIEnMH8dzwI",
};

export const COUNTRY_CODE = "+974";

export const ROLE = "vendor";

export const ERROR_MESSAGE = {
  MOBILE_EMAIL: " Please enter your email or mobile number",
  PASSWORD: "Please enter your password",
  INVALID_MOBILE: "Invalid Mobile Number",
  OTP: "Enter Otp Please",
};

export const PAGES = {
  DASHBOARD: "dashboard",
  MANAGE_PRODUCT: "manageProduct",
  MANAGE_MODIFIER: "manageModifier",
  MY_ORDER: "myOrder",
  EARNINGS_PAYOUTS: "earningsPayouts",
  PAYOUTS_DETAIL: "payoutsDetail",
  PAYOUT_REPORT: "payoutReport",
  NOTIFICATION: "notification",
  MANAGE_CATEGORIES: "manageCategories",
  ADD_PRODUCT: "addProduct",
  EDIT_PRODUCT: "editProduct",
  EDIT_UOM: "editUOM",
  MANAGE_STORE: "manageStore",
  MANAGE_STORE_DETAIL: "manageStoreDetail",
  ADD_STORE: "addStore",
  MANAGE_TIMING: "manageTiming",
  MANAGE_TIMING_DETAIL: "ManageTimingDetail",
  MANAGE_TIMING_EDIT: "ManageTimingEdit",
  MANAGE_HOLIDAYS: "ManageHolidays",
  MANAGE_WORKING_HOUR: "manageWorkingHour",
  MANAGE_HOLIDAY: "manageHoliday",
  PAYOUT_DETAIL: "payoutDetail",
  MY_STORE: "myStore",
  MY_OFFER: "myOffer",
  ADD_OFFER: "addOffer",
  MANAGE_USER: "ManageUser",
  MANAGE_USER_ADD_EDIT: "ManageUserDetail",
  MANAGE_PAYOUTS: "ManagePayouts",
  MANAGE_PAYOUTS_DETAIL: "ManagePayoutsDetail"
};

export const ORDER_STATUS = {
  PLACED: "placed",
  PROCESSING: "processing",
  READYTOPICK: "readytopick",
  SHIPPED: "shipped",
  COMPLETED: "completed",
  REJECTED: "rejected",
  CANCELLED: "cancelled",
  PLANNED: "planned",
  PENDING: "pending",
};

export const IMAGE_URL = (imageUrl) => {
  let url = imageUrl.charAt(0) === "/" ? imageUrl : '/' + imageUrl;
  return `${API_URL}${url}`;
}

// 0600
