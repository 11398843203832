import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import modalClose from "../assets/images/structure/close-black.svg";
import { Boxed } from "../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import {
   Button, Dialog, DialogContent
} from "@mui/material";
import SearchSelectStore from "../shared/SearchSelectStore";
import { changeLoader } from "../redux/reducers/loader";
import globalRequest from "../global-modules/globalRequest";
import { API } from "../Enum";

const InactiveAllHolidays = ({
  isOpen = false,
  handleClose,
  stores,
  storeId,
  storeStatusOnOf,
  bulkEdit = true
}) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  
  const handleSave = async () => {
    dispatch(changeLoader(true));
    const body = {
      storeId: storeId,
      status: storeStatusOnOf,
      online_status_not_update_by_cron: selectedValueRadio,
    };
    const response = await globalRequest('post', API.UPDATE_STORE_ONLINE_OFFLINE, body, {}, true, true);
    dispatch(changeLoader(false));
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} className="main-modal maxwidth-430" >
      <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h4 className="h4" style={{ marginBottom: "10px",marginTop: '0px' }}>
          {t("manageStore_doYouWantToMakeTheHolidaysInactive")}
        </h4>
        <p className="p3" style={{ marginBottom: "16px" }}>{t("manageStore_onceDeactivatedAllSelectedHolidays")}</p>
        
        <div className="background-box" style={{ marginBottom: "24px"}}>
            <h6 className="h6" style={{ marginBottom: "8px",marginTop: '8px' }}>Republic Day</h6>
            <p className="p2" style={{ marginBottom: "8px",marginTop: '8px' }}>26 January 2023 </p>
        </div>

        <SearchSelectStore />
        {/* <SearchSelectSingleStore
          options={stores}
          value={stores.find((store) => store.value === storeId)}
          disabled={true}
        /> */}
        
        <Boxed>
          <Button
            type="button" variant="contained" sx={{ width: "100%", marginTop: "20px" }}
            onClick={handleSave}
          >{t("manageStore_save")}</Button>
        </Boxed>
      </DialogContent>
    </Dialog>
  );
};

export default InactiveAllHolidays;
