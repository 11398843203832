import React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import "./Pagination.scss";
import { useEffect } from "react";

const List = styled("ul")({ listStyle: "none", padding: 0, margin: 0, display: "flex",});

function Pagination({ pageNo = (no) => no, countNo = 5 }) {
  
  const { items } = usePagination({count: countNo,});

  return (
    <>
      {countNo>1?<nav className="pagination">
        <List>
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;
            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = "…";
            } else if (type === "page") {
              children = (<button type="button" {...item}>{page}</button>);
            } else {
              children = (<button type="button" {...item}>{type}</button>);
            }
            return (<li onClick={() => {pageNo(page);}} key={index} >{children}</li>);
          })}
        </List>
      </nav>:null}
    </>
  );
}

export default Pagination;
