import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import "./PayoutDetails.scss";
import { Button, Grid } from "@mui/material";
import { Boxed } from "../../../Styles-Elements/Box";
import TextField from "@mui/material/TextField";
import modalClose from "../../../assets/images/structure/close-black.svg";
import thankyouImg from "../../../assets/images/structure/thankyou-img.png";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { API, PAGES } from "../../../Enum";
import globalRequest from "../../../global-modules/globalRequest";
import skipReg from "../../../global-modules/skipReg";
import { useEffect } from "react";
import { changePage } from "../../../redux/reducers/page";
import {currentLocalData,} from "../../../redux/reducers/localData";
import { useTranslation } from "react-i18next";

const PayoutDetails = () => {
  const [openThankYou, setOpenThankYou] = useState(false);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(true);
  const [accountNo, setAccountNo] = useState("");
  const [accountNoErr, setAccountNoErr] = useState(true);
  const [iban, setIban] = useState("");
  const [ibanErr, setIbanErr] = useState(true);
  const [swiftCode, setSwiftCode] = useState("");
  const [switchCodeErr, setSwiftCodeErr] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localData = useSelector(currentLocalData);

  const getAccount = () => {
    globalRequest("get", API.GET_PAYOUT_DETAIL, {}, true, true)
      .then((res) => {
        const data = res?.data;
        if (data?.ack == 1) {
          let pay = data.payoutDetail;
          setName(pay?.beneficiaryName);
          setAccountNo(pay?.bankAccountNumber);
          setIban(pay?.iban);
          setSwiftCode(pay?.swiftCode);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAccount();
  }, []);

  const Thankyou = ({ isOpen = false, handleClose }) => {
    return (
      <Dialog open={isOpen} onClose={handleClose} className="main-modal">
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose}
          className="modal-close"
        />
        <DialogContent>
          <Boxed
            className="text-center"
            style={{ marginBottom: "20px", marginTop: "10px" }}
          >
            <img src={thankyouImg} alt="" />
          </Boxed>
          <h2 className="h3 text-center" style={{ marginBottom: "8px" }}>{t("accountSetup_welcomeToBaladiExpress")}</h2>
          <p
            className="p3 text-center"
            style={{ marginBottom: "12px", fontSize: "14px" }}
          >{t("accountSetup_youHaveSuccessfullyCompletedYourAccountSetupNowYouCanAddProductToYourStore")}</p>
          <Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Button
              variant="contained"
              size="large"
              sx={{ width: "150px", marginTop: "10px" }}
            >
              {t("accountSetup_addProduct")}
            </Button>
          </Boxed>
        </DialogContent>
      </Dialog>
    );
  };

  const submit = () => {
    if (name && accountNo && iban && swiftCode) {
      dispatch(changeLoader(true));
      globalRequest(
        "post",
        API.PAYOUT_DETAIL,
        {
          beneficiaryName: name,
          bankAccountNumber: accountNo,
          iban: iban,
          swiftCode: swiftCode,
        },
        {},
        true,
        true
      )
        .then(async (res) => {
          let data = res?.data;

          dispatch(changeLoader(false));
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: data?.ack == 1 ? "success" : "error",
              message: data?.msg,
            })
          );
          await skipReg(3);
          dispatch(changePage(PAGES.DASHBOARD));
        })
        .catch((err) => {
          dispatch(changeLoader(false));
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: "error",
              message: t("somethingWentWrong"),
            })
          );
        });
    } else {
      setNameErr(false);
      setAccountNoErr(false);
      setIbanErr(false);
      setSwiftCodeErr(false);
    }
  };

  return (
    <>
      <div className="payout-detail-wrapper">
        <div className="manage-workinghours-head">
          <h3 className="h3">{t("accountSetup_completeYourAccountSetup")}</h3>
        </div>
        <div className="container-body">
          <Grid container columnSpacing={{ xs: 0, sm: 1, md: 1, lg: 1 }}>
            <Grid item xs={12} sm={12} md={10} mx={"auto"}>
              <div className="working-hours-block">
                <div className="card-white">
                  <h4 className="h4">{t("accountSetup_addPayoutDetails")}</h4>
                  <p className="p2">
                    {t(
                      "payoutDetailsAreTheBankDetailsInWhichYouAreExpectingThePayments"
                    )}
                  </p>
                  <Grid
                    container
                    columnSpacing={{ xs: 0, sm: 2, md: 2, lg: 2 }}
                  >
                    <Grid item xs={8}>
                      <div className="form-group">
                        <TextField
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                          label={t("accountSetup_beneficiaryName")}
                        />
                        {!name && !nameErr ? (
                          <span className="redspan">
                            {t('payout_beneficiaryName')}
                          </span>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    columnSpacing={{ xs: 0, sm: 2, md: 2, lg: 2 }}
                  >
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <div className="form-group">
                        <TextField
                          value={accountNo}
                          onChange={(e) => setAccountNo(e.target.value)}
                          required
                          label={t("accountSetup_bankAccountNumber")}
                        />
                        {!accountNoErr && !accountNo ? (
                          <span className="redspan">
                            {t('payout_account_no')}
                          </span>
                        ) : null}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <div className="form-group">
                        <TextField
                          value={iban}
                          onChange={(e) => setIban(e.target.value)}
                          required
                          label={t("accountSetup_iban")}
                        />
                        {!iban && !ibanErr ? (
                          <span className="redspan">{t('payout_iban')}</span>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    columnSpacing={{ xs: 0, sm: 2, md: 2, lg: 2 }}
                  >
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <div className="form-group">
                        <TextField
                          value={swiftCode}
                          onChange={(e) => setSwiftCode(e.target.value)}
                          required
                          label={t("accountSetup_swiftCode")}
                        />
                        {!swiftCode && !switchCodeErr ? (
                          <span className="redspan">
                            {t('payout_swiftCode')}
                          </span>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Boxed
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mb={"30px"}
                >
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ width: "150px", marginTop: "10px" }}
                    className="secondary-outline-gray"
                    onClick={() => dispatch(changePage(PAGES.MANAGE_HOLIDAY))}
                  >
                    {t("accountSetup_back")}
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ width: "150px", marginTop: "10px" }}
                    onClick={submit}
                  >
                    {t("accountSetup_getStarted")}
                  </Button>
                </Boxed>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Thankyou
        isOpen={openThankYou}
        handleClose={() => {
          setOpenThankYou(false);
        }}
      />
    </>
  );
};

export default PayoutDetails;
