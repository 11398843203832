/**
 * @file NoDataFound.js
 * @description This component is used to show no data found page.
 * @exports NoDataFound component
 * @imports text - text to be shown on the page
 */

import "./NoDataFound.scss"
import nodata from "../../assets/images/structure/no results found.svg"

const NoDataFound = ({ text }) => {
  return (
    <div className='empty-wishlist-block'>
      <img src={nodata} alt={text} />
      <p className='p2'>{text}</p>
    </div>
  );
}

export default NoDataFound;