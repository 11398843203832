import { useEffect, useState } from "react";
import "./AddAddOn.scss";
import { Dropdown } from "primereact/dropdown";
import { Alert } from "@mui/material";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { Grid, Button, Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import infoIcon from "../../../assets/images/structure/info-gray.png";
import deleteIcon from "../../../assets/images/structure/delete-black.svg";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { currentLocalData } from "../../../redux/reducers/localData";
import { API, PAGES } from "../../../Enum";
import globalRequest from "../../../global-modules/globalRequest";
import { changeLoader } from "../../../redux/reducers/loader";
import { changePage } from "../../../redux/reducers/page";
import { useTranslation } from "react-i18next";
import { amountMask } from "../../../global-modules/MaskedInputs";
import {
  currentMainProductData,
  changeMainProductData
} from "../../../redux/reducers/mainEditProductData";
import {getdataByKey} from "../../../Helpers/Helpers";

const cities = [
  { name: "Single Select", code: "single" },
  { name: "Multiple Select", code: "multiple" },
];

const AddAddOn = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localData = useSelector(currentLocalData);
  const tokenData = localData.token;
  const editAddonData = useSelector(currentMainProductData);
  let [groupName, setGroupName] = useState("");
  let [groupNameError, setGroupNameError] = useState('');
  let [groupNameArabic, setGroupNameArabic] = useState("");
  let [groupNameArabicError, setGroupNameArabicError] = useState('');
  let [minimum, setMinimum] = useState(0);
  let [maximum, setMaximum] = useState(0);
  let [isActive, setIsActive] = useState(true);
  let [selectedCity, setSelectedCity] = useState({ name: "Single Select", code: "single" });
  let [addonItems, setAddonItems] = useState([{
    id: 0,
    name: "",
    nameError: "",
    name_arabic: "",
    name_arabicError: "",
    price: "",
    priceError: "",
    isActive: true
  }]);
  let [removedAddonItems, setRemovedAddonItems] = useState([]);
  let [haveActiveItems, setHaveActiveItems] = useState(true);

  const increment = (type) => {
    if(type === 'minimum') {
      if (selectedCity.code === 'single') {
        if (parseInt(minimum) === 1) { return; }
        minimum++;
      } else {
        minimum++;
      }
      setMinimum(minimum);
    } else {
      maximum++;
      setMaximum(maximum);
    }
  };

  const decrement = (type) => {
    if(type === 'minimum') {
      if (parseInt(minimum) === 0) { return; }
      minimum--;
      setMinimum(minimum);
    } else {
      if (parseInt(maximum) === 0) { return; }
      maximum--;
      setMaximum(maximum);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(changeLoader(true));
    let validation = true;
    if (groupName.trim() === "") {
      setGroupNameError(t("manageModifier_AddonGroupName_EngilshIsRequire"));
      validation = false;
    }
    if (groupNameArabic.trim() === "") {
      setGroupNameArabicError(t("manageModifier_AddonGroupName_ArabicIsRequire"));
      validation = false;
    }
    if (parseInt(maximum) > 0) {
      if (parseInt(maximum) < parseInt(minimum)) {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("manageModifier_MaximumShouldBeGreater"),
            state: "error",
          })
        );
        validation = false;
      } else if (addonItems.length < parseInt(maximum)) {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("manageModifier_MaximumShouldBeLess"),
            state: "error",
          })
        );
        validation = false;
      }
    
    } 
    const activeAddonItems = addonItems.filter((item) => item.isActive);
    addonItems.forEach((element, index) => {
      if(element.name.trim() === "") {
        let newAddonItems = [...addonItems];
        newAddonItems[index].nameError = t("manageModifier_AddonItemName_EnglishIsRequired");
        setAddonItems(newAddonItems);
        validation = false;
      }
      if(element.name_arabic.trim() === "") {
        let newAddonItems = [...addonItems];
        newAddonItems[index].name_arabicError = t("manageModifier_AddonItemName_ArabicIsRequired");
        setAddonItems(newAddonItems);
        validation = false;
      }
      // if(element.price.trim() === "") {
      //   let newAddonItems = [...addonItems];
      //   newAddonItems[index].priceError = t("manageModifier_AddonItemPriceIsRequired");
      //   setAddonItems(newAddonItems);
      //   validation = false;
      // }
      if (element.name.trim() !== "" && element.name_arabic.trim() !== "") {
        // check for duplicate name and name_arabic in addonItems
        addonItems.forEach((item, itemIndex) => {
          if (index !== itemIndex) {
            if (item.name === element.name) {
              let newAddonItems = [...addonItems];
              newAddonItems[index].nameError = t("manageModifier_AddonItemName_EnglishShouldBeUnique");
              setAddonItems(newAddonItems);
              validation = false;
            }
            if (item.name_arabic === element.name_arabic) {
              let newAddonItems = [...addonItems];
              newAddonItems[index].name_arabicError = t("manageModifier_AddonItemName_ArabicShouldBeUnique");
              setAddonItems(newAddonItems);
              validation = false;
            }
          }
        });
      }
    });
    if (parseInt(minimum) > activeAddonItems.length) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t("manageModifier_MinimumNumberOdActiveItemDoNotMatch"),
          state: "error",
        })
      );
      validation = false;
    }
    if (validation) {
      let data = {
        name: groupName,
        name_arabic: groupNameArabic,
        minimum_item: minimum,
        maximum_item: maximum,
        type: selectedCity.code,
        status: isActive? 'active': 'inactive',
        items: addonItems.map((item) => {
          let obj = {
            name: item.name,
            name_arabic: item.name_arabic,
            price: item.price,
            status: item.isActive? 'active': 'inactive'
          }
          if (item.id !== 0) {
            obj.id = item.id;
          }
          return obj; 
        }),
      };
      if (removedAddonItems.length > 0) {
        data.remove_items = removedAddonItems;
      }
      
      let method = 'post';
      let url = API.ADDONS.ADD;
      if (editAddonData) {
        method = 'put';
        url = API.ADDONS.UPDATE(editAddonData.id);
      }
      globalRequest(method, url, data, {}, true, true).then(({data, status}) => {
        if (data.ack === 1) {
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: data.msg,
              state: "success",
            })
          );
          dispatch(changePage(PAGES.MANAGE_MODIFIER));
        } else {
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: data.msg,
              state: "error",
            })
          );
        }
        dispatch(changeLoader(false));
      }).catch((err) => {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t('manageModifier_FailedToAddAddon'),
            state: "error",
          })
        );
        dispatch(changeLoader(false));
      });
    } else {
      dispatch(changeLoader(false));
    }
  };

  const addAddOnItem = () => {
    let newAddonItems = [...addonItems];
    newAddonItems.push({
      id: 0,
      name: "",
      nameError: "",
      name_arabic: "",
      name_arabicError: "",
      price: "",
      priceError: "",
      isActive: true
    });
    setAddonItems(newAddonItems);
  }

  const removeAddon = (index) => {
    if(addonItems.length === 1) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t('manageModifier_AtLeastOnItemIsRequired'),
          state: "error",
        })
      );
      return;
    }
    if(addonItems[index].id !== 0) {
      let newRemovedAddonItems = [...removedAddonItems];
      newRemovedAddonItems.push(addonItems[index].id);
      setRemovedAddonItems(newRemovedAddonItems);
    }
    let newAddonItems = [...addonItems];
    newAddonItems.splice(index, 1);
    setAddonItems(newAddonItems);
  }

  const checkAllActive = () => {
    let haveActive = false;
    addonItems.forEach((item) => {
      if (item.isActive) {
        haveActive = true;
      }
    });
    if (!haveActive) {
      setIsActive(false)
    }
    haveActiveItems = haveActive;
    setHaveActiveItems(haveActiveItems)
  }

  const getAddonData = (addonId) => {
    dispatch(changeLoader(true));
    globalRequest('get', API.ADDONS.GET_SPECIFIC(addonId), {}, {}, true, true).then(({data, status}) => {
      if (data.ack === 1) {
        dispatch(changeLoader(false));
        const addonData = data?.addon;
        setGroupName(getdataByKey(addonData?.addons_locales, 'name', 'en'))
        setGroupNameError('');
        setGroupNameArabic(getdataByKey(addonData?.addons_locales, 'name', 'ar'))
        setGroupNameArabicError('');
        
        isActive = addonData?.status === 'active'?true: false;
        setIsActive(isActive)
        selectedCity = cities.find((city) => city.code === addonData?.type);
        setSelectedCity(selectedCity);
        minimum = addonData?.minimum_item;
        setMinimum(minimum)
        maximum = addonData?.maximum_item;
        setMaximum(maximum)
        addonItems = addonData?.addon_items?.map((item) => {
          return {
            id: item.id,
            name: getdataByKey(item?.addon_items_locales, 'name', 'en'),
            nameError: "",
            name_arabic: getdataByKey(item?.addon_items_locales, 'name', 'ar'),
            name_arabicError: "",
            price: item.price.toString(),
            priceError: "",
            isActive: item.status === 'active'? true: false
          }
        });
        setAddonItems(addonItems)
        setRemovedAddonItems([]);
        
        checkAllActive();
      } else {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: data.msg,
            state: "error",
          })
        );
        dispatch(changeMainProductData(null));
        dispatch(changePage(PAGES.ADD_ADDON));
      }
    }).catch((err) => {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: 'Something went wrong',
          state: "error",
        })
      );
      dispatch(changeMainProductData(null));
      dispatch(changePage(PAGES.ADD_ADDON));
    });
  };

  useEffect(() => {
    if (editAddonData) {
      getAddonData(editAddonData.id)
    }
  }, [editAddonData]);

  // useEffect(() => {
  //   if (selectedCity.code === 'single') {
  //     // setMaximum(0);
  //     if (minimum > 1) {
  //       minimum = 1;
  //       setMinimum(minimum);
  //     } else {
  //       minimum = 0;
  //       setMinimum(minimum);
  //     }
  //   }
  // }, [selectedCity]);

  return (
    <div className="main-mid-wrapper">
      <div className="add-product-wrapper">
        <div className="add-product-head">
          <h3 className="h3">{t("manageModifier_AddAddOns")}</h3>
        </div>
        <div className="card-white">
          <h6 className="h6 d-flex align-items-center">
            {t("manageModifier_AddonGroup")}
            <Tooltip title={t('manageModifier_AddOnGroupTooltip')} placement="top" arrow>
              <img src={infoIcon} alt="info" className="info-icon ml-2" />
            </Tooltip>
          </h6>
          <Grid
            container
            columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
            xs={12}
            sm={12}
            md={12}
            lg={10}
          >
            <Grid item xs={12}>
              <div className="form-group">
                <TextField
                  required
                  label={t("manageModifier_AddonGroupName_Engilsh")}
                  onChange={(e) => { setGroupName(e.target.value); setGroupNameError(''); }}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end" className="mobile-bottom">{groupName.length}/256</InputAdornment>
                  }}
                  inputProps={{ maxLength: 256 }}
                  disabled={tokenData.role === 'vendor_users'}
                  value={groupName}
                />
                <span className="redspan">{groupNameError}</span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <TextField
                  required
                  label={t("manageModifier_AddonGroupName_Arabic")}
                  onChange={(e) => { setGroupNameArabic(e.target.value); setGroupNameArabicError(""); }}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end" className="mobile-bottom">{groupNameArabic.length}/256</InputAdornment>
                  }}
                  inputProps={{ maxLength: 256 }}
                  disabled={tokenData.role === 'vendor_users'}
                  value={groupNameArabic}
                />
                <span className="redspan">{groupNameArabicError}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={4}>
              <span className="form-group p-float-label">
                <Dropdown
                  value={selectedCity}
                  onChange={(e) => {
                    selectedCity = e.value;
                    setSelectedCity(selectedCity);
                     if (selectedCity.code === 'single') {
                      if (minimum > 1) {
                        minimum = 1;
                        setMinimum(minimum);
                      } else {
                        minimum = 0;
                        setMinimum(minimum);
                      }
                      maximum = 0;
                      setMaximum(maximum);
                    }

                  }}
                  options={cities}
                  optionLabel="name"
                  placeholder="Add on Type"
                  className="w-full"
                  disabled={tokenData.role === 'vendor_users'}
                />
                <label htmlFor="dropdown">
                  {t("Add on Type")} <span className="required-star">*</span>
                </label>
              </span>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}
              style={tokenData.role === 'vendor_users'?{pointerEvents: 'none', opacity: '0.4'}: null}
            >
              <div className="min-max-row">
                <h6 className="h6">
                  Minimum{" "}
                  <Tooltip title={t('manageModifier_MinimumTooltip')} placement="top" arrow>
                    <img src={infoIcon} alt="info" className="info-icon" />
                  </Tooltip>
                </h6>
                <div className="inc-dec-box">
                  <button className="dec-btn" onClick={() => decrement('minimum')} style={{
                    cursor: 'pointer'
                  }}>-</button>
                  <input
                    className="input-box"
                    type="number"
                    value={minimum}
                    inputProps={{ min: 0 }}
                    onChange={(e) => {
                      if (selectedCity.code === 'single') {
                        if (parseInt(e.target.value) > 1) {
                          minimum = 1;
                        } else {
                          minimum = e.target.value;
                        }
                      } else {
                        minimum = e.target.value;
                      }
                      setMinimum(minimum);
                    }}
                  />
                  <button className="inc-btn" onClick={() => increment('minimum')} style={{
                    cursor: 'pointer'
                  }}>+</button>
                </div>
              </div>
            </Grid>
            {selectedCity.code !== 'single'?
            <Grid item xs={12} sm={6} md={6} lg={4}
              style={tokenData.role === 'vendor_users'?{pointerEvents: 'none', opacity: '0.4'}: null}
            >
              <div className="min-max-row">
                <h6 className="h6">
                  Maximum{" "}
                  <Tooltip title={t('manageModifier_MaximumTooltip')} placement="top" arrow>
                    <img src={infoIcon} alt="info" className="info-icon" />
                  </Tooltip>
                </h6>
                <div className="inc-dec-box">
                  <button className="dec-btn" onClick={() => decrement('maximum')} style={{
                    cursor: 'pointer'
                  }}>-</button>
                  <input
                    className="input-box"
                    type="number"
                    value={maximum}
                    min={minimum}
                    onChange={(e) => {
                      maximum = e.target.value;
                      setMaximum(maximum);
                    }}
                  />
                  <button className="inc-btn" onClick={() => increment('maximum')} style={{
                    cursor: 'pointer'
                  }}>+</button>
                </div>
              </div>
            </Grid>: null}
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="switch-label">
                <Switch
                  className=""
                  checked={isActive}
                  onChange={(e) => {
                    setIsActive(e.target.checked)
                    checkAllActive();
                  }}
                />
                <label className="mx-2">{t('manageModifier_makeAddonActive')}</label>
              </div>
              {!haveActiveItems?<Alert severity="error" sx={{ width: "100%" }} >{t('manageModifier_AddonItemShouldBeActive')}</Alert>:null}
            </Grid> */}
          </Grid>
          <Grid
            container
            columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
            xs={12} sm={12} md={12} lg={10} className="addon-delete-row"
            style={{ marginTop: "20px" }}
          >
            <Grid item xs={12}>
              <div className="heading-row">
              <h6 className="h6 d-flex align-items-center mb-0">
                {t("manageModifier_AddOnItem")}                
                <Tooltip title={t('manageModifier_AddOnItemTooltip')} placement="top" arrow>
                  <img src={infoIcon} alt="info" className="info-icon ml-2" />
                </Tooltip>
              </h6>
              {tokenData.role === 'vendor'?<Button
                  variant="contained"
                  size="small"
                  sx={{ width: "120px" }}
                  onClick={addAddOnItem}
                >
                  {t("manageModifier_AddItem")}
                </Button>: null}
              </div>
            </Grid>
          </Grid>
          {addonItems?
          addonItems?.map((addon, index) =>
          <Grid
            key={`addon-${index}`}
            container
            columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
            xs={12} sm={12} md={12} lg={10} className="addon-delete-row"
            style={{ marginBottom: "20px" }}
          >
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                id="outlined-basic"
                label={t('manageModifier_AddOnItemName_English')}
                required={true}
                variant="outlined"
                value={addon.name}
                disabled={tokenData.role === 'vendor_users'}
                onChange={(e) => {
                  let newAddonItems = [...addonItems];
                  newAddonItems[index].name = e.target.value;
                  newAddonItems[index].nameError = '';
                  setAddonItems(newAddonItems);
                }}
              />
              <span className="redspan">{addon.nameError}</span>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                id="outlined-basic"
                label={t('manageModifier_AddOnItemName_Arabic')}
                required={true}
                variant="outlined"
                value={addon.name_arabic}
                disabled={tokenData.role === 'vendor_users'}
                onChange={(e) => {
                  let newAddonItems = [...addonItems];
                  newAddonItems[index].name_arabic = e.target.value;
                  newAddonItems[index].name_arabicError = '';
                  setAddonItems(newAddonItems);
                }}
              />
              <span className="redspan">{addon.name_arabicError}</span>
            </Grid>
            <Grid item xs={9} sm={6} md={3} lg={3}>
              <TextField
                id="outlined-basic"
                label={t('manageModifier_AddOnItemPrice')}
                variant="outlined"
                value={addon.price}
                InputProps={{inputComponent: amountMask}}
                disabled={tokenData.role === 'vendor_users'}
                onChange={(e) => {
                  let newAddonItems = [...addonItems];
                  newAddonItems[index].price = e.target.value;
                  newAddonItems[index].priceError = '';
                  setAddonItems(newAddonItems);
                }}
              />
              <span className="redspan">{addon.priceError}</span>
            </Grid>
            <Grid item xs={3} sm={6} md={1} lg={1}>
              <div className="switch-label mx-2">
                <Switch
                  className=""
                  checked={addon.isActive}
                  disabled={tokenData.role === 'vendor_users'}
                  onChange={(e) => {
                    let newAddonItems = [...addonItems];
                    newAddonItems[index].isActive = e.target.checked;
                    setAddonItems(newAddonItems);
                    checkAllActive();
                  }}
                />
              </div>
            </Grid>
            {tokenData.role === 'vendor'?
              <img src={deleteIcon} alt="delete" className="delete-icon" onClick={() => removeAddon(index)} />:
              null
            }
            </Grid>
          ): null}
        </div>
        {tokenData.role === 'vendor'?<div className="btn-row" style={{ marginBottom: "20px" }}>
          <Button
            variant="contained"
            size="large"
            className="min-width148 ml-auto"
            type="button"
            onClick={handleSubmit}
          >{t("manageProducts_Save")}</Button>
        </div>: null}
      </div>
    </div>
  );
};

export default AddAddOn;
