import "./viewProduct.scss";
import PropTypes from "prop-types";
import { useEffect, useState, useLayoutEffect } from "react";
import {
  Button, Pagination, Tabs, Tab, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Menu, MenuItem, Switch
} from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Boxed } from "../../../Styles-Elements/Box";
import { API, PAGES } from "../../../Enum";
import { currentLocalData } from "../../../redux/reducers/localData";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { changePage } from "../../../redux/reducers/page";
import { changeMainProductData } from "../../../redux/reducers/mainEditProductData";
import { formatPrice, getdataByKey, getDataByKeyLocale } from "../../../Helpers/Helpers";
import SearchInput from "../../../shared/SearchInput";
import noProduct from "../../../assets/images/structure/no-product.png";
import threeDotsBlack from "../../../assets/images/structure/three-dots-black.svg";
import editBlack from "../../../assets/images/structure/edit-black.svg";
import deleteBlack from "../../../assets/images/structure/delete-black.svg";
import ConfirmBox from "../../../dialog/ConfirmBox";
import ProductInactiveModal from "../../../dialog/ProductInactiveModal";
import globalRequest from "../../../global-modules/globalRequest";
import standardTime from "../../../global-modules/standardTime";
import ActiveInactiveStore from "../../../dialog/ActiveInactiveStore";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >{value === index && <>{children}</>}</div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewProduct = () => {
  
  const limit = 25;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tokenData = useSelector(currentLocalData);
  const categoryLevel = tokenData?.token?.store?.category_level;
  const [search, setSearch] = useState("");
  const [type, setType] = useState("approved");
  const [deleteProductId, setDeleteProductId] = useState(0);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [showProductInactiveModal, setShowProductInactiveModal] = useState(false);
  const [allIsInactive, setAllIsInactive] = useState(false);

  let [tabValue, setTabValue] = useState(0);
  let [page, setPage] = useState(1);
  let [sortBy, setSortBy] = useState("id");
  let [orderBy, setOrderByd] = useState("desc");
  let [productData, setProductData] = useState([]);
  let [totalProductCount, setTotalProductCount] = useState(0);
  let [storeOptions, setStoreOptions] = useState([]);
  let [productToEdit, setProductToEdit] = useState(null);
  let [selectedProduct, setSelectedProduct] = useState(null);

  // model for Active stores
  let [activeStores, setActiveStores] = useState([]);
  let [showActiveProductModal, setShowActiveProductModal] = useState(false);

  // model for Inactive stores
  let [inactiveStores, setInactiveStores] = useState([]);
  let [showInactiveProductModal, setShowInactiveProductModal] = useState(false);

  const Sorting = ({ sortAsc, sortDesc }) => {
    return (<>
      <ArrowDropUp onClick={sortAsc} />
      <ArrowDropDown onClick={sortDesc} />
    </>);
  };

  const BasicMenu = ({ obj }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {setAnchorEl(event.currentTarget);};
    
    const handleClose = () => {setAnchorEl(null);};

    if (obj?.is_approved !== 0) {
      return (
        <div>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          ><img src={threeDotsBlack} alt="" /></Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{"aria-labelledby": "basic-button",}}
          >
            {tabValue == 1 ?
            <MenuItem
              onClick={() => {
                handleClose();
                dispatch(changeMainProductData(obj));
                sessionStorage.setItem("returnto", 'product');
                dispatch(changePage(PAGES.ADD_PRODUCT));
              }}
            >
              <img src={editBlack} alt="" />
              <span className="p2-drop-menu">{t("manageProducts_edit")}</span>
            </MenuItem>: <>
              <MenuItem
                onClick={() => {
                  dispatch(changeMainProductData(obj));
                  sessionStorage.setItem("returnto", 'product');
                  dispatch(changePage(PAGES.ADD_PRODUCT));
                  handleClose();
                }}
              >
                <img src={editBlack} alt="" />
                <span className="p2-drop-menu">{t("manageProducts_edit")}</span>
              </MenuItem>
              {tokenData.token.role === 'vendor'?<MenuItem
                onClick={() => {
                  handleClose();
                  setDeleteProductId(obj.id);
                  setOpenDeleteConfirm(true);
                }}
              >
                <img src={deleteBlack} alt="" />
                <span className="p2-drop-menu">{t("manageProducts_delete")}</span>
              </MenuItem>: null}
            </>}
          </Menu>
        </div>
      );
    }
  };

  const handleChange = (event, newValue) => {
    page = 1; setPage(page)
    setTabValue(newValue);
  };

  const goToEditProduct = (obj) => {
    sessionStorage.setItem('canedit', false)
    dispatch(changeMainProductData(obj));
    dispatch(changePage(PAGES.EDIT_PRODUCT));
  }


  const downloadProductCsv = () => {
    dispatch(changeLoader(true));
    // let url = API.GET_PRODUCT;
    let url = API.MANAGE_PRODUCTS.GET_ALL_PRODUCTS;
    let params = { limit: limit, page: page, sort_by: sortBy, order_by: orderBy, download: "yes" };
    if (search !== "") { params.search = search; }
    if (type === "edited") {
      url = API.GET_EDIT_REQUEST_DATA;
    } else {
      params.type = type;
    }
    globalRequest( "get", url, {}, { params: params, }, true, true ).then((res) => {
      let data = res.data;
      const separator = ",";
      if (data?.ack === 1) {
        const product = data?.products;
        const columns = [
          "Item Code", "Product", "Base UOM", "Selling Price", "Status"
        ];
        const csvRows = product.map((obj, index) => {
          let uosData = obj?.uoms
          let defaultUom = uosData[0]
          return [
            obj?.itemCode,
            getdataByKey(obj?.products_locales, 'title', "en"),
            defaultUom?.uomName,
            `QAR ${defaultUom?.salePrice.toFixed(2)}`,
            obj.status
          ].map((cell) => cell === null || cell === undefined ? "" : cell.toString()).join(separator);;
        })
        
        const csvHeader = columns.join(separator);
        const csvContent = [csvHeader, ...csvRows].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "Products.csv";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
      dispatch(changeLoader(false));
    }).catch(() => {
      dispatch(changeLoader(false));
    });
  };

  const getProduct = () => {
    dispatch(changeLoader(true));
    // let url = API.GET_PRODUCT;
    let url = API.MANAGE_PRODUCTS.GET_ALL_PRODUCTS;
    let params = { limit: limit, page: page, sort_by: sortBy, order_by: orderBy };
    if (search !== "") { params.search = search; }
    if (type === "edited") {
      url = API.GET_EDIT_REQUEST_DATA;
    } else {
      params.type = type;
    }
    globalRequest( "get", url, {}, { params: params, }, true, true ).then((res) => {
      let data = res.data;
      if (data?.ack === 1) {
        const product = data?.products;
        productData = product.map((obj, index) => {
          if (type != "edited") {
            const allStores = storeOptions?.filter((store) => store.value !== 0).length;
            const totalActiveStore = allStores - obj?.manage_store_products.length;
            obj.activeStore = totalActiveStore
            obj.inactiveStore = obj?.manage_store_products.length;
            let category = '';
            let sub_category = '';
            let sub_sub_category = '';
            if (categoryLevel == 1) {
              category = obj?.parent_category
                // obj?.product_category_relations?.map((category) =>
                //   category.category.category_locales[0].name).join(', ') || '-';
            }
            if (categoryLevel == 2) {
              category = obj?.parent_category;
              sub_category = obj?.sub_category
              // sub_category = obj?.product_category_relations?.map((category) =>
              //   category.category.category_locales[0].name).join(', ') || '-';
              // category = obj?.product_category_relations?.map((category) =>
              //   category.category.category.category_locales[0].name).join(', ') || '-';
            }
            if (categoryLevel == 3) {
              category = obj?.parent_category;
              sub_category = obj?.sub_category
              sub_sub_category = obj?.sub_sub_category
              // sub_sub_category = obj?.product_category_relations?.map((category) =>
              //   category.category.category_locales[0].name).join(', ') || '-';
              // sub_category = obj?.product_category_relations?.map((category) =>
              //   category.category.category.category_locales[0].name
              //   ).join(', ') || '-';
              // category = obj?.product_category_relations?.map((category) =>
              //   category.category.category.category.category_locales[0].name).join(', ') || '-';
            }
            obj.category = category;
            obj.sub_category = sub_category;
            obj.sub_sub_category = sub_sub_category;
          }
          
          return obj;
        });
        setProductData(productData);
        totalProductCount = data?.totalCount;
        setTotalProductCount(totalProductCount);
        setAllIsInactive(data?.allInactive !== 0);
      }
      dispatch(changeLoader(false));
    }).catch(() => {
      dispatch(changeLoader(false));
    });
  };

  const getStoreOptions = async () => {
    dispatch(changeLoader(true));
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + tokenData.token.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    storeOptions =[];
    if (response.data.ack === 1) {
      storeOptions = [{ label: "All Stores", value: 0, secondLabel: "" }]
      storeOptionArray = response.data.stores
        .filter((store) => (store.is_approved === null || store.is_approved === true)).map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address,
          business_logo: store.business_logo,
        }
      });
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
      dispatch(changeLoader(false));
    }
    storeOptions = [...storeOptions, ...storeOptionArray];
    setStoreOptions(storeOptions);
  };

  const deleteProduct = () => {
    if (deleteProductId !== 0) {
      dispatch(changeLoader(true));
      globalRequest("delete", API.DELETE_PRODUCT(deleteProductId), {}, {}, true, true).then((res) => {
        let data = res?.data;
        if (data?.ack === 1) {
          setProductData(productData.filter((a) => a.id !== deleteProductId));
        }
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: data?.msg,
            state: data?.ack === 1 ? "success" : "error",
          })
        );
        dispatch(changeLoader(false));
        setDeleteProductId(0);
        setOpenDeleteConfirm(false);
      }).catch(() => { dispatch(changeLoader(false)); });
    }
  };

  const sortAsc = (column) => {
    sortBy = column
    setSortBy(sortBy);
    orderBy = "asc";
    setOrderByd(orderBy)
    getProduct();
  };

  const sortDesc = (column) => {
    sortBy = column
    setSortBy(sortBy);
    orderBy = "desc";
    setOrderByd(orderBy)
    getProduct();
  };

  const GetTable = () => {
    if (productData?.length) {
      return (
        <div className="table-block">
          <div className="table-wrapper">
            <TableContainer component={Paper} sx={{ maxHeight: "calc(100vh - 265px)" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
                        {t("manageProducts_ItemCode")}
                        <div className="short-box">
                          <Sorting
                            sortAsc={() => sortAsc("itemcode")}
                            sortDesc={() => sortDesc("itemcode")}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        {t("manageProducts_product")}
                        <div className="short-box">
                          <Sorting
                            sortAsc={() => sortAsc("title")}
                            sortDesc={() => sortDesc("title")}
                          />
                        </div>
                      </div>
                    </TableCell>
                    {type == "edited" ? <>
                    <TableCell>
                      <div className="short-div">
                        {t("manageProducts_ModifiedDate_Time")}
                        <div className="short-box">
                          <Sorting
                            sortAsc={() => sortAsc("time")}
                            sortDesc={() => sortDesc("time")}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        {t("manageProducts_Status")}
                        <div className="short-box">
                          <Sorting
                            sortAsc={() => sortAsc("status")}
                            sortDesc={() => sortDesc("status")}
                          />
                        </div>
                      </div>
                    </TableCell></>: <>
                    {categoryLevel == 1 ?
                    <TableCell>
                      <div className="short-div">
                        {t("manageProducts_Category")}
                        {/* <div className="short-box">
                          <Sorting
                            sortAsc={() => sortAsc("category")}
                            sortDesc={() => sortDesc("category")}
                          />
                        </div> */}
                      </div>
                    </TableCell>: null}
                    {categoryLevel == 2 ? <>
                      <TableCell>
                        <div className="short-div">
                          {t("manageProducts_Category")}
                          {/* <div className="short-box">
                            <Sorting
                              sortAsc={() => sortAsc("category")}
                              sortDesc={() => sortDesc("category")}
                            />
                          </div> */}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          {t("manageProducts_SubCategory")}
                          {/* <div className="short-box">
                            <Sorting
                              sortAsc={() => sortAsc("category")}
                              sortDesc={() => sortDesc("category")}
                            />
                          </div> */}
                        </div>
                      </TableCell>
                    </>: null}
                    {categoryLevel == 3 ? <>
                      <TableCell>
                        <div className="short-div">
                          {t("manageProducts_Category")}
                          {/* <div className="short-box">
                            <Sorting
                              sortAsc={() => sortAsc("category")}
                              sortDesc={() => sortDesc("category")}
                            />
                          </div> */}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          {t("manageProducts_SubCategory")}
                          {/* <div className="short-box">
                            <Sorting
                              sortAsc={() => sortAsc("category")}
                              sortDesc={() => sortDesc("category")}
                            />
                          </div> */}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          {t("manageProducts_SubSubCategory")}
                          {/* <div className="short-box">
                            <Sorting
                              sortAsc={() => sortAsc("category")}
                              sortDesc={() => sortDesc("category")}
                            />
                          </div> */}
                        </div>
                      </TableCell>
                    </>: null}
                    <TableCell>
                      <div className="short-div">
                        {t("manageProducts_BaseUOM")}
                        <div className="short-box">
                          <Sorting
                            sortAsc={() => sortAsc("saleprice")}
                            sortDesc={() => sortDesc("saleprice")}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        {t("manageProducts_sellingPrice")}
                        <div className="short-box">
                          <Sorting
                            sortAsc={() => sortAsc("saleprice")}
                            sortDesc={() => sortDesc("saleprice")}
                          />
                        </div>
                      </div>
                    </TableCell>
                    </>}
                    {tabValue === 0?<TableCell>{t('manageProducts_Status')}</TableCell>: null}
                    {tabValue === 1?<TableCell>{t('manageProducts_RequestStatus')}</TableCell>: null}
                    {tabValue === 0 && tokenData.token.role === 'vendor'?<TableCell>{t('manageProducts_activeStores')}</TableCell>: null}
                    {tabValue === 0 && tokenData.token.role === 'vendor'?<TableCell>{t('manageProducts_inactiveStores')}</TableCell>: null}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productData.map((obj, ind) => {
                    let dateValue = "";
                    if (type == "edited") {
                      dateValue = standardTime(obj.updatedAt)
                    }
                    let uosData = obj?.product?.uoms ? obj?.product?.uoms : obj?.uoms;
                    let defaultUom = uosData?.find((opm) => opm.isDefault);
                    if (defaultUom === undefined) {defaultUom = uosData?uosData[0]:null;}
                    return (
                      <TableRow
                        className={`${obj.is_approved === 0 ? "pointer" : ""}`}
                        key={ind}
                        sx={{"&:last-child td, &:last-child th": { border: 0 },}}
                      >
                        <TableCell
                          onClick={() => {
                            if (obj.is_approved === 0) {
                              dispatch(changeMainProductData(obj));
                              sessionStorage.setItem("returnto", 'product');
                              dispatch(changePage(PAGES.ADD_PRODUCT));
                            } else if(type == "edited") {
                              goToEditProduct(obj);
                            }
                          }}
                          className={`${obj.is_approved === 0 ? "border-left-orange" : ""} ${obj.is_approved === 2 ? "border-left-red" : ""} ${type === "edited"?'pointer': ''}`}
                        >#{type !== "edited"? obj?.itemCode: obj?.product?.itemCode}</TableCell>
                        <TableCell
                          className={type === "edited"?'pointer': ''}
                          onClick={() => {
                            if (obj.is_approved === 0) {
                              dispatch(changeMainProductData(obj));
                              sessionStorage.setItem("returnto", 'product');
                              dispatch(changePage(PAGES.ADD_PRODUCT));
                            }
                            if(type == "edited") {
                              goToEditProduct(obj);
                            }
                          }}
                        >
                          {tabValue === 2 ? <span style={{ fontFamily: "SFPRODISPLAYMEDIUM" }}>
                            {getdataByKey(obj?.product?.products_locales, 'title', "en")}
                          </span> : <span style={{ fontFamily: "SFPRODISPLAYMEDIUM" }}>
                            {obj?.product ? getdataByKey(obj?.product?.products_locales, 'title', "en"): getdataByKey(obj?.products_locales, 'title', "en")}
                          </span>}
                        </TableCell>
                        {type !== "edited" ?
                        <>
                        {categoryLevel == 1 ?<TableCell>{ obj?.category }</TableCell>: null}
                        {categoryLevel == 2 ? <>
                          <TableCell> { obj?.category } </TableCell>
                          <TableCell> { obj?.sub_category } </TableCell>
                        </>: null}
                        {categoryLevel == 3 ? <>
                          <TableCell> { obj?.category } </TableCell>
                          <TableCell> { obj?.sub_category } </TableCell>
                          <TableCell> { obj?.sub_sub_category } </TableCell>
                        </>: null}
                          <TableCell
                            onClick={() => {
                              if (obj.is_approved === 0) {
                                dispatch(changeMainProductData(obj));
                                sessionStorage.setItem("returnto", 'product');
                                dispatch(changePage(PAGES.ADD_PRODUCT));
                              }
                            }}
                          >
                            {defaultUom?.uomName}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              if (obj.is_approved === 0) {
                                dispatch(changeMainProductData(obj));
                                sessionStorage.setItem("returnto", 'product');
                                dispatch(changePage(PAGES.ADD_PRODUCT));
                              }
                            }}
                          >
                            {t("manageProducts_QAR")}{" "}
                            {
                              defaultUom?.salePrice &&
                              defaultUom?.salePrice !== 0 &&
                              defaultUom?.salePrice !== '' &&
                              defaultUom?.salePrice !== '0'
                            ? formatPrice(defaultUom?.salePrice) : formatPrice(defaultUom?.price)}
                          </TableCell>
                          {tabValue === 1?<TableCell>{obj.is_approved === 2?t('manageProducts_Reject'): t('manageProducts_Pending')}</TableCell>: null}
                          {tabValue === 0?<TableCell>
                            <Switch
                              checked={obj.status === "active"}
                              onChange={(e) => {
                                productToEdit = { status: e.target.checked ? "active" : "inactive", product: obj }
                                setProductToEdit(productToEdit)
                                setShowProductInactiveModal(true)
                              }}
                            />
                          </TableCell>: null}
                          {tabValue === 0 && tokenData.token.role === 'vendor'?<TableCell
                            onClick={() => {
                              if (obj?.activeStore > 0) {
                                const inactiveStoreIds = obj?.manage_store_products.map((store) => store.storeId);
                                selectedProduct = obj;
                                setSelectedProduct(selectedProduct);
                                activeStores = storeOptions.filter((store) => !inactiveStoreIds.includes(store.value));
                                setActiveStores(activeStores);
                                setShowActiveProductModal(true);
                              }
                            }}
                            style={obj?.activeStore > 0?{ textDecoration: "underline", cursor: "pointer" }: {}}
                          >{obj?.activeStore}</TableCell>: null}
                          {tabValue === 0 && tokenData.token.role === 'vendor'?<TableCell
                            onClick={() => {
                              if (obj?.inactiveStore > 0) {
                                selectedProduct = obj;
                                setSelectedProduct(selectedProduct);
                                const inactiveStoreIds = obj?.manage_store_products.map((store) => store.storeId);
                                inactiveStores = storeOptions.filter((store) => inactiveStoreIds.includes(store.value));
                                setInactiveStores(inactiveStores);
                                setShowInactiveProductModal(true);
                              }
                            }}
                            style={obj?.inactiveStore > 0?{ textDecoration: "underline", cursor: "pointer" }: {}}
                          >{obj?.inactiveStore}</TableCell>: null}
                          <TableCell>
                            {tabValue == 1 ? (<BasicMenu obj={obj} />) :
                            obj.is_approved == 2 || obj.is_approved === 1 ? (<BasicMenu obj={obj} />) : null}
                          </TableCell>
                        </> : <>
                          <TableCell onClick={() => { goToEditProduct(obj); }} className="pointer">{dateValue}</TableCell>
                          <TableCell className="pointer" onClick={() => { goToEditProduct(obj); }} >
                            {" "}
                            <Button
                              color="secondary"
                              variant="contained"
                              disabled
                              size="small"
                            ><small> {obj.status}</small></Button>
                          </TableCell>
                          <TableCell></TableCell>
                        </>}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      );
    } else {
      if (type == "edited" || type == "unapproved") {
        return (<>
          <div className="empty-box">
            <img src={noProduct} alt="..." className="empty-img" />
            {type == "edited"?<>
              <h4 className="h4">{t("manageProducts_noEditProduct")}</h4>
              <p className="p2">{t("manageProducts_noPendingProduct")}</p>
            </>:<>
              <h4 className="h4">{t("manageProducts_noCreateProduct")}</h4>
              <p className="p2">{t("manageProducts_noPendingProduct")}</p>
            </>}
          </div>
        </>);
      } else {
        return (<>
          <div className="empty-box">
            <img src={noProduct} alt="..." className="empty-img" />
            <h4 className="h4">{t("manageProducts_NoProduct")}</h4>
            <p className="p2">{t("manageProducts_ThereisnothinginyourproductlistLetsaddproducts")}</p>
            {tokenData.token.role === 'vendor'?<Button
              onClick={() => dispatch(changePage(PAGES.ADD_PRODUCT))}
              variant="contained"
              size="large"
            >{t("manageProducts_AddProduct")}</Button>: null}
          </div>
        </>);
      }
    }
  };

  useEffect(() => {
    dispatch(changeLoader(true));
    const getData = setTimeout(() => {
      getProduct();
    }, 500);
    return () => clearTimeout(getData);
  }, [search, type]);

  useEffect(() => {
    if (sessionStorage.getItem("productCreateList")) {
      tabValue = 1; setTabValue(tabValue);
      sessionStorage.removeItem("productCreateList");
      handleChange({}, 1);
    } else if (sessionStorage.getItem("productEditList")) {
      tabValue = 2; setTabValue(tabValue);
      sessionStorage.removeItem("productEditList");
      handleChange({}, 2);
    }
  }, []);

  useEffect(() => {
    productData = [];
    setProductData(productData);
    if (tabValue === 0) {
      setType("approved");
    } else if (tabValue === 1) {
      setType("unapproved");
    } else if (tabValue === 2) {
      setType("edited");
    }
  }, [tabValue]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    getStoreOptions();
  }, []);

  return (
    <div className="main-mid-wrapper pb-0">
      <div className="order-listing-wrapper">
        <Boxed className="top-flex-row" mb={"10px"}>
          <div className="store-detail-head" style={{ marginBottom: "0px" }}>
            <h3 className="h3">{t("manageProducts_manageProducts")}</h3>
          </div>
          <Boxed display={"flex"} alignItems={"center"} className="filter-search">
            <SearchInput onChange={(e) => setSearch(e.target.value)} />
            {tokenData.token.role === 'vendor'?<Button
              onClick={() => {
                sessionStorage.setItem("returnto", 'product');
                dispatch(changeMainProductData(null));
                dispatch(changePage(PAGES.ADD_PRODUCT));
              }}
              variant="contained" size="small" sx={{ width: "150px" }}
            >{t("manageProducts_AddProduct")}</Button>: null}

            {tokenData.token.store.businessTypeId == 97?
              <Button
              onClick={downloadProductCsv}
              variant="contained" size="small" sx={{ width: "150px" }}
              style={{ marginLeft: "10px" }}
            >Download CSV</Button>: null}

          </Boxed>
        </Boxed>
        {/* <Boxed
          display={"flex"} alignItems={"center"} justifyContent={"start"}
          mb={"20px"} className="table-switch-box"
        >
          <Switch onClick={handleAllIsInactive}  checked={allIsInactive} />
          <div className="relative mx-3">
            <h6 className="h6">{t('manageProducts_MakeStoresOnline')}</h6>
            <span className="p3">{t('manageProducts_MakeStoresOnlineText')}</span>
          </div>
        </Boxed> */}
        <div className="">
          <div className="tabs-wrap">
            <Tabs
              value={tabValue} onChange={handleChange} className="border-bottom" variant="scrollable"
              scrollButtons="auto" aria-label="scrollable auto tabs example"
            >
              <Tab label={t("manageProducts_Approved")} onClick={() => { setSortBy("id"); setOrderByd("desc"); }} {...a11yProps(0)} />
              <Tab label={t("manageProducts_ProductCreateRequest")} onClick={() => { setSortBy("id"); setOrderByd("desc"); }} {...a11yProps(1)} />
              <Tab label={t("manageProducts_EditRequest")} onClick={() => { setSortBy("id"); setOrderByd("desc"); }} {...a11yProps(2)} />
            </Tabs>
            <GetTable />
            <div className="pagination-wrap">
            {Math.ceil(totalProductCount / limit) > 1 ? <Pagination
              count={Math.ceil(totalProductCount / limit)}
              page={page} onChange={(e, val) => { page = val; setPage(page); getProduct()}} />:null}
            </div>
          </div>
        </div>
      </div>

      <ConfirmBox
        isOpen={openDeleteConfirm}
        handleClose={() => { setOpenDeleteConfirm(false); setDeleteProductId(0); }}
        confirm={deleteProduct}
        message={t("manageProducts_SureYouWantToDeleteThisProduct")}
      />
        
      {showProductInactiveModal?
        <ProductInactiveModal
        isOpen={showProductInactiveModal}
        handleClose={() => setShowProductInactiveModal(false)}
        productToEdit={productToEdit}
        handleSuccess={() => {
          setShowProductInactiveModal(false);
          getProduct();
        }}
        storeOptions={storeOptions}
      />: null}

      {showActiveProductModal?
        <ActiveInactiveStore
          type="active"
          handleClose={() => setShowActiveProductModal(false)}
          stores={activeStores.filter((store) => store.value !== 0)}
          selectedProduct={selectedProduct}
          getProducts={() => {
            setShowActiveProductModal(false);
            getProduct();
          }}
      />: null}
      {showInactiveProductModal?
        <ActiveInactiveStore
          type="inactive"
          handleClose={() => setShowInactiveProductModal(false)}
          stores={inactiveStores.filter((store) => store.value !== 0)}
          selectedProduct={selectedProduct}
          getProducts={() => {
            setShowInactiveProductModal(false);
            getProduct();
          }}
      />: null}
    </div>
  );
};

export default ViewProduct;
