import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Import css
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "./App.scss";
import "./assets/scss/direction.scss";
import Home from "./pages/Home/Home";
import BecomeAPartnerForm from "./pages/auth/signUp/BecomeAPartnerForm";
import SetPassword from "./pages/auth/setPassword/SetPassword";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import TermsConditionsComponent from "./pages/TermsConditions/TermsConditionsComponent";
import PageNotFound from "./pages/Error/PageNotFound";
import StaticPage from "./pages/StaticPage/StaticPage";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentLoader } from "./redux/reducers/loader";
import { Alert, Snackbar } from "@mui/material";
import { changeSnackbar, currentSnackbar } from "./redux/reducers/snackbar";
import ScrollToTop from "./Styles-Elements/ScrollToTop/ScrollToTop";
import OrderDetails from "./pages/OrderDetails/OrderDetails";
import Loaders from "./Loaders";
import EarningsPayouts from "./pages/EarningsPayouts/EarningsPayouts";
import MainPage from "./pages/MainPage";
import Faq from "./pages/Faq/Faq";
import { useTranslation } from "react-i18next";
import { currentLocalData, changeLocalData } from "./redux/reducers/localData";
import newOrderSound from "./assets/images/sound/new_order_sound.mp3";
import { changeNewOrder } from "./redux/reducers/myNewOrder";
import socket from "./socket";
import { STORAGE, API } from "./Enum";
import addDeleteGetLocalStorage from "./global-modules/addDeleteGetLocalStorage";
import { decodeToken } from "react-jwt";
import globalRequest from "./global-modules/globalRequest";
import { changeBaladiInfo } from "./redux/reducers/baladiInfo";

const App = () => {
  const loader = useSelector(currentLoader);
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const { i18n } = useTranslation();
  const localData = useSelector(currentLocalData);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang)
  }

  const handleNewOrder = (data) => {
    let token = decodeToken(addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single"));
    if (token && Object.keys(data).length > 0) {
      if (
        (token.role === "vendor" && token?.store && token?.brand_id === data?.brand_id) ||
        (token.role === "vendor_users" && token?.store &&  token?.store.id === data?.storeId)
      ) {
        try {
          const audio = new Audio(newOrderSound);
          audio.play();
          dispatch(changeNewOrder(true));
        } catch (error) {
          dispatch(changeLocalData());
        }
      }
    }
  }

  const getBaldiInfo = async () => {
    const response = await globalRequest('get', API.GET_BALADI_INFO, {}, {}, false, true)
    if (response?.data?.ack === 1) {
      dispatch(changeBaladiInfo({
        BALADI_BRAND_Id: response?.data?.BALADI_BRAND_Id,
        BALADI_STORE_Id: response?.data?.BALADI_STORE_Id,
        BALADI_STORE_SLUG: response?.data?.BALADI_STORE_SLUG
      }));
    }
  };
  
  useEffect(() => {
    try {
      socket.on('newOrderPlaced', handleNewOrder);
    } catch (error) {
      console.error('error in socket at app.js', error);
    }
  }, [localData?.token]);

  useEffect(() => {
    if (snackbar?.snackbarOpen) {
      setTimeout(() => {
        dispatch(changeSnackbar({ ...snackbar, isOpen: false, }));
      }, 2000);
    }
  }, [snackbar?.isOpen]);

  useEffect(() => {
    getBaldiInfo();
    if (localStorage.getItem('i18nextLng')) {
      changeLanguage(localStorage.getItem('i18nextLng'))
    } else {
      changeLanguage('en')
    }
  }, [])

  useEffect(() => {
    const handleTabClose = event => {
      socket.disconnect();
    };
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sign-up" element={<BecomeAPartnerForm />} />
          <Route path="/reset-password/:id/:hash/:other" element={<SetPassword />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/terms-conditions-app" element={<TermsConditionsComponent />} />
          <Route path="/order-details" element={<OrderDetails />} />
          <Route path="/earnings-payouts" element={<EarningsPayouts />} />
          <Route path="/dashboard" element={<MainPage socket={socket} />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/:pageType/get" element={<StaticPage />} />
        </Routes>
      </Router>
      <Snackbar

        open={snackbar?.isOpen}
        onClose={() => {
          dispatch(
            changeSnackbar({
              ...snackbar,
              isOpen: false,
            })
          );
        }}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => {
            dispatch(
              changeSnackbar({
                ...snackbar,
                isOpen: false,
              })
            );
          }}
          severity={snackbar?.state}
          sx={{ width: "100%" }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
      {loader ? <Loaders /> : null}
    </>
  );
};

export default App;
