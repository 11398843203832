import { useState, useEffect, useRef } from "react";
import { Typography, Box, Switch } from "@mui/material";
import uploadBlackIcon from "../../assets/images/structure/upload-black-icon.svg";
import ChildCategoryComponent from "./ChildCategoryComponent";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { TreeItem, TreeView } from "@material-ui/lab";
import { Boxed } from "../../Styles-Elements/Box";
import globalRequest from "../../global-modules/globalRequest";
import { API, IMAGE_URL } from "../../Enum";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "../../redux/reducers/snackbar";

const SubCategory = ({
  subCategory,
  handleSelectedId,
  openUploadImage,
  storeId,
  handleCategoryget,
  handleProductget
}) => {

  const dispatch = useDispatch();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [subCategoryList, setSubCategoryList] = useState([]);

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = async (e, index) => {
    const copyListItems = [...subCategoryList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    const newSequence = copyListItems.map((item, index) => {
      return {
        catStoreId: item.catStoreId,
        sequence: index + 1,
      };
    });

    setSubCategoryList(copyListItems);
    await globalRequest('post', API.CATEGORY_MANAGEMENT.CHANGE_SEQUENCE, newSequence, {}, true, true);
  };

  const handleCategoryStatusChange = async (event, category) => {
    const status = event.target.checked ? 'active' : 'inactive';
    const data = {
      storeId: storeId,
      categoryId: category.id,
      status: status
    };
    const response = await globalRequest('post', API.CATEGORY_MANAGEMENT.UPDATE_CATEGORY_HOURS, data, {}, true, true);
    if (response.data.ack === 1) {
      dispatch(changeSnackbar({
        message: response.data.msg,
        state: 'success',
        isOpen: true,
      }));
      handleProductget(category.id);
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        state: 'error',
        isOpen: true,
      }));
    }
    handleCategoryget();
  }

  useEffect(() => {
    setSubCategoryList(subCategory);
  }, [subCategory]);


  return (
    <>
      {
        
        subCategoryList.map((subCategoryItem, index) =>
          subCategoryItem?.chiledCategory?.length > 0 ? (
          <TreeItem
          nodeId={`subCategory${subCategoryItem.id}`}
          key={`subCategory${subCategoryItem.id}`}
          onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={(e) => drop(e, index)}
            draggable
          label={
            <Boxed
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              onClick={() => {
                handleSelectedId(subCategoryItem.id, subCategoryItem.name, subCategoryItem.timejson);
              }}
            >
              <DragIndicatorIcon sx={{ color: "#a7a7a7" }} />
              <Typography
                variant="body2"
                component="body2"
                width={"100%"}
                fontWeight={500}
              >
                 {subCategoryItem.name}
              </Typography>
              <Box display={"flex"} alignItems={"center"}>
                <img
                  src={subCategoryItem.image ? IMAGE_URL(subCategoryItem.image): uploadBlackIcon}
                  onError={(e) => { e.target.onerror = null; e.target.src = uploadBlackIcon; }}
                  width={"20px"} alt=""
                  style={{ marginRight: "10px" }}
                onClick={() => {
                      openUploadImage(
                        subCategoryItem.id,
                        subCategoryItem.name,
                        subCategoryItem.image
                      );
                    }}/>
                <Switch
                  className="mx-1"
                  checked={subCategoryItem.status === 'active' ? true : false}
                  onChange={(e) => {
                    handleCategoryStatusChange(e, subCategoryItem)
                  }}
                />
              </Box>
            </Boxed>
          }
        >
        <ChildCategoryComponent
          childCategory={subCategoryItem?.chiledCategory}
          handleSelectedId={handleSelectedId}
          openUploadImage={openUploadImage}
          storeId={storeId}
          handleCategoryget={handleCategoryget}
          handleProductget={handleProductget}
        />
      </TreeItem>):(
        <Box
            p={"8px 44px 8px 36px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleSelectedId(subCategoryItem.id, subCategoryItem.name, subCategoryItem.timejson);
            }}
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={(e) => drop(e, index)}
            draggable
          >
            <DragIndicatorIcon sx={{ marginRight: "3px", color: "#a7a7a7" }} />
            <Typography
              variant="body2"
              component="body2"
              width={"100%"}
              fontSize={"16px"}
              mb={0}
              fontWeight={500}
            >
              {subCategoryItem.name}
            </Typography>
            <Box display={"flex"} alignItems={"center"}>
              
              {/* <img
                src={deleteBlackImg}
                alt=""
                onClick={() => {
                  handleSetDeleteId(subCategoryItem.id);
                  setOpenDeletePopup(true);
                }}
              /> */}
              <img
                src={subCategoryItem.image ? IMAGE_URL(subCategoryItem.image): uploadBlackIcon}
                onError={(e) => { e.target.onerror = null; e.target.src = uploadBlackIcon; }}
                style={{ marginRight: "10px" }}
                width={"20px"}
                onClick={() => {
                  openUploadImage(
                    subCategoryItem.id,
                    subCategoryItem.name,
                    subCategoryItem.image
                  );
                }}
                alt=""
              />
              <Switch
                checked={subCategoryItem.status === "active" ? true : false}
                onChange={(e) => {
                  handleCategoryStatusChange(e, subCategoryItem)
                }}
              />
            </Box>
          </Box>)
      )}
    </>
  );
};

export default SubCategory;
