import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Menu, MenuItem, Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import { API, PAGES } from "../../Enum";
import { changePage } from "../../redux/reducers/page";
import ConfirmBox from "../../dialog/ConfirmBox";

import threeDotsBlack from "../../assets/images/structure/three-dots-black.svg";
import editBlack from "../../assets/images/structure/edit-black.svg";
import copyBlack from "../../assets/images/structure/copy-black.svg";
import deleteBlack from "../../assets/images/structure/delete-black.svg";

function createData(
  DiscountName,
  DiscountAmount,
  DiscountType,
  Start,
  End,
  Action
) {
  return { DiscountName, DiscountAmount, DiscountType, Start, End, Action };
}

const rows = [
  createData(
    "Black Friday",
    "QAR 200.00",
    "Flat Discount",
    "17/08/2023",
    "19/08/2023",
    ""
  ),
];

export default function OfferTable() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Sorting = ({ sortAsc, sortDesc }) => {
    return (
      <>
        <ArrowDropUpIcon onClick={sortAsc} />
        <ArrowDropDownIcon onClick={sortDesc} />
      </>
    );
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="short-div">
                  {t("myOffers_DiscountName")}
                  <div className="short-box">
                    <Sorting
                      sortAsc={() => sortAsc("title")}
                      sortDesc={() => sortDesc("title")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell align="left">
                <div className="short-div">
                  {t("myOffers_DiscountAmount")}
                  <div className="short-box">
                    <Sorting
                      sortAsc={() => sortAsc("title")}
                      sortDesc={() => sortDesc("title")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell align="left">
                <div className="short-div">
                  {t("myOffers_DiscountType")}
                  <div className="short-box">
                    <Sorting
                      sortAsc={() => sortAsc("title")}
                      sortDesc={() => sortDesc("title")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell align="left">
                <div className="short-div">
                  {t("myOffers_Start")}
                  <div className="short-box">
                    <Sorting
                      sortAsc={() => sortAsc("title")}
                      sortDesc={() => sortDesc("title")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell align="left">
                <div className="short-div">
                  {t("myOffers_End")}
                  <div className="short-box">
                    <Sorting
                      sortAsc={() => sortAsc("title")}
                      sortDesc={() => sortDesc("title")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell align="center">{t("myOffers_Action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.DiscountName}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.DiscountName}
                </TableCell>
                <TableCell align="left">{row.DiscountAmount}</TableCell>
                <TableCell align="left">{row.DiscountType}</TableCell>
                <TableCell align="left">{row.Start}</TableCell>
                <TableCell align="left">{row.End}</TableCell>
                <TableCell align="center">
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <img src={threeDotsBlack} alt="" />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{ "aria-labelledby": "basic-button" }}
                  >
                    <MenuItem
                     onClick={() => {
                        dispatch(changePage(PAGES.ADD_OFFER));
                        handleClose();
                      }}
                    >
                      <img src={editBlack} alt="" />
                      <span className="p2-drop-menu">{t("myOffers_edit")}</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <img src={copyBlack} alt="" />
                      <span className="p2-drop-menu">{t("myOffers_copy")}</span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOpenDeleteConfirm(true);
                      }}
                    >
                      <img src={deleteBlack} alt="" />
                      <span className="p2-drop-menu">
                        {t("myOffers_delete")}
                      </span>
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pagination-wrap">
        <Pagination count={2} page={2} />
      </div>

      <ConfirmBox
        isOpen={openDeleteConfirm}
        handleClose={() => { setOpenDeleteConfirm(false); }}
        message={t("myOffers_areYouSureYouWantToDeleteThisOffer")}
      />
    </>
  );
}
