import { Button, Dialog, DialogContent, Grid, TextField } from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg";
import { API, ROLE, STORAGE } from "../Enum";
import globalRequest from "../global-modules/globalRequest";
import { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import { useEffect } from "react";
import timeOut from "../global-modules/timeOut";
import { Boxed } from "../Styles-Elements/Box";
import { useTranslation } from "react-i18next";

const ForgetOtp = ({ isOpen = false, handleClose, email, openUpdatePassword }) => {

  const { t } = useTranslation();
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState(true);
  const dispatch = useDispatch();
  const [otpTimer, setOtpTimer] = useState(30);
  const [viewEmailResendButton, setViewEmailResendButton] = useState(false);
  const [isResend, setIsResend] = useState(false);

  const handleSubmit = (e) => {
    if (!otp) {
      setOtpErr(false);
    }

    if (otp) {
      let data = {
        uname: email,
        role: ROLE,
        otp: otp,
      };
      dispatch(changeLoader(true));
      globalRequest("post", API.FORGET_PASSWORD_VERIFY, data, {}, false)
        .then((res) => {
          let data = res?.data;
          if (data?.ack == 1) {
            openUpdatePassword(data);
          }
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: data?.ack == 1 ? "success" : "error",
              message: data?.msg,
            })
          );
          dispatch(changeLoader(false));
        })
        .catch((e) => {});
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setOtp("");
      setOtpTimer(30);
      setViewEmailResendButton(false);
      setIsResend(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const t = () => {
      timeOut(
        (bool) => {
          setViewEmailResendButton(bool);
          setOtpTimer(0);
        },
        (i) => {
          setOtpTimer(i);
        }
      );
    };

    if (isOpen) {
      t();
    }

    if (isResend) {
      t();
      setIsResend(false);
    }
  }, [isOpen, isResend]);

  const handleOtp = () => {
    globalRequest(
      "post",
      API.GET_SING_IN_OTP,
      {
        uname: email,
        role: ROLE,
      },
      {},
      false
    )
      .then((res) => {
        let data = res?.data;
        if (data?.ack == 1) {
          setOtpTimer(30);
          setIsResend(true);
          setViewEmailResendButton(false);
          setOtp("");
        }
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: data?.ack == 1 ? "success" : "error",
            message: data?.msg,
          })
        );
      })
      .catch((err) => {
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
      });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} className="main-modal maxwidth-430">
      <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h2 className="h3 text-center">{t('forgot_otpVerification')}</h2>
        <p className="p3 text-center">
          {t('forgot_enterThe4DigitOTPWeHaveSentViaEmailTo')}{" "}
          <span style={{ fontFamily: "SFPRODISPLAYMEDIUM" }}>{email}</span>
        </p>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="form-group">
              <MuiOtpInput value={otp} onChange={(value) => setOtp(value)} />
              {!otpErr && !otp ? <span className="errorspan">{t('forgot_enter_otp')}</span> : null}
            </div>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Button
              onClick={handleSubmit}
              variant="contained"
              size="large"
              sx={{ width: 1, marginTop: "16px" }}
            >
              {t('forgot_verify')}
            </Button>
          </Grid>

          {otpTimer !== 0 ? (
            <Grid item xs={12}>
              <span style={{ color: "#a1a1a1" }}>
                00:{otpTimer < 10 ? `0${otpTimer}` : otpTimer}
              </span>
            </Grid>
          ) : null}

          {viewEmailResendButton ? (
            <Grid item xs={12}>
              <Boxed display={"flex"} alignItems={"center"}>
                <span className="dont-account">{t('forgot_didntReceivedOTP')}</span>
                <span
                  onClick={(e) => {
                    handleOtp();
                  }}
                  className="redspan"
                >
                  {t('forgot_resend')}
                </span>
              </Boxed>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ForgetOtp;
