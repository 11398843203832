import "./EarningsPayouts.scss";
import { useEffect, useState, useLayoutEffect } from "react";
import { Boxed } from "../../Styles-Elements/Box";
import Pagination from "../../global-modules/Pagination/Pagination";
import {
  Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  FormControl, TextField, InputAdornment, MenuItem, Select
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { API, ORDER_STATUS, PAGES } from "../../Enum";
import { useDispatch, useSelector } from "react-redux";
import { changePage } from "../../redux/reducers/page";
import globalRequest from "../../global-modules/globalRequest";
import { changeLoader } from "../../redux/reducers/loader";
import {changePayoutsId} from "../../redux/reducers/earningAndPayoutData";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import { useTranslation } from "react-i18next";
import { currentLocalData } from "../../redux/reducers/localData";
import { formatPrice, getDataByKeyLocale } from ".././../Helpers/Helpers";
import NoDataFound from "../../global-modules/EmptyPages/NoDataFound";
import SearchSelectSingleStore from "../../shared/SearchSelectSingleStore";
import {API_URL} from "../../BaseUrl";

const monthArr = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December",
];

const EarningsPayouts = () => {

  const date = new Date();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tokenData = useSelector(currentLocalData);
  const limit = 10;
  let [value, setValue] = useState(dayjs(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
  let [page, setPage] = useState(1);
  let [type, setType] = useState("all");
  let [sortBy, setSortBy] = useState("sales_amount");
  let [orderBy, setOrderBy] = useState("ASC");
  let [timeZone, setTimeZone] = useState("");
  let [storeOptions, setStoreOptions] = useState([]);
  let [selectedStore, setSelectedStore] = useState(null);
  const [payoutDetails, setpayoutDetails] = useState([]);
  const [payoutCount, setPayoutCount] = useState(10);
  const [paginationCount, setPaginationCount] = useState(5);

  const getAllPayouts = () => {
    const params = {
      params: {
        limit,
        page,
        type,
        month: value?.$M + 1,
        year: value?.$y,
        sortBy,
        orderBy,
        store_id: selectedStore ? selectedStore.value : null
      },
    };
    const url = API.GET_All_MY_PAYOUTS;
    dispatch(changeLoader(true));
    globalRequest("get", url, false, params, true, true)
      .then((response) => {
        const data = response?.data;
        if (data.ack == 1) {
          setpayoutDetails(data.payouts);
          dispatch(changeLoader(false));
          setPayoutCount(data.payoutsCount);
        }
      })
      .catch((e) => {
        dispatch(
          changeSnackbar({
            state: "error",
            isOpen: true,
            message: t("somethingWentWrong"),
          })
        );
      });
  }

  const getTimeZone = () => {
    const url = API.GET_TIMEZONE;
    globalRequest("get", url, false, {}, true, true)
      .then(({data, status}) => {
        timeZone = data.time_zone
        setTimeZone(timeZone)
      }).catch((e) => {
        console.error('error', e);
      });
  }

  const changeOrder = (format, order_by) => {
    sortBy = order_by;
    setSortBy(sortBy)
    orderBy = format;
    setOrderBy(orderBy)
    getAllPayouts();
  };

  const changePageNo = (no) => {
    page = no;
    setPage(page);
  };

  const getHumanDate = (dateTime) => {
    if (timeZone !== "") {
      let newDateTime = new Date(dateTime);
      newDateTime = newDateTime.toLocaleString("en-US", {
        timeZone: timeZone,
      });
      const finalDate = new Date(newDateTime); 
      return `${finalDate.getDate()} ${monthArr[finalDate.getMonth()]} ${finalDate.getFullYear()}`
    }
  }

  const downloadCSV = async () => {
    const params = {
      params: { 
        limit,
        page,
        type,
        month: value?.$M + 1,
        year: value?.$y,
        sortBy,
        orderBy,
        store_id: selectedStore ? selectedStore.value : null
      },
    };
    const url = API.EXPORT_ALL_MY_PAYOUTS;
    dispatch(changeLoader(true));
    const response = await globalRequest("get", url, false, params, true, true)
    if (response.data.ack == 1) {
      window.open(API_URL + response.data.path, "_blank");
      dispatch(changeLoader(false));
    } else {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          isOpen: true,
          state: "error",
          message: "Error in downloading file",
        })
      );
    }
  }

  const getStoreOptions = async () => {
    dispatch(changeLoader(true));
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + tokenData.token.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    storeOptions =[];
    if (response.data.ack === 1) {
      storeOptionArray = response.data.stores
        .filter((store) => (store.is_approved === null || store.is_approved === true)).map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address
        }
      });
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    storeOptions = [...storeOptions, ...storeOptionArray];
    setStoreOptions(storeOptions);
    selectedStore = storeOptions[0];
    if (tokenData.token.role === 'vendor_users') {
      selectedStore = storeOptions.find((store) => store.value === tokenData.token.store.id);
    }
    setSelectedStore(selectedStore);
    getAllPayouts();
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    const count = Math.ceil(payoutCount / 10);
    setPaginationCount(count);
  }, [payoutCount]);
  
  useEffect(() => {
    getTimeZone()
  }, []);

  useLayoutEffect(() => {
    getStoreOptions();
  }, []);

  return (
    <div className="main-mid-wrapper">
      <div className="order-listing-wrapper">
        <Boxed className="top-flex-row" mb={"20px"}>
          <div className="store-detail-head" style={{ marginBottom: "0px" }}>
            <h3 className="h3" style={{ marginRight: "0px" }}>
              {t("payout_earnings_Payouts")}
            </h3>
          </div>
          <Boxed
            display={"flex"}
            alignItems={"center"}
            className="flex-wrap-mobile"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="calender-icon width160 mx-2"
                views={["year", "month"]}
                value={value}
                displayEmpty
                inputProps={{"aria-label": "Without label",}}
                onChange={(newValue) => {
                  page = 1;
                  setPage(page);
                  value = newValue;
                  setValue(value);
                  getAllPayouts()
                }}
                renderInput={(params) => <TextField {...params} size={"small"} helperText={null} />}
              />
            </LocalizationProvider>
            <FormControl className="payout-status" sx={{ margin: "0px 0px", minWidth:'185px' }}>
              <Select
                size="small"
                value={type}
                onChange={(e) => {
                  type = e.target.value;
                  setType(type);
                  page = 1;
                  setPage(page);
                  getAllPayouts()
                }}
                className="bg-white"
                displayEmpty
                inputProps={{"aria-label": "Without label",}}
                MenuProps={{ disableScrollLock: true }}
                startAdornment={<InputAdornment position="start">{t('payout_payoutStatus')}:</InputAdornment>}
              >
                <MenuItem value={"all"} > {t('payout_All')} </MenuItem>
                <MenuItem value={"settled"} > {t('payout_Settled')} </MenuItem>
                <MenuItem value={ORDER_STATUS.PLANNED} >{t('payout_Planned')}</MenuItem>
              </Select>
            </FormControl>
            {tokenData.token.role === 'vendor'?<FormControl className="payout-status" sx={{ marginLeft: "16px", minWidth:'165px', backgroundColor: '#ffffff' }}>
              <SearchSelectSingleStore
                options={storeOptions}
                value={selectedStore}
                size="small"
                className="bg-white"
                handleOptionsChange={(e, value) => {
                  page = 1;
                  setPage(page);
                  selectedStore = value;
                  setSelectedStore(selectedStore);
                  getAllPayouts();
                }}
              />
              {/* <Select
                size="small"
                value={StoreStatus}
                onChange={StoreStatusChange}
                className="bg-white"
                displayEmpty
                inputProps={{"aria-label": "Without label",}}
                MenuProps={{ disableScrollLock: true }}
                startAdornment={<InputAdornment position="start">{t('manageStore_store')}:</InputAdornment>}
              >
                <MenuItem value={"All"} > {t('All')} </MenuItem>
                <MenuItem value={"Anand General Store"} > {t('Anand General Store')} </MenuItem>
              </Select> */}
            </FormControl>: ''}
            <Button
              variant="contained"
              size="medium"
              sx={{ marginLeft: "16px", minWidth: "140px" }}
              onClick={downloadCSV}
            >
              {t("exportCSV")}
            </Button>
          </Boxed>
        </Boxed>
        <div className="">
         {payoutDetails.length >0? <div className="tabs-wrap">
            <div className="table-block">
              <div className="table-wrapper">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div className="short-div">
                            {t("salesPeriod")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  changeOrder("ASC", "period_start_date");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  changeOrder("DESC", "period_start_date");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="short-div">
                            {t("expectedPayoutDate")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  changeOrder("ASC", "expected_payout_date");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  changeOrder("DESC", "expected_payout_date");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="short-div">
                            {t("actualPayoutDate")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  changeOrder("ASC", "actual_payout_date");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  changeOrder("DESC", "actual_payout_date");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="short-div">
                            {t("salesAmount")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  changeOrder("ASC", "sales_amount");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  changeOrder("DESC", "sales_amount");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="short-div">
                            {t("expectedPayoutAmount")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  changeOrder("ASC", "expected_payout_amount");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  changeOrder("DESC", "expected_payout_amount");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="short-div">
                            {t("actualPayoutAmount")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  changeOrder("ASC", "actual_payout_amount");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  changeOrder("DESC", "actual_payout_amount");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="short-div">
                            {t("payoutStatus")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  changeOrder("ASC", "status");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  changeOrder("DESC", "status");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payoutDetails.map((obj, index) => {
                        let expected_payout_date = new Date(
                          obj.expected_payout_date
                        );
                        let startSalesTime = new Date(obj.period_start_date);
                        let endSalestime = new Date(obj.period_end_date);
                        return (
                          <TableRow
                            key={index}
                            onClick={() => {
                              dispatch(changePage(PAGES.PAYOUTS_DETAIL));
                              dispatch(changePayoutsId(obj.id));
                            }}
                            style={{cursor: 'pointer'}}
                          >
                            <TableCell component="th" scope="row" dir="ltr">
                              {getHumanDate(startSalesTime)} -{" "} {getHumanDate(endSalestime)}
                              {/* {`${startSalesTime.getDate()} ${monthArr[startSalesTime.getMonth()]} ${startSalesTime.getFullYear()}`}{" "} - {`${endSalestime.getDate()} ${monthArr[endSalestime.getMonth()]} ${endSalestime.getFullYear()}`} */}
                            </TableCell>
                            <TableCell dir="ltr">
                              {/* {`${expected_payout_date.getDate()} ${
                                monthArr[expected_payout_date.getMonth()]
                              } ${expected_payout_date.getFullYear()}`} */}
                              {getHumanDate(expected_payout_date)}
                            </TableCell>
                            <TableCell dir="ltr">
                              {obj.actual_payout_date
                                ? getHumanDate(obj.actual_payout_date)
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {t("QAR")} {formatPrice(obj.sales_amount)}
                            </TableCell>
                            <TableCell>
                              {t("QAR")}{" "}
                              {formatPrice(obj.expected_payout_amount)}
                            </TableCell>
                            <TableCell>
                              {obj.actual_payout_amount?t("QAR"):''} {formatPrice(obj.actual_payout_amount)}
                            </TableCell>
                            <TableCell>
                              <span className="chips-fill gray">
                                {obj.status}
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {payoutDetails.length == 0 ? t("payout_NoRecordsFound") : ""}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {payoutDetails.length > 0 ? <Pagination pageNo={changePageNo} countNo={paginationCount} />: ""}
            </div>
          </div>: <NoDataFound text={t("payout_noPayouts")}/>}
        </div>
      </div>
    </div>
  );
};

export default EarningsPayouts;
