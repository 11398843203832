/**
 * @fileoverview socket.io client
 * @description socket.io client for real time communication between client and server
 * @package socket.io-client - https://www.npmjs.com/package/socket.io-client
 * @returns socket - socket.io client instance
 */

import { io } from "socket.io-client";
import { API_URL } from "./BaseUrl";

const socket = io(API_URL, {
  transports: ["websocket"],
  autoConnect: true,
  reconnectionAttempts: 10,
  reconnectionDelay: 10000,
});

export default socket;
