import React, { useState, useEffect, useLayoutEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Grid, Button, Checkbox } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import { Boxed } from "../../Styles-Elements/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import "./StoreDetail.scss";
import cancelRed from "../../assets/images/structure/cancel-red.svg";
import closeGray from "../../assets/images/structure/close-gray.svg";
import arrowLeftBlack from "../../assets/images/structure/arrow-left-black.svg";
import modalClose from "../../assets/images/structure/close-black.svg";
import { useDispatch } from "react-redux";
import globalRequest from "../../global-modules/globalRequest";
import validEmail from "../../global-modules/validEmail";
import errorMessage from "../../global-modules/errorMessage";
import { API, ROLE, PAGES, KEYS } from "../../Enum";
import { API_GET_GEOLOCATION, VALIDATE_COORDINATES } from "../../Enum";
import uploadBlackIcon from "../../assets/images/structure/upload-black-icon.svg";
import readImageFile from "../../global-modules/readImageFile";
import { changeLoader } from "../../redux/reducers/loader";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import thankyouImg from "../../assets/images/structure/thankyou-img.png";
import skipReg from "../../global-modules/skipReg";
import AddPayoutDetails from "./AddPayoutDetails";
import ManageHoliday from "./ManageHolidays";
import ManageWorkingHours from "./ManageWorkingHoursNew";
import GeneralSetting from "./GeneralSetting";
import { changePage } from "../../redux/reducers/page";
import { currentLocalData } from "../../redux/reducers/localData";
import { API_URL } from "../../BaseUrl";
import Geocode from "react-geocode";
import { useTranslation } from "react-i18next";
import {
  currentStoreData,
  currentStoreOption,
  changeHolidays,
  currentHolidays,
} from "../../redux/reducers/editStoreData";
import { useSelector } from "react-redux";
import {getAddress} from "../../Helpers/AddressService";
import DocumentPopup from "./DocumentPopup";
import {arrangeDaysInWeek} from "../../Helpers/Helpers";
import { geocodeByPlaceId, } from 'react-google-places-autocomplete';
import GoogleAutocompletePlaces from '../../global-modules/Google-libraries/GoogleAutocompletePlaces';
import MyMapComponent from '../../global-modules/Google-libraries/MapComponent';
import AutocompleteAddress from '../../global-modules/OpenStreetMap-library/AutocompleteAddress';

const formData = new FormData();

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Boxed>{children}</Boxed>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const StoreDetail = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const curruntStoreData = useSelector(currentStoreData);
  const storeOption = useSelector(currentStoreOption);
  const localData = useSelector(currentLocalData);
  const currentHoliday = useSelector(currentHolidays);
  
  let [open247, setOpen247] = useState(false);
  let [marketPlaceMerchant, setMarketPlaceMerchant] = useState('no');
  let [buildingAddress, setBuildingAddress] = useState("");
  let [apartmentNo, setApartmentNo] = useState("");
  let [floorNo, setFloorNo] = useState("");
  let [googleAddress, setGoogleAddress] = useState([]);
  let [showMaps, setShowMaps] = useState(false);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [bType, setBType] = useState("");
  const [businessTypeList, setBusinessTypeList] = useState([]);
  const [bName, setBName] = useState("");
  const [bTypeErr, setBTypeErr] = useState(true);
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);
  const [streetNo, setStreetNo] = useState("");
  const [buildingNo, setBuildingNo] = useState("");
  const [zoneNo, setZoneNo] = useState("");
  const [businessLogo, setBusinessLogo] = useState(null);
  const [businessLogoErr, setBusinessLogoErr] = useState(true);
  const [companyRegistrationFile, setCompanyRegistrationFile] = useState([]);
  const [companyRegistrationErr, setCompanyRegistrationErr] = useState(true);
  const [bNameErr, setBNameErr] = useState(true);
  const [country, setCountry] = useState("Qatar");
  const [countryErr, setCountryErr] = useState(true);
  const [bNameArabic, setBNameArabic] = useState("");
  const [bNameArabicErr, setBNameArabicErr] = useState(true);
  const [value, setValue] = React.useState(0);
  const [debounce, setDebounce] = useState(true);
  const [openAddHoliday, setOpenAddHoliday] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isAdded, setIsAdded] = useState(true);
  const [isEditted, setIsEditted] = useState(false);
  const [holidayData, setHolidayData] = useState([]);
  const [saveData, setSaveData] = useState([]);
  const [workingHolidays, setWorkingHoloidays] = useState([]);
  const [showHoliday, setShowHoliday] = useState(false);
  const [preloadHolidays, setPreloadHoloidays] = useState([]);
  const [openThankYou, setOpenThankYou] = useState(false);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(true);
  const [accountNo, setAccountNo] = useState("");
  const [accountNoErr, setAccountNoErr] = useState(true);
  const [iban, setIban] = useState("");
  const [ibanErr, setIbanErr] = useState(true);
  const [swiftCode, setSwiftCode] = useState("");
  const [switchCodeErr, setSwiftCodeErr] = useState(true);
  const [showEmail, setShowEmail] = useState(false);
  const [getTheStore, setGetTheStore] = useState(false);
  const [payoutDetails, setpayOutDetails] = useState("");
  const [dayOpenClose, setDayOpenClose] = useState([{
    index: 0,
    day: "sunday",
    open: true,
    time: [{ startTime: "01:00", endTime: "18:00", startTimeError: "", endTimeError: "" }]
  }, {
    index: 1,
    day: "monday",
    open: true,
    time: [{ startTime: "01:00", endTime: "18:00", startTimeError: "", endTimeError: "" }]
  }, {
    index: 2,
    day: "tuesday",
    open: true,
    time: [{ startTime: "01:00", endTime: "18:00", startTimeError: "", endTimeError: "" }]
  }, {
    index: 3,
    day: "wednesday",
    open: true,
    time: [{ startTime: "01:00", endTime: "18:00", startTimeError: "", endTimeError: "" }]
  }, {
    index: 4,
    day: "thursday",
    open: true,
    time: [{ startTime: "01:00", endTime: "18:00", startTimeError: "", endTimeError: "" }]
  }, {
    index: 5,
    day: "friday",
    open: true,
    time: [{ startTime: "01:00", endTime: "18:00", startTimeError: "", endTimeError: "" }]
  }, {
    index: 6,
    day: "saturday",
    open: true,
    time: [{ startTime: "01:00", endTime: "18:00", startTimeError: "", endTimeError: "" }]
  }]);
  let [cuisineList, setCuisineList] = useState([]);
  let [selectedOption, setSelectedOption] = useState([]);
  let [bannerImage, setBannerImage] = useState({
    image: null,
    file: null,
    isBase64: false,
  });
  let [deliveryCharge, setDeliveryCharge] = useState(0);

  const populateAddress = async (address) => {
    if (address) {
      // ---------- Open Street Map Address ---------- //
      buildingAddress = address.display_name;
      setBuildingAddress(buildingAddress);
      setLatitude(address.lat);
      setLongitude(address.lon);
      // ---------- Open Street Map Address ---------- //
      // ---------- Google Address ---------- // 
      // googleAddress = address;
      // setGoogleAddress(googleAddress);
      // enableResetGoogleAddress();
      // const geocode = await geocodeByPlaceId(address.value.place_id);
      // buildingAddress = geocode[0].formatted_address;
      // setBuildingAddress(buildingAddress);
      // setLatitude(geocode[0].geometry.location.lat());
      // setLongitude(geocode[0].geometry.location.lng());
      // ---------- Google Address ---------- //
    }
  };

  const enableResetGoogleAddress = () => {
    try {
      let elements = document.getElementsByClassName("css-tlfecz-indicatorContainer");
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener("click", resetGoogleAddress, true);
      }
    } catch (error) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: 'Something went wrong',
        state: "error",
      }));
    }
  };

  const getAddressFromLatLng = async (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
    buildingAddress = await getAdressFromLatLng(lat, lng);
    setBuildingAddress(buildingAddress);
  }
  
  const getStoreDetails = () => {
    const url = API.GET_SPECIFIC_STORE(curruntStoreData);
    globalRequest("GET", url, {}, {}, true, true).then((res) => {
      let data = res?.data;
      if (data.ack == 1) {
        const {
          businessTypeId, company_registration_ides, street_number, zone_number, building_number,
          business_logo, manage_working_hours, manage_holidays, payout_detail, open247, cuisineId, banner_image
        } = data.store;
        const englishData = data?.store?.stores_locales.find((val) => val?.locale == "en");
        const arabicData = data?.store?.stores_locales.find((val) => val?.locale == "ar");
        let business_name = englishData?.business_name;
        let business_name_arabic = arabicData?.business_name;

        company_registration_ides.forEach((val) => {
          const document = val?.image.split('/')[val?.image.split('/').length -1];
          setCompanyRegistrationFile((prev) => {
            return [...prev, {document: document, image: val?.image}];
          });
        });
        const itemIds = cuisineId?.split(",").filter((item) => item !== '');
        selectedOption = cuisineList?.filter((item) => itemIds.includes(item.id.toString()));
        setSelectedOption(selectedOption);
        setBannerImage({
          image: null,
          file: banner_image,
          isBase64: false,
        });
        setpayOutDetails(payout_detail);
        setAccountNo(payout_detail.bankAccountNumber);
        setName(payout_detail.beneficiaryName);
        setSwiftCode(payout_detail.swiftCode);
        setIban(payout_detail.iban);
        setPreloadHoloidays(manage_holidays);
        setBType(businessTypeId);
        setBName(business_name);
        
        setStreetNo(street_number);
        setFloorNo(street_number);
        setZoneNo(zone_number);
        setBuildingNo(building_number);
        setApartmentNo(data?.store?.apartment_number);
        setBuildingAddress(data?.store?.business_address);
        setBNameArabic(business_name_arabic);
        setBusinessLogo(business_logo);
        setLatitude(data?.store?.latitude);
        setLongitude(data?.store?.longitude);
        setOpen247(open247 == 1 ? true : false);
        setMarketPlaceMerchant(data?.store?.market_place === 1? 'yes': 'no');
        setDeliveryCharge(data?.store?.delivery_charges);
        if (!open247) {
          if (manage_working_hours.length > 0) {
            const newTemp = arrangeDaysInWeek(manage_working_hours);
            let temp = [...dayOpenClose]
            newTemp.forEach((val, ind) => {
              temp[ind].open = val?.open;
              temp[ind].day = val?.day;
              temp[ind].time = val?.timejson !== ''? JSON.parse(val?.timejson).map((time) => {
                return {
                  startTime: time?.starttime,
                  endTime: time?.endtime,
                  startTimeError: "",
                  endTimeError: ""
                };
              }) :[{
                startTime: val?.starttime,
                endTime: val?.endtime,
                startTimeError: "",
                endTimeError: ""
              }];
            });
          }
        } else {
          temp = [...dayOpenClose];
          manage_working_hours.forEach((val, ind) => {
            temp[ind].open = true;
            temp[ind].day = val?.day;
            temp[ind].time = [{
              startTime: '00:00',
              endTime: '23:59',
              startTimeError: "",
              endTimeError: ""
            }]
          });
        }
        setDayOpenClose(temp);
        dispatch(changeHolidays(manage_holidays));
      }
    }).catch((e) => {});
  };

  const getCuisine = () => {
    const url = API.GET_CUISINE;
    globalRequest("GET", url, {}, {}, true, true).then((res) => {
      cuisineList = [];
      res?.data?.data.filter(da => da.cuisines.length > 0).map((item) => {
          const cuisi = item.cuisines.map((cui) => {
              cui.categoryName = item.name;
              cui.cuisineName = cui.cuisines_locales[0].name;
              return cui
          });
          cuisineList.push(...cuisi);
      });
      setCuisineList(cuisineList);
    }).catch((e) => {});
  };

  
  useLayoutEffect(() => {
    getCuisine();
    formData.delete("businessTypeId");
    formData.delete("business_name");
    formData.delete("business_name_arabic");
    formData.delete("zone_number");
    formData.delete("street_number");
    formData.delete("building_number");
    formData.delete("country");
    formData.delete("role");
    formData.delete("business_logo");
    formData.delete("latitude");
    formData.delete("longitude");
    formData.delete("beneficiaryName");
    formData.delete("bankAccountNumber");
    formData.delete("iban");
    formData.delete("swiftCode");
    formData.delete("business_address");
    formData.delete("workingholiday");
    formData.delete("workinghoursdata");
    formData.delete("delivery_charges");
    setCompanyRegistrationFile([]);
  }, []);

  useEffect(() => {
    if (storeOption !== null) {
      setShowEmail(true);
      setTimeout(() => {
        getStoreDetails();
      }, 1000);
    } else {
      setShowEmail(false);
    }
    setEmail(localData.token.email);
    setMobile(localData.token.contact_number);
  }, [getTheStore]);

  useEffect(() => {
    if (currentHoliday) {
      setSaveData(currentHoliday);
      for (let i = 0; i < currentHoliday.length; i++) {}
    }
  }, []);

  const submit2 = async (e) => {
    let bool = true;
    let a = [];
    if (open247) {
      dayOpenClose.forEach((obj, ind) => {
        a.push({
          day: obj.day,
          open: obj.open,
          time: [{ starttime: '00:00', endtime: '24:00' }]
        });
      })
    } else {
      dayOpenClose.forEach((obj, ind) => {
        a.push({
          day: obj.day,
          open: obj.open,
          time: obj.time.map((shift) => {
            if (shift.startTimeError !== '' || shift.endTimeError !== '') {
              bool = false;
            }
            return {
              starttime: shift.startTime,
              endtime: shift.endTime
            }
          })
        });
      });
    }
    if (bool) {
      if (storeOption == "edit") {
        dispatch(changeLoader(true));
        const url = API.MANAGE_WORKING_HOURS_BY_ID(curruntStoreData);
        globalRequest("post", url, {hoursdata: JSON.stringify(a), open247: open247 ? 1: 0}, {}, true, true)
        .then(async (res) => {
          let data = res?.data;
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: data?.ack == 1 ? "success" : "error",
              message: data?.msg,
            })
          );
          dispatch(changeLoader(false));
        }).catch((err) => {
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: "error",
              message: t("somethingWentWrong"),
            })
          );
          dispatch(changeLoader(false));
        });
      } else {
        formData.delete("workinghoursdata");
        formData.delete("open247");
        formData.append("workinghoursdata", JSON.stringify(a));
        formData.append("open247", open247 ? 1: 0);
      }
      setValue(value + 1);
    } else {
      dispatch(
        changeSnackbar({
          isOpen: true,
          state: "error",
          message: t('manageStore_shiftTimeAreOverlapping'),// t('manageStore_EndTimeShouldBeGreater', {days: days.toString()}),
        })
      );
    }
  };

  useEffect(() => {
    globalRequest("get", API.BUSINESS_TYPE, {}, {}, false)
      .then((res) => {
        let data = res?.data;
        if (data?.ack == 1) {
          setBusinessTypeList(
            data?.businesstypes.filter((item) => item?.slug !== "grocery")
          );
        }
      })
      .catch((err) => {
        dispatch(
          changeSnackbar({
            state: "error",
            isOpen: true,
            message: t("somethingWentWrong"),
          })
        );
      });
  }, []);

  const removeFile = (index) => {
    setCompanyRegistrationFile(
      companyRegistrationFile.filter((obj) => {
        return obj.index !== index;
      })
    );
  };

  const getAdressFromLatLng = async (lat, lng) => {
    const response = await Geocode.fromLatLng(lat, lng);
    const address = response.results[0].formatted_address;
    return address;
  };
  
  const getGeolocation = async () => {
    const {ack, data} = await getAddress(zoneNo, streetNo, buildingNo);
    if (ack === 1) {
      setLongitude(data?.longitude);
      setLatitude(data?.latitude);
      formData.delete("business_address");
      formData.append("business_address", data?.buildingAddress);
    }
  };

  useEffect(() => {
    if (businessLogo !== null) {
      readImageFile(businessLogo, "imageFile");
    }
  }, [businessLogo]);

  const handleSubmit = async () => {
    if (!bType) {
      setBTypeErr(false);
    }
    if (!bName) {
      setBNameErr(false);
    }
    if (businessLogo == null) {
      setBusinessLogoErr(false);
    }
    if (!companyRegistrationFile[0]) {
      setCompanyRegistrationErr(false);
    }
    if (country == "") {
      setCountryErr(false);
    }
    if (!bNameArabic) {
      setBNameArabicErr(false);
    }
    
    if (
      !bType ||
      !bName ||
      businessLogo == null ||
      !companyRegistrationFile[0] ||
      !country ||
      !bNameArabic
    ) {
    } else if (latitude == 0.0) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t("manageStore_latitudeError"),
          state: "error",
        })
      );
    } else if (longitude == 0.0 && storeOption != "edit") {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t("manageStore_longitudeError"),
          state: "error",
        })
      );
    } else {
      formData.delete("businessTypeId");
      formData.delete("business_name");
      formData.delete("business_name_arabic");
      formData.delete("zone_number");
      formData.delete("street_number");
      formData.delete("building_number");
      formData.delete("country");
      formData.delete("role");
      formData.delete("business_logo");
      formData.delete("latitude");
      formData.delete("longitude");
      formData.delete("image");
      formData.append("businessTypeId", bType);
      formData.append("business_name", bName);
      formData.append("business_name_arabic", bNameArabic);
      formData.append("zone_number", zoneNo);
      formData.append("building_number", buildingNo);
      formData.append('apartment_number', apartmentNo);
      formData.append('street_number', floorNo);
      formData.append("business_address", buildingAddress);
      formData.append("country", country);
      formData.append("role", ROLE);
      formData.append("business_logo", businessLogo);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      if (storeOption == null) {
        if (companyRegistrationFile[0]) {
          formData.append("image", companyRegistrationFile[0].file);
        }
        if (companyRegistrationFile[1]) {
          formData.append("image", companyRegistrationFile[1].file);
        }
        if (companyRegistrationFile[2]) {
          formData.append("image", companyRegistrationFile[2].file);
        }
      }
      setDebounce(true);
      setValue(value + 1);
    }
  };

  useEffect(() => {
    if (isAdded || isDeleted || isEditted) {
      getHoliday();
      setIsAdded(false);
      setIsDeleted(false);
      setIsEditted(false);
    }
  }, [isAdded, isDeleted, isEditted]);

  const getHoliday = () => {
    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API.GET_WORKING_HOLIDAY,
      {},
      {
        params: {
          sort_by: "date",
          order_by: "asc",
        },
      },
      true,
      true
    )
      .then((res) => {
        let data1 = res?.data;
        if (data1.ack == 1) {
          let holidayResult = [];
          holidayResult.push(data1.result);

          setWorkingHoloidays(holidayResult);
        }
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
      });
  };

  const addHoliday = (data) => {
    globalRequest(
      "post", API.MANAGE_HOLIDAY,
      { holidayName: data?.holidayName, date: data?.date },
      {}, true, true
    );
  };

  const saveHolidays = () => {
    if (saveData) {
      formData.delete("workingholiday");
      formData.append("workingholiday", saveData.length > 0?JSON.stringify(saveData): '');
      setValue(value + 1);
      if (storeOption == "edit") {
        const holidaydata = saveData[saveData.length - 1];
        addHoliday(holidaydata);
      }
    }
  };

  const handleDocumentPopup = (document) => {
    documentSrc = API_URL + document;
    window.open(documentSrc, "_blank");
  }

  const submitGeneral = () => {
    if (storeOption == "edit") {
      dispatch(changeLoader(true));
      const settingFormData = new FormData();
      settingFormData.delete("market_place");
      settingFormData.delete("cuisineId");
      settingFormData.delete("banner_image");
      settingFormData.delete("delivery_charges");
      settingFormData.append("market_place", marketPlaceMerchant==="yes"?1:0);
      settingFormData.append("cuisineId", selectedOption.map((item) => item.id).join(","));
      settingFormData.append("banner_image", bannerImage.isBase64? bannerImage.image: bannerImage.file);
      settingFormData.append("delivery_charges", deliveryCharge);
      globalRequest("post", API.UPDATE_GENERAL_SETTINGS(curruntStoreData), settingFormData, {}, true, true).then((res) => {
        let data = res?.data;
        if (data.ack == 1) {
          setDebounce(false);
          dispatch(changeSnackbar({ isOpen: true, state: "success", message: data.msg }));  
          dispatch(changeLoader(false));
          dispatch(changePage(PAGES.MY_STORE));
        } else {
          let message = '';
          if (data.msg !== '') {
            message = data.msg;
          } else {
            data.errMsg.forEach((element) => {
              for (let [key, value] of Object.entries(element)) { message += value; }
            });
          }
          dispatch(changeSnackbar({ isOpen: true, state: "error", message: message }));
          dispatch(changeLoader(false));
        }
      }).catch((e) => {
        dispatch(changeLoader(false));
        dispatch(changeSnackbar({ isOpen: true, state: "error", message: t("somethingWentWrong") }));
      });
    }
    if (storeOption !== "edit" && debounce == true) {
      dispatch(changeLoader(true));
      formData.delete("market_place");
      formData.delete("cuisineId");
      formData.delete("bannerImage");
      formData.delete("delivery_charges");
      formData.append("market_place", marketPlaceMerchant==="yes"? 1: 0);
      formData.append("cuisineId", selectedOption.map((item) => item.id));
      formData.append("bannerImage", bannerImage);
      formData.append("delivery_charges", deliveryCharge);
      globalRequest("post", API.ADD_STORE, formData, {}, true, true).then((res) => {
        let data = res?.data;
        if (data.ack == 1) {
          setOpenThankYou(true);
          setDebounce(false);
          dispatch(changeLoader(false));
        }
      }).catch((e) => {
        dispatch(changeLoader(false));
        dispatch(changeSnackbar({ isOpen: true, state: "error", message: t("somethingWentWrong") }));
      });
    }
  };

  const submitPayout = () => {
    if (name && accountNo && iban && swiftCode) {
      if (storeOption != "edit") {
        formData.delete("beneficiaryName");
        formData.delete("bankAccountNumber");
        formData.delete("iban");
        formData.delete("swiftCode");
        formData.append("beneficiaryName", name);
        formData.append("bankAccountNumber", accountNo);
        formData.append("iban", iban);
        formData.append("swiftCode", swiftCode);

        if (
          !bType ||
          !bName ||
          businessLogo == null ||
          !companyRegistrationFile[0] ||
          !country ||
          !bNameArabic
        ) {
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: t("manageStore_PleaseEnterFields"),
              state: "error",
            })
          );
          dispatch(changeLoader(false));
        }
      }

      if (storeOption == "edit") {
        const url =
          storeOption == "edit"
            ? API.EDIT_ACCOUNT(payoutDetails.id)
            : API.PAYOUT_DETAIL;

        dispatch(changeLoader(true));
        globalRequest(
          "post",
          url,
          {
            beneficiaryName: name,
            bankAccountNumber: accountNo,
            iban: iban,
            swiftCode: swiftCode,
          },
          {},
          true,
          true
        )
          .then(async (res) => {
            let data = res?.data;
            if (data.ack == 1) {
              dispatch(changeLoader(false));
              dispatch(
                changeSnackbar({
                  isOpen: true,
                  state: "success",
                  message: data.msg,
                })
              );
              setValue(value + 1);
            }
          })
          .catch((err) => {
            dispatch(changeLoader(false));
            dispatch(
              changeSnackbar({
                isOpen: true,
                state: "error",
                message: t("somethingWentWrong"),
              })
            );
          });
      }
      if (storeOption !== "edit") {
        setValue(value + 1);
      }
    } else {
      setNameErr(false);
      setAccountNoErr(false);
      setIbanErr(false);
      setSwiftCodeErr(false);
      dispatch(changeLoader(false));
    }
  };

  const Thankyou = ({ isOpen = false, handleClose }) => {
    return (
      <Dialog open={isOpen} onClose={handleClose} className="main-modal">
        <img
          src={modalClose}
          alt="..."
          onClick={() => {
            dispatch(changePage(PAGES.MY_STORE));
          }}
          className="modal-close"
        />
        <DialogContent>
          <Boxed
            className="text-center"
            style={{ marginBottom: "20px", marginTop: "10px" }}
          >
            <img src={thankyouImg} alt="" />
          </Boxed>
          <h2 className="h3 text-center" style={{ marginBottom: "8px" }}>
            {t("manageStore_applicationSubmitted")}
          </h2>
          <p
            className="p3 text-center"
            style={{ marginBottom: "12px", fontSize: "14px", width: "355px" }}
          >
            {t("manageStore_youHaveSuccessfullyCompletedYourStoreSetup")}
          </p>
          <p
            className="p3 text-center"
            style={{ marginBottom: "12px", fontSize: "14px" }}
          >
            {t("manageStore_thankyouForPatience")}
          </p>
        </DialogContent>
      </Dialog>
    );
  };

  const handleChange1 = (event, newValue) => {
    if (storeOption == "edit") setValue(newValue);
    else {
      if (value > 0) {
        if (newValue < value) {
          setValue(newValue);
        }
      }
    }
  };

  const handleClose = () => {
    setOpenAddHoliday(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowMaps(true);
    }, 1000);
  }, []);

  return (
    <div className="main-mid-wrapper">
      <div className="store-detail-wrapper">
        <div className="store-detail-head">
          <h3 className="h3">{t("manageStore_storeDetail")}</h3>
        </div>
        <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4 }}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <div className="store-detail-tabs-block">
              <div className="card-white">
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange1}
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  <Tab label="Business Detail" {...a11yProps(0)} />
                  <Tab label="Manage Working hours" {...a11yProps(1)} />
                  <Tab label="Manage Holiday" {...a11yProps(2)} />
                  <Tab label="Add Payout Detail" {...a11yProps(3)} />
                  <Tab label="General Setting" {...a11yProps(4)} />
                </Tabs>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9}>
            <TabPanel value={value} index={0}>
              <div className="card-white">
                <div className="business-detail-wrap">
                  <h6 className="h6">{t("manageStore_businessInformation")}</h6>
                  <p className="p3">
                    {t(
                      "manageStore_theBusinessEmailAddressAndPhoneNumberWillBeUsedForLoginPurpose"
                    )}
                  </p>
                  <div className="signup-right">
                    <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}>
                      {showEmail ? (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={10}>
                            <div className="form-group">
                              <TextField
                                required
                                label={t("manageStore_businessEmail")}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                                disabled={true}
                                value={email}
                              />
                            </div>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                      {showEmail ? (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={10}>
                            <div className="form-group">
                              <TextField
                                required
                                value={mobile}
                                label={t("manageStore_businessMobileNumber")}
                                onChange={(e) => {
                                  let mob = e.target.value.replace(/\D/g, "");
                                  setMobile(mob);
                                }}
                                disabled={true}
                                inputProps={{ maxLength: 8 }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">{'+974'}</InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}

                      <Grid item xs={12} sm={12} md={6} lg={5}>
                        <div className="form-group">
                          <FormControl fullWidth required>
                            <InputLabel id="demo-simple-select-label">
                              {t("manageStore_storeType")}
                            </InputLabel>
                            <Select
                              disabled={storeOption == "edit" ? true : false}
                              className={storeOption == "edit" ? "aroow-hide-disabled" : ""}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={t("manageStore_storeType")}
                              defaultValue=""
                              value={bType}
                              onChange={(e) => {
                                setBType(e.target.value);
                              }}
                            >
                              {businessTypeList.map((obj, index) => {
                                if (obj.name != "Grocery")
                                  return (
                                    <MenuItem key={index} value={obj.id}>
                                      {obj.business_type_locales[0].name}
                                    </MenuItem>
                                  );
                              })}
                            </Select>
                            {!bTypeErr && !bType ? (
                              <span className="errorspan">{t("manageStore_business_type")}</span>
                            ) : null}
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={5}>
                        <div className="form-group">
                          <TextField
                            required
                            disabled={storeOption == "edit" ? true : false}
                            className={storeOption == "edit" ? "aroow-hide-disabled" : ""}
                            label={t("manageStore_storeNameEnglish")}
                            value={bName}
                            inputProps={{ maxLength: 256 }}
                            onChange={(e) => {
                              setBName(e.target.value);
                            }}
                          />
                          {!bNameErr && !bName ? (
                            <span className="errorspan">{t("manageStore_business_name")}</span>
                          ) : null}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={5}>
                        <div className="form-group">
                          <TextField
                            disabled={storeOption == "edit" ? true : false}
                            className={storeOption == "edit" ? "aroow-hide-disabled" : ""}
                            value={bNameArabic}
                            onChange={(e) => {
                              setBNameArabic(e.target.value);
                            }}
                            required={true}
                            label={t("manageStore_storeNameArebic")}
                            inputProps={{ maxLength: 256 }}
                          />
                          {!bNameArabicErr && !bNameArabic ? (
                            <span className="errorspan">{t("manageStore_business_name")}</span>
                          ) : null}
                        </div>
                      </Grid>
                      
                      <Grid item xs={12} mb={"16px"}>
                        <p className="p1">{t("manageStore_businessAddress")}</p>
                      </Grid>
                      {storeOption !== "edit"?<>
                        <Grid item xs={10} sm={10} style={{ position: 'relative' }} mb={"25px"}>
                        <AutocompleteAddress
                          setAddress={populateAddress}
                        />
                          {/* <GoogleAutocompletePlaces
                            googleAddress={googleAddress}
                            onChange={populateAddress}
                          /> */}
                        </Grid>
                        {showMaps? <Grid item xs={10} sm={10} style={{ position: 'relative' }} mb={"25px"}>
                        <MyMapComponent
                          lat={latitude}
                          lng={longitude}
                          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${KEYS.GOOLGE_MAP_ADDRESS_KEY}&libraries=places`}
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `200px` }} />}
                          mapElement={<div style={{ height: `100%` }} />}
                          ChangeAddress={(lat, lng) => {
                            getAddressFromLatLng(lat, lng)
                          }}
                        />
                        </Grid>: null}
                      </>: null}
                      
                      <Grid item xs={12} sm={12} md={10} lg={10}>
                        <div className="form-group">
                          <TextField
                            required
                            disabled={storeOption == "edit" ? true : false}
                            className={storeOption == "edit" ? "aroow-hide-disabled" : ""}
                            value={buildingAddress}
                            label={t("manageStore_buildingAddress")}
                            onChange={(e) => {
                              let m = e.target.value.replace(/\D/g, "");
                              setZoneNo(m);
                            }}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={5}>
                        <div className="form-group">
                          <TextField
                            disabled={storeOption == "edit" ? true : false}
                            className={storeOption == "edit" ? "aroow-hide-disabled" : ""}
                            value={apartmentNo}
                            label={t("manageStore_ApartmentNumber")}
                            onChange={(e) => { 
                              setApartmentNo(e.target.value);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={5}>
                        <div className="form-group">
                          <TextField
                            disabled={storeOption == "edit" ? true : false}
                            className={storeOption == "edit" ? "aroow-hide-disabled" : ""}
                            label={t("manageStore_buildingNumber")}
                            value={buildingNo}
                            onChange={(e) => { setBuildingNo(e.target.value); }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={5}>
                        <div className="form-group">
                          <TextField
                            disabled={storeOption == "edit" ? true : false}
                            className={storeOption == "edit" ? "aroow-hide-disabled" : ""}
                            label={t("manageStore_FloorNumber")}
                            value={floorNo}
                            onChange={(e) => {setFloorNo(e.target.value);}}
                          />
                        </div>
                      </Grid>


                      <Grid item xs={12} sm={12} md={6} lg={5}>
                        <div className="form-group">
                          <TextField
                            required
                            disabled={storeOption == "edit" ? true : false}
                            className={storeOption == "edit" ? "aroow-hide-disabled" : ""}
                            inputProps={{ readOnly: true }}
                            label={t("manageStore_country")}
                            value={t("manageStore_countryName")}
                          />
                          {!countryErr && !country ? (
                            <span className="errorspan">{t("manageStore_country")}</span>
                          ) : null}
                        </div>
                      </Grid>

                      <Grid item xs={12} mb={"16px"}>
                        <p className="p1">{t("manageStore_companyLogo")}</p>
                        <p className="p3 mb-0">{t("manageStore_uploadCompanyLogo")}</p>
                      </Grid>

                      <Grid item xs={12}>
                        {!storeOption ? (
                          <label className="img-upload-file">
                            <Boxed
                              display={"flex"}
                              alignItems={"center"}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={uploadBlackIcon}
                                className="upload-icon"
                                alt=""
                                style={{ marginRight: "24px" }}
                              />
                              <div className="">
                                <p className="p2">
                                  {t("manageStore_uploadFile")}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                                <p className="p3">{t("manageStore_fileFormat")}</p>
                              </div>
                            </Boxed>
                            <input
                              id="businessLogofordelete"
                              type="file"
                              onChange={(e) => {
                                if (e.target.files[0]) {
                                  const imageType = e.target.files[0].type;
                                  if (
                                    imageType == "image/png" ||
                                    imageType == "image/jpeg" ||
                                    imageType == "image/jpg"
                                  )
                                    setBusinessLogo(e.target.files[0]);
                                  else {
                                    dispatch(
                                      changeSnackbar({
                                        isOpen: true,
                                        message: t("manageStore_PleaseUploadValidDocuments"),
                                        state: "error",
                                      })
                                    );
                                  }
                                }
                              }}
                            />
                            {!businessLogoErr && businessLogo == null ? (
                              <span className="errorspan">{t("manageStore_business_logo")}</span>
                            ) : null}
                          </label>
                        ) : null}

                        <Boxed>
                          {businessLogo !== null ? (
                            <div className="uploaded-image-box">
                              <img
                                id={"imageFile"}
                                // style={{ height: "60px", width: "60px" }}
                                src={storeOption ? `${API_URL}/${businessLogo}` : ""}
                                className="store-logo"
                                alt=""
                              />
                              {storeOption != "edit" ? (
                                <img
                                  src={cancelRed}
                                  onClick={() => {
                                    setBusinessLogo(null);
                                    const value = (document.getElementById(
                                      "businessLogofordelete"
                                    ).value = null);
                                  }}
                                  className="cancel-red"
                                  alt=""
                                />
                              ) : null}
                            </div>
                          ) : null}
                        </Boxed>
                      </Grid>

                      <Grid item xs={12} mb={"16px"}>
                        <p className="p1">{t("manageStore_companyRegistrationIDDocuments")}</p>
                        <p className="p3 mb-0">{t("manageStore_uploadCompanyRegistrationID")}</p>
                      </Grid>

                      <Grid item xs={12}>
                        {!storeOption ? (
                          <label className="img-upload-file">
                            <Boxed
                              display={"flex"}
                              alignItems={"center"}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={uploadBlackIcon}
                                className="upload-icon"
                                alt=""
                                style={{ marginRight: "24px" }}
                              />
                              <div className="">
                                <p className="p2">
                                  {t("manageStore_uploadFile")}
                                  <span style={{ color: "red" }}>*</span>
                                </p>
                                <p className="p3">
                                  {t("manageStore_maxFiles")} {t("manageStore_fileFormatDoc")}
                                </p>
                              </div>
                            </Boxed>
                            <input
                              id="companyRegistrationDocumentsID"
                              type="file"
                              accept=".png,.jpeg,.jpg,.png,.doc,.docx,.pdf"
                              onChange={(e) => {
                                const imageType = e.target.files[0].type;

                                if (
                                  imageType == "image/png" ||
                                  imageType == "image/jpeg" ||
                                  imageType == "image/jpg" ||
                                  imageType == "application/msword" ||
                                  imageType ==
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                  imageType == "application/pdf"
                                ) {
                                  if (companyRegistrationFile.length < 3) {
                                    setCompanyRegistrationFile((companyRegistrationFile) => [
                                      ...companyRegistrationFile,
                                      {
                                        index: parseInt(Math.random() * 1000),
                                        file: e.target.files[0],
                                      },
                                    ]);
                                  } else {
                                    dispatch(
                                      changeSnackbar({
                                        isOpen: true,
                                        message: t("manageStore_company_registration"),
                                        state: "error",
                                      })
                                    );
                                  }
                                } else {
                                  dispatch(
                                    changeSnackbar({
                                      isOpen: true,
                                      message: t("manageStore_PleaseValidDocumnets"),
                                      state: "error",
                                    })
                                  );
                                }
                              }}
                            />

                            {!companyRegistrationErr && companyRegistrationFile.length == 0 ? (
                              <span className="errorspan">
                                {t("manageStore_company_registration")}
                              </span>
                            ) : null}

                            {!companyRegistrationFile.length >= 3 ? (
                              <>
                                <span className="errorspan">
                                  {t("manageStore_cannot_upload_more_than_3")}
                                </span>
                              </>
                            ) : null}
                          </label>
                        ) : null}

                        {storeOption == "edit"
                          ? companyRegistrationFile.map((obj, index) => {
                              return (
                                <div key={index} className="uploaded-text-block" style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                  <span
                                  onClick={() => {
                                    handleDocumentPopup(obj.image);
                                  }}
                                  className="p2" style={{whiteSpace: 'nowrap'}}>{obj.document}</span>
                                  {storeOption != "edit" ? (
                                    <img
                                      src={closeGray}
                                      className="close-icon"
                                      onClick={(e) => removeFile(obj.index)}
                                      alt=""
                                    />
                                  ) : null}
                                </div>
                              );
                            })
                          : companyRegistrationFile.map((obj, index) => {
                              return (
                                <div key={index} className="uploaded-text-block" style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                  <span className="p2" style={{whiteSpace: 'nowrap'}}>{obj.file?.name}</span>
                                  <img
                                    src={closeGray}
                                    className="close-icon"
                                    onClick={(e) => {
                                      removeFile(obj.index);
                                      document.getElementById(
                                        "companyRegistrationDocumentsID"
                                      ).value = null;
                                    }}
                                    alt=""
                                  />
                                </div>
                              );
                            })}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </TabPanel>{" "}
            <ManageWorkingHours
              value={value}
              dayOpenClose={dayOpenClose}
              changeWorkingHours={(workingHours) => setDayOpenClose(workingHours)}
              open247 = {open247}
              handleOpen247={(value) => setOpen247(value) }
            />
            <ManageHoliday
              value={value}
              formData={formData}
              setValue={setValue}
              showHoliday={showHoliday}
              setShowHoliday={setShowHoliday}
              // data={holidayData}
              data={preloadHolidays}
              setData={setHolidayData}
              saveData={saveData}
              setSaveData={setSaveData}
              setGetTheStore={setGetTheStore}
              getTheStore={getTheStore}
              getStoreDetails={getStoreDetails}
              skipHoliday={saveHolidays}
            />
            <AddPayoutDetails
              value={value}
              formData={formData}
              accountNo={accountNo}
              setAccountNo={setAccountNo}
              name={name}
              setNameErr={setNameErr}
              nameErr={nameErr}
              setName={setName}
              iban={iban}
              setIban={setIban}
              swiftCode={swiftCode}
              setSwiftCode={setSwiftCode}
              accountNoErr={accountNoErr}
              setAccountNoErr={setAccountNoErr}
              ibanErr={ibanErr}
              setIbanErr={setIbanErr}
              switchCodeErr={switchCodeErr}
              setSwiftCodeErr={setSwiftCodeErr}
            />

            <GeneralSetting
              value={value}
              marketPlaceMerchant={marketPlaceMerchant}
              setMarketPlaceMerchant={setMarketPlaceMerchant}
              cuisineList={cuisineList}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              bannerImage={bannerImage}
              uploadBlackIcon={uploadBlackIcon}
              storeOption={storeOption}
              deliveryCharge={deliveryCharge}
              setDeliveryCharge={setDeliveryCharge}
              changeBannerImage={(fileBase, fileImage) => {
                bannerImage = {
                  image: fileImage,
                  file: fileBase,
                  isBase64: true
                }
                setBannerImage(bannerImage)
              }}
            />

          </Grid>
          <Grid item xs={12}>
            <div className="text-right-left">
              <Button
                variant="contained"
                size="large"
                sx={{
                  width: "150px",
                  marginTop: "16px",
                }}
                onClick={() => {
                  if (value == 0) handleSubmit();
                  else if (value == 1) submit2();
                  else if (value == 2) saveHolidays();
                  else if (value == 3) submitPayout();
                  else if (value == 4) submitGeneral();
                }}
              >
                {value === 4 ? t("manageStore_submit") : t("manageStore_next")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>

      <Dialog open={openAddHoliday} onClose={handleClose} className="main-modal">
        <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
      </Dialog>
      <Thankyou
        isOpen={openThankYou}
        handleClose={() => {
          setOpenThankYou(false);
        }}
      />
    </div>
  );
};

export default StoreDetail;
