import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  Autocomplete,
  createFilterOptions,
  MenuItem,
  TextField,
  InputLabel,
  Select,
} from "@mui/material";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import globalRequest from "../../global-modules/globalRequest";
import arrowLeftBlack from "../../assets/images/structure/arrow-left-black.svg";
import uploadBlackIcon from "../../assets/images/structure/upload-black-icon.svg";
import cancelRed from "../../assets/images/structure/cancel-red.svg";
import closeGray from "../../assets/images/structure/close-gray.svg";
import { currentLocalData } from "../../redux/reducers/localData";
import readImageFile from "../../global-modules/readImageFile";
import { changeLoader } from "../../redux/reducers/loader";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import { changePage } from "../../redux/reducers/page";
import { API, ROLE, PAGES, COUNTRY_CODE } from "../../Enum";
import { API_URL } from "../../BaseUrl";
import {
  currentStoreData,
  currentStoreOption
} from "../../redux/reducers/editStoreData";
import { getdataByKey, imagesToBase64 } from "../../Helpers/Helpers";

const formData = new FormData();

const MyStoreDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const storeOption = useSelector(currentStoreOption);
  const storeData = useSelector(currentStoreData);
  const localData = useSelector(currentLocalData);
  let [cuisineList, setCuisineList] = useState([]);
  let [storeIsApproved, setStoreIsApproved] = useState(false);
  let [buildingAddress, setBuildingAddress] = useState("");
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);
  const [streetNo, setStreetNo] = useState("");
  const [buildingNo, setBuildingNo] = useState("");
  const [zoneNo, setZoneNo] = useState("");
  let [apartmentNo, setApartmentNo] = useState("");
  let [floorNo, setFloorNo] = useState("");
  let [googleAddress, setGoogleAddress] = useState([]);
  const [country, setCountry] = useState("Qatar");
  const [countryErr, setCountryErr] = useState(true);
  let [showMaps, setShowMaps] = useState(false);
  let [storeDetails, setStoreDetails] = useState(null);
  
  const [businessTypeList, setBusinessTypeList] = useState([]);
  let [bType, setBType] = useState("");
  const [bTypeErr, setBTypeErr] = useState(true);
  const [bName, setBName] = useState("");
  const [bNameErr, setBNameErr] = useState(true);
  const [bNameArabic, setBNameArabic] = useState("");
  const [bNameArabicErr, setBNameArabicErr] = useState(true);
  const [businessLogo, setBusinessLogo] = useState(null);
  const [businessLogoErr, setBusinessLogoErr] = useState(true);
  const [companyRegistrationFile, setCompanyRegistrationFile] = useState([]);
  const [companyRegistrationErr, setCompanyRegistrationErr] = useState(true);
  
  const [ownerName, setOwnerName] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [ownerMobile, setOwnerMobile] = useState("");
  
  let [bannerImage, setBannerImage] = useState({
    image: null,
    file: null,
    isBase64: false,
  });
  let [marketPlaceMerchant, setMarketPlaceMerchant] = useState("no");
  let [deliveryCharge, setDeliveryCharge] = useState(0);
  let [selectedOption, setSelectedOption] = useState([]);

  let [isRejected, setIsRejected] = useState(false);
  let [reason_of_reject, setReasonOfReject] = useState("");

  const handleOptionChange = (event, newValue) => setSelectedOption(newValue);

  const filterOptions = createFilterOptions({stringify: (option) => option.name,});

  const handleBannerUpload = async (file) => {
    const fileBase = await imagesToBase64([file]); 
    bannerImage = {
      image: file,
      file: fileBase,
      isBase64: true
    }
    setBannerImage(bannerImage)
  }

  const getAllBusinessType = async () => {
    const response = await globalRequest("get", API.BUSINESS_TYPE, {}, {}, true, true);
    setBusinessTypeList(response.data.businesstypes.filter((item) => item?.slug !== "grocery"));
    getStoreDetails();
  };

  const getCuisine = () => {
    const url = API.GET_CUISINE + `?businessTypeId=${bType}`;
    globalRequest("GET", url, {}, {}, true, true).then((res) => {
      cuisineList = [];
      res?.data?.data.filter(da => da.cuisines.length > 0).map((item) => {
        const cuisi = item.cuisines.map((cui) => {
          cui.categoryName = item.name;
          cui.cuisineName = cui.cuisines_locales[0].name;
          return cui
        });
        cuisineList.push(...cuisi);
      });
      setCuisineList(cuisineList);
      const itemIds = storeDetails?.cuisineId?.split(",").filter((item) => item !== '').map((item) => parseInt(item));
      selectedOption = cuisineList?.filter((item) => itemIds.includes(item.id));
      setSelectedOption(selectedOption);
    }).catch((e) => {});
  };

  const getStoreDetails = async () => {
    const response = await globalRequest('get', `${API.GET_STORE_SPECIFIC(storeData)}`, {}, {}, true, true);
    const store = response.data.store;
    storeDetails = store
    setStoreDetails(storeDetails);
    bType = store.businessTypeId;
    setBType(bType);
    storeIsApproved = store.is_approved? true: false;
    reason_of_reject = store.reason_of_reject;
    setReasonOfReject(reason_of_reject);
    isRejected = store?.is_approved === false? true: false;
    setIsRejected(isRejected)
    setStoreIsApproved(storeIsApproved)
    const brand = response.data.brand;
    setBName(getdataByKey(store.stores_locales, 'business_name', 'en'));
    setBNameArabic(getdataByKey(store.stores_locales, 'business_name', 'ar'));
    setBusinessLogo(store.business_logo);
    let companyRegistrationFile = [];
    store.company_registration_ides.forEach((val) => {
      const document = val?.image.split('/')[val?.image.split('/').length -1];
      companyRegistrationFile.push({document: document, image: val?.image});
    });
    setCompanyRegistrationFile(companyRegistrationFile);
    setBannerImage({
      image: null,
      file: store.banner_image,
      isBase64: false,
    });
    setOwnerName(brand?.user?.full_name)
    setOwnerEmail(brand?.user?.email)
    setOwnerMobile(brand?.user?.contact_number)
    setMarketPlaceMerchant(store?.market_place === 1? 'yes': 'no');
    setDeliveryCharge(store?.delivery_charges);
    setFloorNo(store?.street_number);
    setZoneNo(store?.zone_number);
    setBuildingNo(store?.building_number);
    setApartmentNo(store?.apartment_number);
    setBuildingAddress(store?.business_address);
    const itemIds = store?.cuisineId?.split(",").filter((item) => item !== '').map((item) => parseInt(item));
    selectedOption = cuisineList?.filter((item) => itemIds.includes(item.id));
    setSelectedOption(selectedOption);
    getCuisine();
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(changeLoader(true));
		let isValid = true;
    if (bName.trim() === "") {
      setBNameErr(t('signup_business_name'));
      isValid = false;
    }
    if (bNameArabic.trim() === "") {
      setBNameArabicErr(t('signup_business_name'));
      isValid = false;
    }
    if (!buildingAddress) {
      setBuildingAddressErr(t('signup_enterBusinessAddress'));
      isValid = false;
    }
    if (!businessLogo && businessLogo === null) {
      setBusinessLogoErr(t('signup_business_logo'));
      isValid = false;
    }
    if (companyRegistrationFile.length === 0) {
      setCompanyRegistrationErr(t('signup_company_registration'));
      isValid = false;
    }
		
		if (isValid) {
			const formData = new FormData();
			formData.append("market_place", marketPlaceMerchant === 'yes' ? 1 : 0);
			formData.append("delivery_charges", deliveryCharge);
			formData.append("cuisineId", selectedOption.map((item) => item.id).join(","));
      formData.append("banner_image", bannerImage.isBase64? bannerImage.image: bannerImage.file);
			const response = await globalRequest("post", API.UPDATE_GENERAL_SETTINGS(storeData), formData, {}, true, true);
      if (response.data.ack === 1) {
        dispatch(changeLoader(false));
        dispatch(changeSnackbar({ 
          isOpen: true,
          state: "success",
          message: response.data.msg
        }));
        dispatch(changePage(PAGES.MANAGE_STORE));
      } else {
        dispatch(changeSnackbar({ 
          isOpen: false,
          state: "error",
          message: response.data.msg
        }));
      }
		}
		dispatch(changeLoader(false));
  };

  useEffect(() => {
    getAllBusinessType();
  }, [storeOption]);

  return (
    <div className="main-mid-wrapper">
      <div className="store-detail-wrapper  max-w-810">
        <form onSubmit={handleSubmit}>
          <Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={"20px"}
          >
            <div className="store-detail-head" style={{ marginBottom: "0px" }}>
              <img
                src={arrowLeftBlack} alt=""
                className="rotate180"
                onClick={() => { dispatch(changePage(PAGES.MANAGE_STORE)); }}
              />
              <h3 className="h3" style={{ marginRight: "0px" }}>
                {t("manageStore_storeDetail")}
              </h3>
            </div>
          </Boxed>

          {isRejected && <Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"start"}
            mb={"20px"}
            className="table-switch-box"
          >
            <div className="relative mx-3">
              <h6 className="h6">Store Rejected</h6>
              <span className="p3">{reason_of_reject}</span>
            </div>
          </Boxed>}

          <div className="card-white">
            <div className="business-detail-wrap">
              <h6 className="h6">{t("manageStore_StoreInformation")}</h6>
              <p className="p3 mb-0">
                {t("manageStore_TheBusinessEmailAddressAndPhoneNumberWillBeUsedForLoginPurpose")}
              </p>
              <div className="signup-right max-w-548">
                <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        required
                        disabled={storeOption == "edit" ? true : false}
                        className={
                          storeOption == "edit" ? "aroow-hide-disabled" : ""
                        }
                        label={t("manageStore_storeNameEnglish")}
                        value={bName}
                        inputProps={{ maxLength: 256 }}
                        onChange={(e) => {
                          setBName(e.target.value);
                        }}
                      />
                      {!bNameErr && !bName ? (
                        <span className="errorspan">
                          {t("manageStore_business_name")}
                        </span>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        disabled={storeOption == "edit" ? true : false}
                        className={
                          storeOption == "edit" ? "aroow-hide-disabled" : ""
                        }
                        value={bNameArabic}
                        onChange={(e) => {
                          setBNameArabic(e.target.value);
                        }}
                        required={true}
                        label={t("manageStore_storeNameArebic")}
                        inputProps={{ maxLength: 256 }}
                      />
                      {!bNameArabicErr && !bNameArabic ? (
                        <span className="errorspan">
                          {t("manageStore_business_name")}
                        </span>
                      ) : null}
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <FormControl fullWidth required>
                        <InputLabel id="demo-simple-select-label">
                          {t("manageStore_storeType")}
                        </InputLabel>
                        <Select
                          disabled={storeOption == "edit" ? true : false}
                          className={
                            storeOption == "edit" ? "aroow-hide-disabled" : ""
                          }
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={t("manageStore_storeType")}
                          defaultValue=""
                          value={bType}
                          onChange={(e) => {
                            setBType(e.target.value);
                          }}
                        >
                          {businessTypeList.map((obj, index) => {
                            if (obj.name != "Grocery")
                              return (
                                <MenuItem key={index} value={obj.id}>
                                  {obj.business_type_locales[0].name}
                                </MenuItem>
                              );
                          })}
                        </Select>
                        {!bTypeErr && !bType ? (
                          <span className="errorspan">
                            {t("manageStore_business_type")}
                          </span>
                        ) : null}
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item xs={12} mb={"16px"}>
                    <p className="p1 mb-0">{t("manageStore_storeAddress")}</p>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="form-group">
                      <TextField
                        required
                        disabled={storeOption == "edit" ? true : false}
                        className={
                          storeOption == "edit" ? "aroow-hide-disabled" : ""
                        }
                        value={buildingAddress}
                        label={t("manageStore_storeAddress")}
                        onChange={(e) => {
                          let m = e.target.value.replace(/\D/g, "");
                          setBuildingAddress(m);
                        }}
                      />
                    </div>
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        disabled={storeOption == "edit" ? true : false}
                        className={
                          storeOption == "edit" ? "aroow-hide-disabled" : ""
                        }
                        value={apartmentNo}
                        label={t("manageStore_ApartmentNumber")}
                        onChange={(e) => {
                          let m = e.target.value.replace(/\D/g, "");
                          setApartmentNo(m);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        disabled={storeOption == "edit" ? true : false}
                        className={
                          storeOption == "edit" ? "aroow-hide-disabled" : ""
                        }
                        label={t("manageStore_buildingNumber")}
                        value={buildingNo}
                        onChange={(e) => {
                          setBuildingNo(e.target.value);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        disabled={storeOption == "edit" ? true : false}
                        className={
                          storeOption == "edit" ? "aroow-hide-disabled" : ""
                        }
                        value={floorNo}
                        label={t("manageStore_FloorNumber")}
                        onChange={(e) => {
                          let m = e.target.value.replace(/\D/g, "");
                          setFloorNo(m);
                        }}
                      />
                    </div>
                  </Grid>
                  
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        required
                        disabled={storeOption == "edit" ? true : false}
                        className={
                          storeOption == "edit" ? "aroow-hide-disabled" : ""
                        }
                        inputProps={{ readOnly: true }}
                        label={t("manageStore_country")}
                        value={t("manageStore_countryName")}
                      />
                      {!countryErr && !country ? (
                        <span className="errorspan">
                          {t("manageStore_country")}
                        </span>
                      ) : null}
                    </div>
                  </Grid>

                  <Grid item xs={12} mb={"16px"}>
                    <p className="p1 mb-0">{t("manageStore_storeLogo")}</p>
                  </Grid>

                  <Grid item xs={12}>
                    {!storeOption ? (
                      <label className="img-upload-file">
                        <Boxed
                          display={"flex"}
                          alignItems={"center"}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={uploadBlackIcon}
                            className="upload-icon"
                            alt=""
                            style={{ marginRight: "24px" }}
                          />
                          <div className="">
                            <p className="p2">
                              {t("manageStore_uploadFile")}
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <p className="p3">{t("manageStore_fileFormat")}</p>
                          </div>
                        </Boxed>
                        <input
                          id="businessLogofordelete"
                          type="file"
                          onChange={(e) => {
                            if (e.target.files[0]) {
                              const imageType = e.target.files[0].type;
                              if (
                                imageType == "image/png" ||
                                imageType == "image/jpeg" ||
                                imageType == "image/jpg"
                              )
                                setBusinessLogo(e.target.files[0]);
                              else {
                                dispatch(
                                  changeSnackbar({
                                    isOpen: true,
                                    message: t(
                                      "manageStore_PleaseUploadValidDocuments"
                                    ),
                                    state: "error",
                                  })
                                );
                              }
                            }
                          }}
                        />
                        {!businessLogoErr && businessLogo == null ? (
                          <span className="errorspan">
                            {t("manageStore_business_logo")}
                          </span>
                        ) : null}
                      </label>
                    ) : null}

                    <Boxed>
                      {businessLogo !== null ? (
                        <div className="uploaded-image-box">
                          <img
                            id={"imageFile"}
                            // style={{ height: "60px", width: "60px" }}
                            src={
                              storeOption ? `${API_URL}/${businessLogo}` : ""
                            }
                            className="store-logo"
                            alt=""
                          />
                          {storeOption != "edit" ? (
                            <img
                              src={cancelRed}
                              onClick={() => {
                                setBusinessLogo(null);
                                const value = (document.getElementById(
                                  "businessLogofordelete"
                                ).value = null);
                              }}
                              className="cancel-red"
                              alt=""
                            />
                          ) : null}
                        </div>
                      ) : null}
                    </Boxed>
                  </Grid>

                  <Grid item xs={12} mb={"16px"}>
                    <p className="p1">
                      {t("manageStore_companyRegistrationIDDocuments")}
                    </p>
                  </Grid>

                  <Grid item xs={12}>
                    {!storeOption ? (
                      <label className="img-upload-file">
                        <Boxed
                          display={"flex"}
                          alignItems={"center"}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={uploadBlackIcon}
                            className="upload-icon"
                            alt=""
                            style={{ marginRight: "24px" }}
                          />
                          <div className="">
                            <p className="p2">
                              {t("manageStore_uploadFile")}
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <p className="p3">
                              {t("manageStore_maxFiles")}{" "}
                              {t("manageStore_fileFormatDoc")}
                            </p>
                          </div>
                        </Boxed>
                        <input
                          id="companyRegistrationDocumentsID"
                          type="file"
                          accept=".png,.jpeg,.jpg,.png,.doc,.docx,.pdf"
                          onChange={(e) => {
                            const imageType = e.target.files[0].type;

                            if (
                              imageType == "image/png" ||
                              imageType == "image/jpeg" ||
                              imageType == "image/jpg" ||
                              imageType == "application/msword" ||
                              imageType ==
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              imageType == "application/pdf"
                            ) {
                              if (companyRegistrationFile.length < 3) {
                                setCompanyRegistrationFile(
                                  (companyRegistrationFile) => [
                                    ...companyRegistrationFile,
                                    {
                                      index: parseInt(Math.random() * 1000),
                                      file: e.target.files[0],
                                    },
                                  ]
                                );
                              } else {
                                dispatch(
                                  changeSnackbar({
                                    isOpen: true,
                                    message: t(
                                      "manageStore_company_registration"
                                    ),
                                    state: "error",
                                  })
                                );
                              }
                            } else {
                              dispatch(
                                changeSnackbar({
                                  isOpen: true,
                                  message: t(
                                    "manageStore_PleaseValidDocumnets"
                                  ),
                                  state: "error",
                                })
                              );
                            }
                          }}
                        />

                        {!companyRegistrationErr &&
                        companyRegistrationFile.length == 0 ? (
                          <span className="errorspan">
                            {t("manageStore_company_registration")}
                          </span>
                        ) : null}

                        {!companyRegistrationFile.length >= 3 ? (
                          <>
                            <span className="errorspan">
                              {t("manageStore_cannot_upload_more_than_3")}
                            </span>
                          </>
                        ) : null}
                      </label>
                    ) : null}

                    {storeOption == "edit"
                      ? companyRegistrationFile.map((obj, index) => {
                          return (
                            <div
                              key={index}
                              className="uploaded-text-block"
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <span
                                onClick={() => {
                                  handleDocumentPopup(obj.image);
                                }}
                                className="p2"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {obj.document}
                              </span>
                              {storeOption != "edit" ? (
                                <img
                                  src={closeGray}
                                  className="close-icon"
                                  onClick={(e) => removeFile(obj.index)}
                                  alt=""
                                />
                              ) : null}
                            </div>
                          );
                        })
                      : companyRegistrationFile.map((obj, index) => {
                          return (
                            <div
                              key={index}
                              className="uploaded-text-block"
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <span
                                className="p2"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {obj.file?.name}
                              </span>
                              <img
                                src={closeGray}
                                className="close-icon"
                                onClick={(e) => {
                                  removeFile(obj.index);
                                  document.getElementById(
                                    "companyRegistrationDocumentsID"
                                  ).value = null;
                                }}
                                alt=""
                              />
                            </div>
                          );
                        })}
                  </Grid>



                  <Grid item xs={12} mb={"16px"}>
                    <p className="p1">
                      {t("manageStore_GeneralSetting")}
                    </p>
                  </Grid>

                  <Grid item xs={12}
                    style={!storeIsApproved? {
                      pointerEvents: "none",
                      opacity: "0.4",
                    }: {
                      pointerEvents: "auto",
                      opacity: "1",
                    }}
                  >
                  <label className="img-upload-file">
                    <Boxed display={"flex"} alignItems={"center"} style={{ cursor: "pointer" }}>
                      <img src={uploadBlackIcon} className="upload-icon" alt="" style={{ marginRight: "24px" }} />
                      <div className="">
                        <p className="p2">{t("manageStore_uploadStoreBanner")}</p>
                        <p className="p3">{t("manageStore_fileFormat")}</p>
                      </div>
                    </Boxed>
                    <input id="businessLogofordelete" type="file" accept="image/*"
                      onChange={(e) => {
                        if (e.target.files[0]) {
                          const imageType = e.target.files[0].type;
                          if (imageType == "image/png" || imageType == "image/jpeg" || imageType == "image/jpg"){
                            handleBannerUpload(e.target.files[0]);
                          } else {
                            dispatch(
                              changeSnackbar({
                                isOpen: true,
                                message: t("manageStore_PleaseUploadValidBannerImage"),
                                state: "error",
                              })
                            );
                          }
                        }
                      }}
                    />
                  </label>
                  <Boxed>
                    {bannerImage.file !== null ? 
                    <div className="">
                      <img
                        style={{ width: "100%", objectFit: "contain", maxHeight: "250px", marginBottom: "24px" }}
                        src={bannerImage.isBase64 ? bannerImage.file : `${API_URL}${bannerImage.file}`}
                        id={"imageFile"} className="" alt=""
                      />
                    </div>: null}
                  </Boxed>
                </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="form-group">
                    <FormControl fullWidth required
                      disabled={true}
                    >
                      <InputLabel id="demo-simple-select-label"> {t("manageStore_marketPlaceMerchant")} </InputLabel>
                      <Select
                        
                        labelId="demo-simple-select-label" id="demo-simple-select"
                        label={t("manageStore_marketPlaceMerchant")} value={marketPlaceMerchant}
                        onChange={(e) => { setMarketPlaceMerchant(e.target.value) }}
                      >
                        <MenuItem value={'no'}>NO</MenuItem>
                        <MenuItem value={'yes'}>YES</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="form-group">
                    <FormControl fullWidth required
                      disabled={!storeIsApproved}>
                      <Autocomplete
                        multiple={true} value={selectedOption} onChange={handleOptionChange}
                        options={cuisineList} getOptionLabel={(option) => option.cuisineName}
                        groupBy={(option) => option.categoryName} filterOptions={filterOptions}
                        renderInput={(params) => <TextField {...params} label="Select cuisine" variant="outlined" />}
                        disableCloseOnSelect={true}
                        disabled={!storeIsApproved}
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="form-group">
                    <FormControl fullWidth required>
                    <TextField
                      value={deliveryCharge}
                      onChange={(e) => setDeliveryCharge(e.target.value)}
                      required
                      disabled={true}
                      label={'Delivery Charge'}
                    />
                    </FormControl>
                  </div>
                </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className="card-white">
            <div className="business-detail-wrap">
              <h6 className="h6">{t("manageStore_brandOwnerContactInformation")}</h6>
              <p className="p3">
                {t("manageStore_ThisIsJustForInformationPurpose")}
              </p>
              <div className="signup-right max-w-548">
                <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}>
                  <Grid item xs={12}>
                    <div className="form-group">
                      <TextField
                        value={ownerName}
                        disabled
                        label={t("manageStore_ownerFullName")}
                        inputProps={{ maxLength: 256 }}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        value={ownerMobile}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">{COUNTRY_CODE}</InputAdornment>
                          ),
                        }}
                        disabled
                        label={t("manageStore_ownerMobileNumber")}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        type="email"
                        value={ownerEmail}
                        disabled
                        label={t("manageStore_ownerOwnerAddress")}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          {storeIsApproved?<Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Button
              variant="contained"
              size="large"
              sx={{ width: "150px" }}
              type="submit"
              className="date-picker-btn"
            >
              {t("manageStore_save")}
            </Button>
          </Boxed>: null}
        </form>
      </div>
    </div>
  );
};

export default MyStoreDetail;
