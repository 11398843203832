import { TextField } from "@mui/material";
import searchBlack from "../assets/images/structure/search-black.svg";
import { useTranslation } from "react-i18next";
// Importing localised strings
const strings = require("../localisation_en.json");

const SearchInput = ({ onChange, valueSearch }) => {
  const { t } = useTranslation();
  return (
    <div className="searches-block">
      <img src={searchBlack} className="searches-icon" alt="" />
      <TextField
        onChange={onChange}
        type="search"
        value={valueSearch}
        id="outlined-basic"
        sx={{ width: "100%", backgroundColor: "#fff" }}
        className="searches-input"
        variant="outlined"
        placeholder={t('search')}
      />
    </div>
  );
};

export default SearchInput;
