import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Boxed } from "../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

import { changeSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import { API } from "../Enum";
import { currentLocalData } from "../redux/reducers/localData";
import modalClose from "../assets/images/structure/close-black.svg";
import globalRequest from "../global-modules/globalRequest";
import SearchSelectStore from "../shared/SearchSelectStore";


const InactiveCatListModal = ({
  isOpen = false,
  handleClose,
  category,
  // storeOptions,
  handleSuccess
}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tokenData = useSelector(currentLocalData);
  const [categoryList, setcategoryList] = useState([]);


  useEffect(() => {
    getInactiveCatStoreList();
  
  }, [category]);

  const getInactiveCatStoreList = async () => {

    const categoryId =category.id;
    const brand_id = tokenData.token.brand_id
    
    const response = await globalRequest(
      "get",
      API.CATEGORY_MANAGEMENT.INACTIVE_CAT_STORE_LIST(brand_id, categoryId), {}, {},true, true
    );

    if (response.data.ack === 1) {
      setcategoryList(response.data.data);
    }
  }


  const handleCategoryStatusChange = async (event, category) => {
    const status = event.target.checked ? "active" : "inactive";
    const data = {
      storeId: category.store.id,
      categoryId: category.categoryId,
      status: status,
    };

    console.log(category, "data");
    // return false

    const response = await globalRequest(
      "post",
      API.CATEGORY_MANAGEMENT.UPDATE_CATEGORY_HOURS,
      data,
      {},
      true,
      true
    );
    if (response.data.ack === 1) {
      dispatch(
        changeSnackbar({
          message: response.data.msg,
          state: "success",
          isOpen: true,
        })
      );
      getInactiveCatStoreList();
    } else {
      dispatch(
        changeSnackbar({
          message: response.data.msg,
          state: "error",
          isOpen: true,
        })
      );
    }
    getInactiveCatStoreList();
    handleSuccess()
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} className="main-modal maxwidth-430">
      <img src={modalClose} alt="Close" onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h4 className="h4" style={{ marginBottom: "10px", marginTop: "0px" }}>
          Inactive Category Store List
        </h4>
        <p className="p3" style={{ marginBottom: "16px" }}>
          Once deactivated, this category will be disabled automatically.
        </p>
        <div className="background-box" style={{ marginBottom: "24px" }}>
          <h6 className="h6" style={{ marginBottom: "8px", marginTop: "8px" }}>
            {category.name}
          </h6>
        </div>
        <div className="" style={tokenData.token.role === "vendor_users" ? { pointerEvents: "none", opacity: "0.4" } : {}}>
          <TableContainer component={Paper} sx={{ maxHeight: "calc(100vh - 265px)" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="short-div">
                      Store Name
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="short-div">
                      Action
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryList.map((category, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div className="short-div">
                        {console.log(category.store)}
                        <strong>  
                          {category.store.stores_locales[0].business_name}
                        </strong> <br/>
                        {category.store.business_address}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                      <Switch
                              className="mx-1"
                              checked={
                                category.status === "active" ? true : false
                              }
                              onChange={(e) => {
                                handleCategoryStatusChange(e, category);
                              }}
                            />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
    </Dialog>
  );
};


export default InactiveCatListModal;
