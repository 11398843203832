import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import "./header2.scss";
import headerLogo from "../../../assets/images/logo/header-logo.svg";
import headerLogoAr from "../../../assets/images/logo/header-logo.svg";
import englishLangIcon from "../../../assets/images/language/english.svg";
import arabicLangIcon from "../../../assets/images/language/arabic.svg";
import dawnArrowBlack from "../../../assets/images/structure/dawn-arrow-black.svg";
import storeLogo1Small from "../../../assets/images/products/store-logo1-small.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@mui/icons-material/Add";
import logOutIcon from "../../../assets/images/structure/logout-icon.svg";
import profileIcon from "../../../assets/images/structure/account_circle.svg";
import LockIcon from "../../../assets/images/structure/lock-icon.svg";
import translateIcon from "../../../assets/images/structure/translate-icon.svg";
import { Boxed } from "../../../Styles-Elements/Box";
import addDeleteGetLocalStorage from "../../../global-modules/addDeleteGetLocalStorage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API, PAGES, STORAGE } from "../../../Enum";
import localData, {
  changeLocalData,
  currentLocalData,
} from "../../../redux/reducers/localData";
import { changeStoreOption } from "../../../redux/reducers/editStoreData";
import { changeHolidays } from "../../../redux/reducers/editStoreData";
import { changeLoader } from "../../../redux/reducers/loader";
import globalRequest from "../../../global-modules/globalRequest";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { API_URL } from "../../../BaseUrl";
import { changePage } from "../../../redux/reducers/page";
// import getAddressUsingLatLng from "../../../global-modules/getAddressUsingLatLng";
import { currentRecallGetStore } from "../../../redux/reducers/recallGetStore";
import { useTranslation } from "react-i18next";
import ChangePassword from "../../../dialog/ChangePassword";
import { Link } from "react-router-dom";
import defaultImage from "../../../assets/images/structure/default.jpeg";
import { currentBaladiInfo } from "../../../redux/reducers/baladiInfo";

const label = { inputProps: { "aria-label": "Switch demo" } };

const Header2 = () => {
  const { t, i18n } = useTranslation();
  const languages = [
    {
      name: "English",
      code: "en",
      icon: englishLangIcon,
    },
    {
      name: "عربي",
      code: "ar",
      icon: arabicLangIcon,
    },
  ];

  const [lang, setLang] = React.useState(false);
  const [selectedLang, setSelectedLang] = React.useState("en");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElLogout, setAnchorElLogout] = useState(null);
  const openLogout = Boolean(anchorElLogout);

  const handleClickLogout = (event) => {
    setAnchorElLogout(event.currentTarget);
  };
  const handleCloseLogout = () => {
    setAnchorElLogout(null);
  };

  const openProfileMenu = Boolean(anchorEl);
  const handleClickProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenData = useSelector(currentLocalData);
  const baladiInfo = useSelector(currentBaladiInfo);
  let userStore = tokenData?.token?.store;
  const token = tokenData?.token;
  const [storeDataSelected, setStoreDataSelected] = useState(tokenData?.token?.store);
  const [storeData, setStoreData] = useState([]);
  const [mainAddress, setMainAddress] = useState("");
  const recallStore = useSelector(currentRecallGetStore);
  let [onlineOffline, setOnlineOffline] = useState(
    token?.store?.online === "online" || token?.store?.online === true
      ? true
      : false
  );
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const getStoreData = () => {
    let params = {
      params: {
        limit: 20,
        page: 1,
        sort_by: "id",
        order_by: "desc",
        search: "",
      },
    };

    dispatch(changeLoader(true));
    globalRequest("GET", API.GET_ALL_MY_STORES, {}, params, true, true)
      .then((res) => {
        const data = res?.data;
        if (data.ack == 1) {
          setStoreData(data?.stores);
          setStoreDataSelected(data?.stores.find((obj) => obj.id == userStore?.id));
        }
        dispatch(changeLoader(false));
      })
      .catch((e) => {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
      });
  };

  const refreshToken = () => {
    globalRequest("get", API.REFRESH_TOKEN, {}, {}, true, true)
      .then(({ data, status }) => {
        if (data.ack == 1) {
          localStorage.removeItem(STORAGE.TOKEN);
          addDeleteGetLocalStorage(STORAGE.TOKEN, data?.data, "add", "single");
          dispatch(changeLocalData());
        }
      })
      .catch((e) => {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
      });
  };

  const handleStatusChange = (e) => {
    setOnlineOffline(e.target.checked);
    globalRequest(
      "post",
      API.CHANGE_STORE_STATUS,
      { status: e.target.checked ? "online" : "offline" },
      {},
      true,
      true
    )
      .then(({ data, status }) => {
        if (status == 401) {
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: "error",
              message: t("somethingWentWrong"),
            })
          );
        } else {
          if (data.ack == 1) {
            refreshToken();
          }else{
            dispatch(
              changeSnackbar({
                isOpen: true,
                state: "error",
                message: data.msg,
              })
            );
            setOnlineOffline(false);
          }
        }
      })
      .catch((e) => {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
      });
  };

  useEffect(() => {
    getStoreData();
  }, [recallStore]);

  const changeStore = (storeId) => {
    dispatch(changeLoader(true));
    globalRequest("get", API.CHANGE_VENDOR_STORE(storeId), {}, true, true)
      .then((res) => {
        let data = res?.data;
        if (data?.ack == 1) {
          let token = data?.data;
          handleCloseLogout();
          addDeleteGetLocalStorage(STORAGE.TOKEN, token, "add", "single");
          dispatch(changeLocalData());
          window.location.reload();
        }
        let message =
          data?.ack == 1
            ? "Store has changed successfully"
            : t("somethingWentWrong");
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: message,
            state: data?.ack == 1 ? "success" : "error",
          })
        );
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("somethingWentWrong"),
            state: "error",
          })
        );
      });
  };

  

  const getOnlineOfflineStatus = () => {
    globalRequest("GET", API.GET_ONLINE_OFFLINE_STATUS, {}, {}, true, true)
      .then(({ data, status }) => {
        if (status == 401) {
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: "error",
              message: t("somethingWentWrong"),
            })
          );
        } else {
          if (data.ack == 1) {
            onlineOffline = data?.online === true ? true : false;
            setOnlineOffline(onlineOffline);
          } else {
            dispatch(
              changeSnackbar({
                isOpen: true,
                state: "error",
                message: t("somethingWentWrong"),
              })
            );
          }
        }
      })
      .catch((e) => {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
      });
  };

  const changeLanguage = (lang) => {
    changeLoc(lang);
    window.location.reload();
  };

  const changeLoc = (lang) => {
    setSelectedLang(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    setLang(!lang);
    if (tokenData) {
      globalRequest('post', API.CHANGE_LANGUAGE, { language: lang }, {}, true, true)
    }
  }

  React.useEffect(() => {
    if (localStorage.getItem("i18nextLng")) {
      changeLoc(localStorage.getItem("i18nextLng"));
    } else {
      changeLoc("en");
    }
    getOnlineOfflineStatus();
  }, []);

  React.useEffect(() => {
    if (tokenData) {
      getOnlineOfflineStatus();
    }
  }, [tokenData]);

  return (
    <>
      <div className="header-wrapper2">
        <div className="container-body">
          <Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Link to="/">
              <img src={selectedLang === "ar"? headerLogoAr : headerLogo} className="header-logo" alt="" />
            </Link>

            <div className="right-wrap">
              {token.role === 'vendor_users'?
                <div
                className={`store-wrap ${token?.brand_id === baladiInfo.BALADI_BRAND_Id?'no-event-btn':''}`}
                id="shopName-button"
                aria-controls={openLogout ? "shopName-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openLogout ? "true" : undefined}
                onClick={handleClickLogout}
              >

                <div className="img-block">
                  <img
                    src={`${API_URL}${storeDataSelected?.business_logo}`}
                    className="logo-img"
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultImage
                    }}
                  />
                </div>
                <div className="content-block">
                  <h6 className="h6">{storeDataSelected?.stores_locales[0]?.business_name}</h6>
                  <p className="p3">{storeDataSelected?.business_address}</p>
                </div>
              </div>: null}
              {/* <Menu
                id="shopName-menu"
                anchorEl={anchorElLogout}
                open={openLogout}
                onClose={handleCloseLogout}
                MenuListProps={{
                  "aria-labelledby": "shopName-button",
                }}
              >
                {storeData.map((obj, ind) => {
                  const image = `${API_URL}${obj.business_logo}`;
                  let name = obj.stores_locales[0]?.business_name;
                  let address = obj?.business_address;
                  let isApprove = obj?.is_approved;

                  return (
                    <MenuItem
                      onClick={() => {
                        if (userStore?.id != obj.id && isApprove) {
                          changeStore(obj.id);
                        }
                        handleCloseLogout();
                      }}
                      key={ind}
                      className={`store-wrap ${
                        !isApprove
                          ? "disabled"
                          : userStore?.id == obj.id
                          ? "active"
                          : ""
                      }`}
                    >
                      <div className="img-block">
                        <img src={image} className="logo-img" alt="" />
                      </div>
                      <div className="content-block">
                        <h6 className="h6">{name}</h6>
                        <p className="p4">{address}</p>
                      </div>
                    </MenuItem>
                  );
                })}
                <MenuItem
                  onClick={() => {
                    dispatch(changePage(PAGES.ADD_STORE));
                    dispatch(changeStoreOption(null));
                    dispatch(changeHolidays(null));
                    handleCloseLogout();
                  }}
                  className="store-wrap"
                >
                  <AddIcon />
                  <div className="content-block">
                    <h6 className="h6">{t('header_footer_AddNewStore')} </h6>
                  </div>
                </MenuItem>
              </Menu> */}

              {token.role === 'vendor_users'?<div className="online-switch-wrap">
                <Switch
                  {...label}
                  checked={onlineOffline}
                  onChange={handleStatusChange}
                />
                <span className="p3">
                  {onlineOffline
                    ? t("header_footer_online")
                    : t("header_footer_offline")}
                </span>
              </div>: null}


              <div className="profile-drop-parent">
                <Button
                  id="Logout-menu"
                  aria-controls={openProfileMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openProfileMenu ? "true" : undefined}
                  onClick={handleClickProfileMenu}
                >
                  <img src={profileIcon} alt="..." className="profile-icon"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultImage
                    }}
                  />
                </Button>
                <Menu
                  id="Logout-menu"
                  anchorEl={anchorEl}
                  open={openProfileMenu}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "Logout-button",
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    <img src={profileIcon} alt="..." className="icon24" />
                    <div className="relative">
                      <p className="p2">{token?.full_name}</p>
                      <p className="p4">{token?.email}</p>
                    </div>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpenChangePassword(true);
                      handleClose();
                    }}
                  >
                    <img src={LockIcon} alt=".." className="icon24" />
                    {t("header_footer_ChangePassword")}
                  </MenuItem>
                  <MenuItem>
                    <img src={translateIcon} alt=".." className="icon24" />
                    {t("header_footer_PreferredLanguage")}
                    <ArrowForwardIosIcon
                      fontSize="14px"
                      style={{ margin: "0px 12px" }}
                    />
                    <div className="language-menu">
                      {languages.map((language, index) => (
                        <div
                          className="language-menu-item"
                          key={`language-${index}`}
                          onClick={() => {
                            changeLanguage(language?.code);
                            handleClose();
                          }}
                        >
                          <img
                            src={language?.icon}
                            className="language-img"
                            alt=""
                          />
                          <span className="language-text">
                            {language?.name}
                          </span>
                        </div>
                      ))}
                      {/* <div className="language-menu-item" onClick={handleClose}>
                        <img src={arabicLangIcon} className="language-img" alt="" />
                        <span className="language-text">Qatar</span>
                      </div> */}
                    </div>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "delete");
                      dispatch(changeLocalData());
                      navigate("/");
                    }}
                  >
                    <img src={logOutIcon} alt=".." className="icon24" />
                    {t("header_footer_Logout")}
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </Boxed>
        </div>
      </div>
      {openChangePassword ? (
        <ChangePassword
          handleClose={() => {
            setOpenChangePassword(false);
          }}
        />
      ) : null}
    </>
  );
};

export default Header2;
