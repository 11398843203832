import {useState, useEffect} from "react";
import "./TermsConditions.scss";
import { useTranslation } from "react-i18next";
import globalRequest from "../../global-modules/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { API } from "../../Enum";
import { useSearchParams } from "react-router-dom";

const TermsConditionsComponent = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let [terms, setTnc] = useState(null);

  const getStatic = () => {
    dispatch(changeLoader(true));
    if (searchParams?.get('lang')) {
      localStorage.setItem('i18nextLng', searchParams?.get('lang'))
    }
    globalRequest( "get", API.STATIC_PAGES.GET_STATIC_PAGES('termsofservice'), {}, {}, true, true).then(({data, status}) => {
      if (status === 400) {
        dispatch(changeLoader(false));
      } else {

        terms = data?.cms;
        setTnc(terms);
        dispatch(changeLoader(false));
      }
    }).catch((error) => {
      dispatch(changeLoader(false));
    })
  }

  useEffect(() => {
    getStatic();
  }, []);

  return (
      <div className="container-body">
        <div className="" dangerouslySetInnerHTML={{__html: terms?.cms_locales[0].description,}}></div>
        {/* <div className="">

          <h4 className="h4">{t('tnc_overview')}</h4>
          <p className="p1">{t('tnc_overview_content')}</p>
          <p className="p1">{t('tnc_overview_content_line2')}</p>

          <h4 className="h4">{t('tnc_GeneralConditions')}</h4>
          <p className="p1">{t('tnc_GeneralConditions_content')}</p>

          <h4 className="h4">{t('tnc_intellectualPropertyRights')}</h4>
          <p className="p1">{t('tnc_intellectualPropertyRights_content')}</p>
          <p className="p1">{t('tnc_intellectualPropertyRights_contentLine2')}</p>

          <h4 className="h4">{t('tnc_UserRepresentation')}</h4>
          <p className="p1">{t('tnc_UserRepresentation_line2')}</p>
          <ul>
            <li>{t('tnc_UserRepresentation_pont1')}</li>
            <li>{t('tnc_UserRepresentation_pont2')}</li>
            <li>{t('tnc_UserRepresentation_pont3')}</li>
            <li>{t('tnc_UserRepresentation_pont4')}</li>
          </ul>

          <h4 className="h4">{t('tnc_UserRegistration')}</h4>
          <p className="p1">{t('tnc_UserRegistrationContent')}</p>

          <h4 className="h4">{t('tnc_RegistrationEligibilityRequirements')}</h4>
          <ul>
            <li>{t('tnc_RegistrationEligibilityRequirementsPoint1')}</li>
            <li>{t('tnc_RegistrationEligibilityRequirementsPoint2')}</li>
            <li>{t('tnc_RegistrationEligibilityRequirementsPoint3')}</li>
            <li>{t('tnc_RegistrationEligibilityRequirementsPoint4')}</li>
            <li>{t('tnc_RegistrationEligibilityRequirementsPoint5')}</li>
            <li>{t('tnc_RegistrationEligibilityRequirementsPoint6')}</li>
          </ul>

          <h4 className="h4">{t('tnc_ModificationsToTheServiceAndPrices')}</h4>
          <p className="p1">{t('tnc_ModificationsToTheServiceAndPricesContent')}</p>

          <h4 className="h4">{t('tnc_PurchasePayment')}</h4>
          <p className="p1">{t('tnc_PurchasePaymentContent')}</p>
          <ul>
            <li>{t('tnc_PurchasePaymentPoint1')}</li>
            <li>{t('tnc_PurchasePaymentPoint2')}</li>
            <li>{t('tnc_PurchasePaymentPoint3')}</li>
            <li>{t('tnc_PurchasePaymentPoint4')}</li>
            <li>{t('tnc_PurchasePaymentPoint5')}</li>
          </ul>
          <p className="p1">{t('tnc_PurchasePaymentLine2')}</p>

          <h4 className="h4">{t('tnc_FreeShippingAndExpressDelivery')}</h4>
          <p className="p1">{t('tnc_FreeShippingAndExpressDeliveryContent')}</p>

          <h4 className="h4">{t('tnc_UserData')}</h4>
          <p className="p1">{t('tnc_UserDataContent')}</p>
          <p className="p1">{t('tnc_UserDataContent2')}</p>

          <h4 className="h4">{t('tnc_QATAR_EGovernmentConsumerRightsProtection')}</h4>
          <p className="p1">{t('tnc_QATAR_EGovernmentConsumerRightsProtectionContent')}</p>

          <h4 className="h4">{t('tnc_Corrections')}</h4>
          <ul>
            <li>{t('tnc_CorrectionsPoint1')}</li>
            <li>{t('tnc_CorrectionsPoint2')}</li>
          </ul>

          <h4 className="h4">{t('tnc_PrivacyNotice')}</h4>
          <p className="p1">{t('tnc_PrivacyNoticeContent')}</p>

          <h4 className="h4">{t('tnc_ModificationAndInterruption')}</h4>
          <p className="p1">{t('tnc_ModificationAndInterruptionContent')}</p>

          <h4 className="h4">{t('tnc_GoverningLaw')}</h4>
          <p className="p1">{t('tnc_GoverningLawContent')}</p>

          <h4 className="h4">{t('tnc_ReturnPolicy')}</h4>
          <p className="p1">{t('tnc_ReturnPolicyContent')}</p>

          <h4 className="h4">{t('tnc_ProhibitedActivities')}</h4>
          <p className="p1">{t('tnc_ProhibitedActivitiesContent')}</p>

          <h4 className="h4">{t('tnc_RulesRegulations')}</h4>
          <ol>
            <li>{t('tnc_RulesRegulationsPoint1')}</li>
            <li>{t('tnc_RulesRegulationsPoint2')}</li>
            <li>{t('tnc_RulesRegulationsPoint3')}</li>
            <li>{t('tnc_RulesRegulationsPoint4')}</li>
            <li>{t('tnc_RulesRegulationsPoint5')}</li>
          </ol>

          <h4 className="h4">{t('tnc_TermsTermination')}</h4>
          <p className="p1">{t('tnc_TermsTerminationContent')}</p>
          <p className="p1">{t('tnc_TermsTerminationContent2')}</p>

          <h4 className="h4">{t('tnc_GuidelineForReview')}</h4>
          <p className="p1">{t('tnc_GuidelineForReviewContent')}</p>
          <ol>
            <li>{t('tnc_GuidelineForReviewPoint1')}</li>
            <li>{t('tnc_GuidelineForReviewPoint2')}</li>
            <li>{t('tnc_GuidelineForReviewPoint3')}</li>
            <li>{t('tnc_GuidelineForReviewPoint4')}</li>
            <li>{t('tnc_GuidelineForReviewPoint5')}</li>
          </ol>

          <h4 className="h4">{t('tnc_SiteManagement')}</h4>
          <p className="p1">{t('tnc_SiteManagementContent')}</p>
          <ol>
            <li>{t('tnc_SiteManagementPoint1')}</li>
            <li>{t('tnc_SiteManagementPoint2')}</li>
            <li>{t('tnc_SiteManagementPoint3')}</li>
          </ol>

        </div> */}
      </div>
  );
};

export default TermsConditionsComponent;
