/**
 * @description This function is used to get the address from the third party API.
 */

import axios from 'axios';
import Geocode from "react-geocode";
import {KEYS} from '../Enum';
Geocode.setApiKey(KEYS.GOOLGE_MAP_ADDRESS_KEY);

const GET_X_Y_FROM_DETAILS = (ZoneNumber, StreetNumber, BuildingNumber) => `https://services.gisqatar.org.qa/server/rest/services/Vector/QARS1/MapServer/0/query?f=json&where=ZONE_NO=${ZoneNumber} and STREET_NO=${StreetNumber} and BUILDING_NO=${BuildingNumber}&returnGeometry=true&spatialRel=esriSpatialRelIntersects&outFields=ZONE_NO,STREET_NO,BUILDING_NO,QARS,ELECTRICITY_NO,WATER_NO,QTEL_ID`;
const GET_LAT_LNG_FROM_X_Y = (x, y) => `https://epsg.io/trans?x=${x}&y=${y}&s_srs=2932&t_srs=4326`;

const getThirdPartyRequest = async (url) => {
  if (url !== undefined) {
    return new Promise((resolve, reject) => {
      axios.get(url).then(res => {
        resolve(res.data);
      }).catch(err => {
        resolve({
          ack: 0,
          msg: 'Something went wrong.'
        });
      });
    })
  }
}

const getAdressFromLatLng = async (lat, lng) => {
  const response = await Geocode.fromLatLng(lat, lng);
  const address = response.results[0].formatted_address;
  return address;
};

export const getAddress = async (zoneNumber, streetNumber, buildingNumber) => {
	const response = await getThirdPartyRequest(GET_X_Y_FROM_DETAILS(zoneNumber, streetNumber, buildingNumber));
	if (response) {
		if (response.features.length > 0) {
			const res = await getThirdPartyRequest(GET_LAT_LNG_FROM_X_Y(response.features[0].geometry.x, response.features[0].geometry.y));
      const latitude = res.y;
      const longitude = res.x;
			const buildingAddress = await getAdressFromLatLng(latitude, longitude);
			return {
				ack: 1,
				msg: 'Address found.',
				data: {
					latitude,
					longitude,
					buildingAddress
				}
			};
		} else {
			return {
				ack: 0,
				msg: 'No Address found.',
				data: {}
			}
		}
	} else {
		return {
			ack: 0,
			msg: 'Something went wrong.',
			data: {}
		}
	}
};

export const getAddressFromOpenStreetMap = async (address) => {
	const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${address} qatar&format=json`);
	return response;
};