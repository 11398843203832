/** @format */
import {useState, useEffect} from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./Faq.scss";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import { useTranslation } from "react-i18next";
import globalRequest from "../../global-modules/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { API } from "../../Enum";

const Faq = () => {
  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let [faqs, setFaqs] = useState([]);

  const getFaqs = () => {
    dispatch(changeLoader(true));
    globalRequest( "get", API.STATIC_PAGES.GET_FAQS, {}, {}, true, true).then(({data, status}) => {
      if (status === 400) {
        dispatch(changeLoader(false));
      } else {
        faqs = data?.cms;
        setFaqs(faqs);
        dispatch(changeLoader(false));
      }
    }).catch((error) => {
      dispatch(changeLoader(false));
    })
  }

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <>
      <Header />
      <div className="faq-condition-wrapper">
        <div className="container-body">
          <div className="faq-condition-head">
            <h3 className="h3">{t("faq_FrequentlyAskedQuestions")}</h3>
          </div>
          <div className="faq-condition-body">
            <Accordion activeIndex={0}>
              {faqs?.length > 0 ? faqs?.map((faq, index) =>
              <AccordionTab key={`faq-${index}`} header={faq?.cms_locales[0]?.question}>
                <p className="p2">{faq?.cms_locales[0]?.answer}</p>
              </AccordionTab>):null}
            </Accordion>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
