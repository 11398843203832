import { createSlice } from "@reduxjs/toolkit";

export const recallGetStore = createSlice({
  name: "recallGetStore",
  initialState: {
    value: false,
  },
  reducers: {
    changeRecallGetStore: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeRecallGetStore } = recallGetStore.actions;
export const currentRecallGetStore = (state) => state.recallGetStore.value;
export default recallGetStore.reducer;
