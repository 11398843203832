import * as React from 'react';
import { Button, Dialog, DialogContent, Grid, TextField } from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg";
import { API } from "../Enum";
import globalRequest from "../global-modules/globalRequest";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { changeSnackbar } from "../redux/reducers/snackbar";
import { currentLocalData } from "../redux/reducers/localData";
import { useTranslation } from "react-i18next";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SearchSelectStore from "../shared/SearchSelectStore";
import SearchSelectSingleStore from "../shared/SearchSelectSingleStore";
import {
  generateHours, convertTimeTo12Hour, getDataByKeyLocale
} from '../Helpers/Helpers'

const AddHoliday = ({
  isOpen = false,
  handleClose,
  updateValue,
  isEdit = false,
  storeId = null,
  holidayData = null
}) => {

  const { t } = useTranslation();
  const currentUserStoreData = useSelector(currentLocalData);
  const [date, setDate] = useState(null);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(true);
  const [dateErr, setDateErr] = useState(true);
  const [existingId, setExistingId] = useState(0);
  let [selectedStores, setSelectedStores] = useState([]);
  let [storeOptions, setStoreOptions] = useState([]);
  let [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const [personName, setPersonName] = React.useState([]);

  const getStoreOptions = async () => {
    dispatch(changeLoader(true));
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + currentUserStoreData.token.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    storeOptions = []
    if (response.data.ack === 1) {
      storeOptions = 
      response.data.stores.length > 1 ?
      [{ label: "All Stores", value: 0, secondLabel: "" }]: [];
      storeOptionArray = response.data.stores.filter(
        (store) => store.is_approved === null || store.is_approved === true
      ).map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address
        }
      });
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    storeOptions = [...storeOptions, ...storeOptionArray]
    setStoreOptions(storeOptions);

    if (currentUserStoreData.token.role === 'vendor_users') {
      setSelectedStores(storeOptionArray.filter((store) => store.value === currentUserStoreData.token.store.id));
    } else {
      if (storeId === undefined || storeId === null) {
        setSelectedStores(storeOptions);
      } else {
        setSelectedStores(storeOptions.filter((store) => store.value === storeId));
      }
    }


    dispatch(changeLoader(false));
  };

  const ifZero = (number) => {
    return isNaN(number) ? "00" : number < 10 ? `0${number}` : number;
  };

  useEffect(() => {
    if (isEdit) {
      setName(holidayData.holidayName);
      setDate(new Date(holidayData.date));
    } else {
      setName("");
      setDate(null);
    }
  }, [isEdit]);

  const submit = (e) => {
    e.preventDefault();
    isSubmit = true;
    setIsSubmit(isSubmit);
    if (selectedStores.length === 0) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          state: "error",
          message: t('manageStore_selectStore'),
        })
      );
      isSubmit = false;
      setIsSubmit(isSubmit);
      return;
    }
    if (name && date) {
      dispatch(changeLoader(true));
      let da = new Date(date);
      let dates = `${da.getFullYear()}-${ifZero(da.getMonth() + 1)}-${ifZero(da.getDate())}`;
      let url = isEdit ? API.UPDATE_WORKING_HOLIDAY(holidayData.id) : API.MANAGE_HOLIDAYS.ADD_HOLIDAYS_BY_STORE;
      globalRequest("post", url, {
        holidayName: name,
        date: dates,
        storeId: selectedStores.map((store) => store.value).join(',')
      }, {}, true, true)
        .then((res) => {
          let data = res.data;
          if (data?.ack == 1) {
            setName("");
            setDate("");
            handleClose();
          }
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: data?.ack == 1 ? "success" : "error",
              message: data?.msg,
            })
          );
          dispatch(changeLoader(false));
          isSubmit = false;
          setIsSubmit(isSubmit);
        })
        .catch((err) => {
          dispatch(changeLoader(false));
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: "error",
              message: t("somethingWentWrong"),
            })
          );
          isSubmit = false;
          setIsSubmit(isSubmit);
        });
    } else {
      setDateErr(false);
      setNameErr(false);
      isSubmit = false;
      setIsSubmit(isSubmit);
    }
  };


  useEffect(() => {
    getStoreOptions();
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="main-modal maxwidth-430"
    >
      <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h4 className="h4">{isEdit?t('holiday_EditHoliday'):t('holiday_addHoliday')}</h4>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="form-group"
              style={isEdit || (currentUserStoreData.token.role === 'vendor_users')?
                { pointerEvents: 'none', opacity: 0.5 }:
                { pointerEvents: 'auto', opacity: 1 }
              }
            >
              <SearchSelectStore
                disabled={isEdit}
                options={storeOptions}
                value={selectedStores.map((store) => store.value)}
                changeSelectedStores={(e) => {
                  setSelectedStores(storeOptions.filter((store) => e.includes(store.value)));
                }}
              />
            </div>
            <div className="form-group">
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                label={t('holiday_HolidayName')}
              />
              {!name && !nameErr ? <span className="redspan">{t('holiday_holidayName')}</span>: null}
            </div>
            <div className="form-group">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label={t('holiday_date')} inputFormat="MM/DD/YYYY" value={date} onChange={(newValue) => setDate(newValue)}
                  renderInput={(params) => <TextField {...params} />} disablePast={true} orientation="landscape" className="datepicker-icon"
                />
              </LocalizationProvider>
              {!date && !dateErr ? <span className="redspan">{t('holiday_holidayDate')}</span>: null}
            </div>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button onClick={submit} variant="contained" size="large" sx={{ width: 1, marginTop: "0px" }} disabled={isSubmit} >
              {t('holiday_add')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddHoliday;
