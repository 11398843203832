import "./MyOrders.scss";
import PropTypes from "prop-types";
import { useEffect, useLayoutEffect, useState } from "react";
import { Boxed } from "../../Styles-Elements/Box";
import { Tabs, Tab, TextField, FormControl, Select, MenuItem, InputAdornment } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import globalRequest from "../../global-modules/globalRequest";
import NoDataFound from "../../global-modules/EmptyPages/NoDataFound";
import { API, ORDER_STATUS } from "../../Enum";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { currentLocalData } from "../../redux/reducers/localData";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import {
  changeNewOrder,
  currentNewOrder,
} from "../../redux/reducers/myNewOrder";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { changeTab, currentTab } from "../../redux/reducers/orderTab";
import { useTranslation } from "react-i18next";
import searchBlack from "../../assets/images/structure/search-black.svg";
import Orders from "./Orders";
import socket from "../../socket";
import { getCountByStatus, getDataByKeyLocale } from "../../Helpers/Helpers";
import SearchSelectSingleStore from "../../shared/SearchSelectSingleStore";
import OrderItem from "./OrderItem";

import RejectOrder from "../../dialog/RejectOrder";
import ShippingOrder from "../../dialog/ShippingOrder";
import CompleteOrder from "../../dialog/CompleteOrder";
import ReadyToPick from "../../dialog/ReadyToPick";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tab-panel"
      hidden={value !== index}
      id={`vertical-tab-panel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Boxed>{children}</Boxed>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tab-panel-${index}`,
  };
}

const orderTypes = [
  "placed", "processing", "readytopick", "shipped", "completed", "rejected", "cancelled", "pending",
];

const MyOrders = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localData = useSelector(currentLocalData);
  const NewOrder = useSelector(currentNewOrder);
  const limit = 10;

  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [openShippingModel, setOpenShippingModel] = useState(false);
  const [openCompleteModel, setOpenCompleteModel] = useState(false);
  let [selectedOrder, setSelectedOrder] = useState(null);

  let [value, setValue] = useState(0);
  let [search, setSearch] = useState("");
  let [page, setPage] = useState(1);
  let [startDate, setStartDate] = useState(null);
  let [endDate, setEndDate] = useState(null);
  let [data, setData] = useState([]);
  let [totalOrderCount, setTotalOrderCount] = useState(0);
  let [storeOptions, setStoreOptions] = useState([]);
  let [selectedStore, setSelectedStore] = useState(null);

  const getStoreOptions = async () => {
    dispatch(changeLoader(true));
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + localData.token.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    storeOptions = localData.token.role === 'vendor'? [{ label: t("order_allStores"), value: null, secondLabel: "" }]: [];
    if (response.data.ack === 1) {
      storeOptionArray = response.data.stores
        .filter((store) => (store.is_approved === null || store.is_approved === true)).map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address
        }
      });
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    storeOptions = [...storeOptions, ...storeOptionArray];
    setStoreOptions(storeOptions);
    selectedStore = storeOptions[0];
    if (localData.token.role === 'vendor_users') {
      selectedStore = storeOptions.find((store) => store.value === localData.token.store.id);
    }
    if (sessionStorage.getItem('orderSearch')) {
      const searchData = JSON.parse(sessionStorage.getItem('orderSearch'));
      selectedStore = storeOptions.find((store) => store.value === searchData?.store_id);
      setSelectedStore(selectedStore);
    }
    setSelectedStore(selectedStore);
    dispatch(changeLoader(false));
  };

  function convertArrayOfObjectsToCSV(data) {
    const separator = ",";
    // Define the column names
    const columns = [
      "Customer Name",
      "Customer Number",
      "Order Id",
      "No of Items",
      "Total Amount",
      "Payment Method",
      "Payment status",
      "Status",
      "Order Date"
    ];
    // Create the CSV header
    const csvHeader = columns.join(separator);
    // Create the CSV rows
    let csvRows = data.map((row) => {
      return [
        row?.user?.full_name,
        row?.user?.contact_number,
        row?.order_id,
        row?.total_quantity,
        row?.total_amount,
        row?.payment_method,
        row?.payment_status,
        row?.status,
        row?.createdAt
      ].map((cell) => cell === null || cell === undefined ? "" : cell.toString()).join(separator);
    });
    // Combine the header and rows
    const csvContent = [csvHeader, ...csvRows].join("\n");
    return csvContent;
  }

  function downloadCSV(data) {
    const csvContent = convertArrayOfObjectsToCSV(data);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "orders.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const downloadOrderCSV = async () => {
    dispatch(changeLoader(true));
    totalOrderCount = 0;
    setTotalOrderCount(totalOrderCount);
    let storeId = selectedStore ? selectedStore.value : null;
    if (sessionStorage.getItem('orderSearch')) {
      const searchData = JSON.parse(sessionStorage.getItem('orderSearch'));
      storeId = searchData?.store_id;
      sessionStorage.removeItem('orderSearch');
    }
    let params = {
      type: sessionStorage.getItem("orderTab") || orderTypes[value],
      limit: limit,
      page: page,
      store_id: storeId,
      download: "yes"
    };
    if (search !== "") { params.search = search; }
    if (startDate) {
      params.start_date = startDate?.format("YYYY-MM-DD 00:00:00");
      if (endDate) { params.end_date = endDate?.format("YYYY-MM-DD 23:59:59"); } else {
        params.end_date = startDate?.format("YYYY-MM-DD 23:59:59");
      }
    } else {
      const date = new Date();
      params.start_date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`;
      params.end_date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 23:59:59`;
    }
    globalRequest( "get", API.GET_ORDER, {}, { params: params, search }, true, true )
      .then((res) => {
        const data = res?.data;
        if (data?.ack == 1 && data.orders.length > 0) {
          downloadCSV(data.orders);
        } else {
          dispatch(changeSnackbar({
            message: t("order_NoOrderFound"),
            isOpen: true,
            state: "error",
          }));
        }
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(changeLoader(false));
      });
  }

  const getOrder = async () => {
    dispatch(changeLoader(true));
    totalOrderCount = 0;
    setTotalOrderCount(totalOrderCount);
    let storeId = selectedStore ? selectedStore.value : null;
    if (sessionStorage.getItem('orderSearch')) {
      const searchData = JSON.parse(sessionStorage.getItem('orderSearch'));
      storeId = searchData?.store_id;
      sessionStorage.removeItem('orderSearch');
    }
    if (storeId === null && localData.token.role === 'vendor_users') {
      storeId = localData.token.store.id
    }
    let params = {
      type: sessionStorage.getItem("orderTab") || orderTypes[value],
      limit: limit,
      page: page,
      store_id: storeId
    };
    if (search !== "") { params.search = search; }
    if (startDate) {
      params.start_date = startDate?.format("YYYY-MM-DD 00:00:00");
      if (endDate) { params.end_date = endDate?.format("YYYY-MM-DD 23:59:59"); } else {
        params.end_date = startDate?.format("YYYY-MM-DD 23:59:59");
      }
    } else {
      const date = new Date();
      params.start_date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`;
      params.end_date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 23:59:59`;
    }
    globalRequest( "get", API.GET_ORDER, {}, { params: params, search }, true, true )
      .then((res) => {
        const data = res?.data;
        if (data?.ack == 1) {
          setData(data?.orders);
          if (value === 0) {
            dispatch(changeNewOrder(data?.orders?.length > 0));
          }
          totalOrderCount = data?.totalCount;
          setTotalOrderCount(totalOrderCount);
          if (value === 0) {
            dispatch(changeNewOrder(data?.orders?.length > 0));
          }
        }
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(changeLoader(false));
      });
  };

  const handleChange = (event, newValue) => {
    page = 1;
    setPage(page);
    value = newValue;
    setValue(value);
    sessionStorage.setItem("orderTab", orderTypes[value]);
    getOrder();
  };

  const changeOrderStatus = (
    orderId,
    statusType,
    message = "",
    currentType = ORDER_STATUS.PLACED
  ) => {
    dispatch(changeLoader(true));
    let body = {
      status: statusType,
      order_id: orderId,
    };
    if (message) {
      body.reason_of_reject = message;
    }
    let url = API.UPDATE_ORDER_STATUS;
    let method = "post";
    if (currentType == ORDER_STATUS.PENDING) {
      url = API.REJECT_PENDING_ORDER(orderId);
      method = "put";
    }
    globalRequest(method, url, body, {}, true, true)
      .then((res) => {
        let data = res?.data;
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: data?.msg,
            state: data?.ack == 1 ? "success" : "error",
          })
        );
        dispatch(changeLoader(false));
        getOrder();
      })
      .catch((err) => {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("somethingWentWrong"),
            state: "error",
          })
        );
        dispatch(changeLoader(false));
      });
  };

  const clearAllInputs = () => {
    startDate = null;
    setStartDate(startDate);
    endDate = null;
    setEndDate(endDate);
    page = 1;
    setPage(page);
    setSearch("");
    getOrder();

  };

  useLayoutEffect(() => {
    getStoreOptions();
  }, []);

  useEffect(() => {
    try {
      if (localData.token.role === 'vendor') {
        socket.on("newOrderPlaced", (response) => {
          getOrder();
        });
        socket.on("orderStatusChanged", (response) => { getOrder(); });
      } else {
        socket.on("newOrderPlaced", (response) => {
          if (selectedStore?.value === response?.storeId) {
            getOrder();
          }
        });
        socket.on("orderStatusChanged", (response) => {
          if (selectedStore?.value === response?.storeId) {
            getOrder();
          }
        });
      }
    } catch (error) {
      console.error("error in socket at my orders", error);
    }
  }, [socket, selectedStore]);

  useEffect(() => {
    const getData = setTimeout(() => { 
      getOrder()
    }, 500);
    return () => clearTimeout(getData);
  }, [search]);

  useLayoutEffect(() => {
    if (sessionStorage.getItem('orderSearch')) {
      const searchData = JSON.parse(sessionStorage.getItem('orderSearch'));
      search = searchData?.order_id
      setSearch(search);
    }
  }, []);

  return (
    <div className="main-mid-wrapper">
      <div className="order-listing-wrapper">
        <Boxed className="top-flex-row" mb={"20px"}>
          <div className="store-detail-head" style={{ marginBottom: "0px" }}>
            <h3 className="h3" style={{ marginRight: "0px" }}>
              {t("order_myOrders")}
            </h3>
          </div>
          <Boxed
            display={"flex"}
            alignItems={"center"}
            className="filter-search"
          >
            {localData.token.role === 'vendor'?
              <FormControl className="payout-status" sx={{ margin: "0px 0px", width: '231px' }}>
              <SearchSelectSingleStore
                options={storeOptions}
                value={selectedStore}
                handleOptionsChange={(e, value) => {
                  page = 1;
                  setPage(page);
                  selectedStore = value;
                  setSelectedStore(selectedStore);
                  getOrder();
                }}
              />
            </FormControl>: null}
            <div className="searches-block">
              <img src={searchBlack} className="searches-icon" alt="" />
              <TextField
                onChange={(e) => {
                  page = 1;
                  setPage(page);
                  setSearch(e.target.value);
                }}
                type="search"
                value={search}
                id="outlined-basic"
                sx={{ width: "100%", backgroundColor: "#fff" }}
                className="searches-input"
                variant="outlined"
                placeholder={t("search")}
              />
            </div>
            <div className="mr-20">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="calender-icon width160 custom-label"
                  displayEmpty
                  value={startDate}
                  label={t("order_fromDate")}
                  onChange={(newValue) => {
                    page = 1;
                    setPage(page);
                    startDate = newValue;
                    setStartDate(startDate);
                    getOrder();
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  renderInput={(params) => (
                    <TextField {...params} size={"small"} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </div>
            {startDate ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="calender-icon width160 custom-label"
                  displayEmpty
                  minDate={startDate}
                  maxDate={
                    new Date(startDate).setDate(new Date(startDate).getDate() + 6)
                  }
                  value={endDate}
                  label={t("order_toDate")}
                  onChange={(newValue) => {
                    page = 1;
                    setPage(page);
                    endDate = newValue;
                    setEndDate(endDate);
                    getOrder();
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  renderInput={(params) => (
                    <TextField {...params} size={"small"} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            ) : null}

            {search || startDate ? (
              <span className="clear-all" onClick={() => clearAllInputs()}>
                Clear
              </span>
            ) : null}
            {localData.token.store.businessTypeId == 97?<span className="clear-all" onClick={downloadOrderCSV}>
                Download CSV
              </span>: null}
          </Boxed>
        </Boxed>

        <div className="">
          <div className="tabs-wrap">
            <div
              className=""
              style={{
                borderBottom: "1px solid #E3E3E3",
                marginBottom: "12px",
              }}
            >
              <Tabs
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                onChange={handleChange}
              >
                <Tab
                  className={NewOrder ? "new-dot" : ""}
                  label={`
                  ${t("order_new")} ${value == 0 ? `(${totalOrderCount})` : ""}
                `}
                  {...a11yProps(0)}
                />
                <Tab
                  label={`
                  ${t("order_processing")} ${
                    value == 1 ? `(${totalOrderCount})` : ""
                  }
                `}
                  {...a11yProps(1)}
                />
                <Tab
                  label={`
                  ${t("order_readyToPick")} ${
                    value == 2 ? `(${totalOrderCount})` : ""
                  }
                `}
                  {...a11yProps(2)}
                />
                <Tab
                  label={`
                  ${t("order_shipped")} ${
                    value == 3 ? `(${totalOrderCount})` : ""
                  }
                `}
                  {...a11yProps(3)}
                />
                <Tab
                  label={`
                  ${t("order_completed")} ${
                    value == 4 ? `(${totalOrderCount})` : ""
                  }
                `}
                  {...a11yProps(4)}
                />
                <Tab
                  label={`
                  ${t("order_rejected")} ${
                    value == 5 ? `(${totalOrderCount})` : ""
                  }
                `}
                  {...a11yProps(5)}
                />
                <Tab
                  label={`
                  ${t("order_cancelled")} ${
                    value == 6 ? `(${totalOrderCount})` : ""
                  }
                `}
                  {...a11yProps(6)}
                />
                <Tab
                  label={`
                  ${t("order_pending")} ${
                    value == 7 ? `(${totalOrderCount})` : ""
                  }
                `}
                  {...a11yProps(7)}
                />
              </Tabs>

              {data.length > 0 ? (
                data.map((order, index) => (
                  <OrderItem
                    type={orderTypes[value]}
                    order={order}
                    key={`order-${index}`}
                    getOrders={getOrder}
                    storeOptions={storeOptions}
                    rejectOrder={(order) => {
                      selectedOrder = order;
                      setSelectedOrder(selectedOrder);
                      setOpenRejectModel(true);
                    }}
                  />
                ))
              ) : <NoDataFound text={t("order_NoOrderFound")} />}

              <div className="pagination-wrap">
                {Math.ceil(totalOrderCount / limit) > 1 ? (
                  <Pagination
                    count={
                      Math.ceil(totalOrderCount / limit) < 1
                        ? 1
                        : Math.ceil(totalOrderCount / limit)
                    }
                    page={page}
                    onChange={(e, val) => {
                      page = val;
                      setPage(page);
                      getOrder();
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {openRejectModel ? (
        <RejectOrder
          isOpen={openRejectModel}
          handleClose={() => setOpenRejectModel(false)}
          submit={(message) => {
            if (selectedOrder?.type == ORDER_STATUS.PLACED) {
              changeOrderStatus(
                selectedOrder?.id,
                ORDER_STATUS.REJECTED,
                message,
                ORDER_STATUS.PLACED
              );
            } else {
              changeOrderStatus(
                selectedOrder?.id,
                ORDER_STATUS.REJECTED,
                message,
                ORDER_STATUS.PENDING
              );
            }
            setOpenRejectModel(false);
          }}
        />
      ) : null}

    </div>
  );
};

export default MyOrders;
