import { useEffect, useState } from "react";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import globalRequest from "../../global-modules/globalRequest";
import { API, PAGES } from "../../Enum";
// import SearchSelectStore from "../../shared/SearchSelectStore";
import SearchSelectSingleStore from "../../shared/SearchSelectSingleStore";
import arrowLeftBlack from "../../assets/images/structure/arrow-left-black.svg";
import { PhoneMask } from "../../global-modules/MaskedInputs";
import { useSelector, useDispatch } from "react-redux";

import { currentLocalData } from "../../redux/reducers/localData";
import { changeLoader } from "../../redux/reducers/loader";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import { changePage } from "../../redux/reducers/page";
import { currentStorePayoutsData } from "../../redux/reducers/storePayouts";
import { getDataByKeyLocale } from "../../Helpers/Helpers";
import OverrideModal from "../../dialog/OverrideModal";
import SearchSelectStore from "../../shared/SearchSelectStore";

const ManagePayoutsDetail = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUserStoreData = useSelector(currentLocalData);
  const payoutData = useSelector(currentStorePayoutsData);
  let [storeOptions, setStoreOptions] = useState([]);
  let [selectedStores, setSelectedStores] = useState(null);
  let [beneficiaryName, setBeneficiaryName] = useState("");
  let [beneficiaryNameErr, setBeneficiaryNameErr] = useState('');
  let [bankAccountNumber, setBankAccountNumber] = useState("");
  let [bankAccountNumberErr, setBankAccountNumberErr] = useState('');
  let [iban, setIban] = useState("");
  let [ibanErr, setIbanErr] = useState('');
  let [swiftCode, setSwiftCode] = useState("");
  let [swiftCodeErr, setSwiftCodeErr] = useState('');
  const [showOverwrite, setShowOverwrite] = useState(false);
  let [overwrite, setOverwrite] = useState(false);
  let [affectedStores, setAffectedStores] = useState([]);

  const getStorePayouts = async () => {
    if (payoutData && payoutData?.store_id !== null) {
      selectedStores = storeOptions.find((store) => store.value === payoutData?.store_id);
      setSelectedStores(selectedStores);
    } else {
      selectedStores = storeOptions;
      setSelectedStores(selectedStores);
    }
    setBeneficiaryName(payoutData?.store_payout?.beneficiaryName);
    setBankAccountNumber(payoutData?.store_payout?.bankAccountNumber);
    setIban(payoutData?.store_payout?.iban);
    setSwiftCode(payoutData?.store_payout?.swiftCode);
  };

  const handleSave = async () => {
    let isValid = true;
    if (beneficiaryName.trim() === "") {
      setBeneficiaryNameErr("Please enter beneficiary name");
      isValid = false;
    }
    if (bankAccountNumber.trim() === "") {
      setBankAccountNumberErr("Please enter bank account number");
      isValid = false;
    }
    if (iban.trim() === "") {
      setIbanErr("Please enter iban");
      isValid = false;
    }
    if (swiftCode.trim() === "") {
      setSwiftCodeErr("Please enter swift code");
      isValid = false;
    }

    if (isValid) {
      let url = API.MANAGE_PAYOUTS.SAVE_PAYOUT_DETAIL_FOR_STORE(payoutData?.store_payout?.id);
      let data = {
        beneficiaryName: beneficiaryName,
        bankAccountNumber: bankAccountNumber,
        iban: iban,
        swiftCode: swiftCode
      };
      if (payoutData?.type === 'edit-all' || payoutData?.type === 'add-all') {
        url = API.MANAGE_PAYOUTS.SAVE_PAYOUT_DETAIL_FOR_ALL_STORE
        data.overwrite = overwrite ? 1: 0;
        data.storeId = selectedStores.map((store) => store.value).join(',');
      }

      globalRequest('post', url, data, {}, true, true)
      .then((res) => {
        let data = res.data;
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: data?.ack == 1 ? "success" : "error",
            message: data?.msg,
          })
        );
        dispatch(changeLoader(false));
        if (data?.ack == 1) {
          dispatch(changePage(PAGES.MANAGE_PAYOUTS));
        }
      }).catch((error) => {
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t('somethingWentWrong'),
          })
        );
      });

    }

  };

  const checkOverwrite = async () => {
    dispatch(changeLoader(true));
    const storeIds = selectedStores.map((store) => store.value);
    if (storeIds.length === 0) {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: t('manageStore_SelectAtLeastOneStore'),
      }));
      dispatch(changeLoader(false));
      return;
    }
    let isValid = true;
    if (beneficiaryName === "" || beneficiaryName === null || beneficiaryName === undefined) {
      setBeneficiaryNameErr("Please enter beneficiary name");
      isValid = false;
    }
    if (bankAccountNumber === "" || bankAccountNumber === null || bankAccountNumber === undefined) {
      setBankAccountNumberErr("Please enter bank account number");
      isValid = false;
    }
    if (iban === "" || iban === null || iban === undefined) {
      setIbanErr("Please enter iban");
      isValid = false;
    }
    if (swiftCode === "" || swiftCode === null || swiftCode === undefined) {
      setSwiftCodeErr("Please enter swift code");
      isValid = false;
    }
    if (!isValid) {
      dispatch(changeLoader(false));
      return;
    } else  {

      globalRequest("post", API.MANAGE_PAYOUTS.CHECK_STORE_OVERWRITE, {
        storeId: storeIds.join(',')
      }, {}, true, true)
        .then(async (res) => {
          let data = res.data;
          if (data?.ack == 1) {
            if (data.overwrite) {
              affectedStores = data?.affectedStores?.map(
                (store) => getDataByKeyLocale(store.stores_locales, 'business_name')
              );
              setAffectedStores(affectedStores)
              setShowOverwrite(true);
            } else {
              handleSave();
            }
          } else {
            dispatch(
              changeSnackbar({
                isOpen: true,
                state: "error",
                message: data?.msg,
              })
            );
          }
        }).catch((err) => {
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: "error",
              message: t("somethingWentWrong"),
            })
          );
        });
    }
    dispatch(changeLoader(false));
  }

  const getStoreOptions = async () => {
    dispatch(changeLoader(true));
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + currentUserStoreData.token.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    storeOptions = []
    if (response.data.ack === 1) {
      storeOptions = [{ label: "All Stores", value: 0, secondLabel: "" }]
      storeOptionArray = response.data.stores
      .filter((store) => store.is_approved === null || store.is_approved === true)
      .map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address
        }
      });
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    storeOptions = [...storeOptions, ...storeOptionArray];
    setStoreOptions(storeOptions);
    dispatch(changeLoader(false));
    getStorePayouts();
  };
  
  useEffect(() => {
    getStoreOptions();
  }, [payoutData]);

  return (
    <div className="main-mid-wrapper">
      <div className="store-detail-wrapper  max-w-810">
        <form 
        onSubmit={(e) => {
          e.preventDefault();

          if (payoutData?.type === 'edit-all' || payoutData?.type === 'add-all') {
            checkOverwrite();
          } else {
            handleSave();
          }
          
        }}
        >
          <Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={"20px"}
          >
            <div className="store-detail-head" style={{ marginBottom: "0px" }}>
              <img src={arrowLeftBlack} alt="" className="rotate180"
                onClick={() => {
                  dispatch(changePage(PAGES.MANAGE_PAYOUTS));
                }}
              />
              <h3 className="h3" style={{ marginRight: "0px" }}>
              {
                payoutData?.type === 'add-all'?
                  t("manageStore_AddPayout"):
                  payoutData?.type === 'edit-all'?
                    t("manageStore_EditPayout"):
                    t("manageStore_EditPayout") + ' | ' + payoutData?.store_name
              }
              </h3>
            </div>
          </Boxed>
          <div className="card-white">
            <div className="business-detail-wrap">
              <h6 className="h6">{t("manageStore_PayoutDetails")}</h6>
              <p className="p3">
                {t("manageStore_ThePayoutDetailsAreTheBankDetailsInWhichYouAreExpectingThePayments")}
              </p>
              <div className="signup-right max-w-548">
                <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="form-group">
                    {payoutData && payoutData?.store_id !== null?
                    <SearchSelectSingleStore
                      options={storeOptions}
                      value={selectedStores}
                      disabled={true}
                    />:
                    <SearchSelectStore
                      options={storeOptions}
                      value={selectedStores?.map((store) => store.value)}
                      changeSelectedStores={(e) => {
                        setSelectedStores(storeOptions.filter((store) => e.includes(store.value)));
                      }}
                    />}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="form-group">
                    <TextField
                      value={beneficiaryName}
                      onChange={(e) => {
                        setBeneficiaryName(e.target.value);
                        setBeneficiaryNameErr('');
                      }}
                      label={t("manageStore_beneficiaryName") + " *"}
                    />
                      <span className="redspan">{beneficiaryNameErr}</span>
                  </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="form-group">
                    <TextField
                      value={bankAccountNumber}
                      onChange={(e) => {
                        setBankAccountNumber(e.target.value)
                        setBankAccountNumberErr('');
                      }}
                      label={t("manageStore_bankAccountNumber") + " *"}
                    />
                    <span className="redspan">
                      {bankAccountNumberErr}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="form-group">
                    <TextField
                      value={iban}
                      onChange={(e) => {
                        setIban(e.target.value)
                        setIbanErr('');
                      }}
                      
                      label={t("manageStore_iban") + " *"}
                    />
                    <span className="redspan">
                      {ibanErr}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="form-group">
                    <TextField
                      value={swiftCode}
                      onChange={(e) => {
                        setSwiftCode(e.target.value)
                        setSwiftCodeErr('');
                      }}
                      label={t("manageStore_swiftCode") + " *"}
                    />
                    <span className="redspan">
                      {swiftCodeErr}
                    </span>
                  </div>
                </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Button
              variant="contained"
              size="large"
              sx={{ width: "150px" }}
              type="submit"
              className="date-picker-btn"
            >
              {" "}
              {t("manageUsers_save")}{" "}
            </Button>
          </Boxed>
        </form>
      </div>

      {showOverwrite?
        <OverrideModal
          handleClose={() => setShowOverwrite(false)}
          message={t('manageStore_DoYouWantToOverridePayoutText', {
            noOfStores: `
              ${affectedStores?.length > 1?
                affectedStores?.length + " stores": affectedStores?.length + " store"} (
              ${affectedStores?.length > 4?
                affectedStores?.splice(0, 4).join(', ') + '...' :affectedStores?.join(', ')
              })`
          })}
          handleNo={() => {
            setShowOverwrite(false);
            overwrite = false;
            setOverwrite(overwrite);
            handleSave();
          }}
          handleYes={() => {
            setShowOverwrite(false);
            overwrite = true;
            setOverwrite(overwrite);
            handleSave();
          }}
        />: null}
    </div>
  );
};

export default ManagePayoutsDetail;
