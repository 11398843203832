import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import strings from "../localisation_en.json";
import modalClose from "../assets/images/structure/close-black.svg";
import { useEffect, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import { VisibilityOff } from "@mui/icons-material";
import globalRequest from "../global-modules/globalRequest";
import { API } from "../Enum";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import errorMessage from "../global-modules/errorMessage";
import { useTranslation } from "react-i18next";

const UpdatePassword = ({ isOpen = false, handleClose, data, openLogin }) => {

  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOpen) {
      setPassword("");
      setConfirmPassword("");
      setShowPassword(false);
      setShowConfirmPassword(false);
    }
  }, [isOpen]);

  const handleSubmit = (e) => {
    if (!password) {
      setPasswordErr(false);
    }
    if (!confirmPassword) {
      setConfirmPasswordErr(false);
    }
    if (password === confirmPassword && password && confirmPassword && password.length >= 8) {
      dispatch(changeLoader(true));
      globalRequest(
        "post",
        API.FORGET_UPDATE_PASSWORD,
        {
          code: data?.code,
          user_id: data?.user_id,
          password: password,
        },
        {},
        false
      )
        .then((res) => {
          let data = res?.data;
          if (data?.ack == 1) {
            openLogin();
          }
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: data?.msg ? data?.msg : data?.errMsg[0]?.password,
              state: data?.ack == 1 ? "success" : "error",
            })
          );
          dispatch(changeLoader(false));
        })
        .catch((e) => {
          dispatch(changeLoader(false));
        });
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} className="main-modal maxwidth-430">
      <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h2 className="h3 text-center">{t('forgot_updatePassword')}</h2>
        <p className="p3 text-center">{t('forgot_mustIncludePasswordText')}</p>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="form-group">
              <FormControl variant="outlined" required>
                <InputLabel htmlFor="outlined-adornment-password">{t('forgot_password')}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => { setPassword(e.target.value); }}
                  required={true}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => setShowPassword(!showPassword)}
                        onMouseDown={(e) => {e.preventDefault();}}
                        edge="end"
                      >{!showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                    </InputAdornment>
                  }
                  label={t("forgot_password")}
                />
              </FormControl>
              {!passwordErr && !password ?
                <span className="errorspan">{t('holiday_password')} </span>
              : null}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="form-group">
              <FormControl variant="outlined" required>
                <InputLabel htmlFor="outlined-adornment-password">
                  {t('forgot_confirmPassword')}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  required={true}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => setShowConfirmPassword(!showConfirmPassword)}
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                        edge="end"
                      >
                        {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t('forgot_confirmPassword')}
                />
              </FormControl>
              {!confirmPasswordErr && !confirmPassword ? (
                <span className="errorspan">{t('holiday_enter_confirm_password')} </span>
              ) : null}

              {password && confirmPassword && password !== confirmPassword ? (
                <span className="errorspan">
                  {t('holiday_password_confirm_password_does_not_match')}{" "}
                </span>
              ) : null}

              {password && confirmPassword && password == confirmPassword && password.length < 8 ? (
                <span className="errorspan">{t('holiday_password_8_digit')} </span>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              onClick={handleSubmit}
              variant="contained"
              size="large"
              sx={{ width: 1, marginTop: "16px" }}
            >
              {t('forgot_update')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePassword;
