import addDeleteGetLocalStorage from "../../global-modules/addDeleteGetLocalStorage";
import { createSlice } from "@reduxjs/toolkit";
import { STORAGE } from "../../Enum";
import { decodeToken } from "react-jwt";

const getLocalData = () => {
  let token = addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single");
  return token? decodeToken(token) : null;
};

const getManageWorkingHour = () => {
  let data = addDeleteGetLocalStorage(STORAGE.MANAGE_WORKING_HOUR, {}, "get", "single");
  return data? data : null;
};

const getData = () => {
  return {
    token: getLocalData(),
    manage_working_hour: getManageWorkingHour(),
  };
};

export const localData = createSlice({
  name: "localData",
  initialState: {
    value: getData(),
  },
  reducers: {
    changeLocalData: (state, action) => {
      state.value = getData();
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeLocalData } = localData.actions;
export const currentLocalData = (state) => state.localData.value;
export default localData.reducer;
