import React, { useEffect, useState } from "react";
import "./ManageWorkingHours.scss";
import { Button, Grid, Divider, IconButton, Checkbox, InputLabel, Select, MenuItem, FormControl, OutlinedInput } from "@mui/material";
import { Boxed } from "../../../Styles-Elements/Box";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { currentLocalData } from "../../../redux/reducers/localData";
import globalRequest from "../../../global-modules/globalRequest";
import { API, PAGES } from "../../../Enum";
import { changeLoader } from "../../../redux/reducers/loader";
import skipReg from "../../../global-modules/skipReg";
import { changeLocalData } from "../../../redux/reducers/localData";
import { changePage } from "../../../redux/reducers/page";
import { useTranslation } from "react-i18next";
import plusIcon from "../../../assets/images/structure/plus-icon.svg";
import minusIcon from "../../../assets/images/structure/minus-icon.svg";
import {
  generateHours, convertTimeTo12Hour, getDataByKeyLocale
} from '../../../Helpers/Helpers'

const storeNames = [
  'Anand General Store',
  'Anand General Store1',
];
const ManageWorkingHours = () => {
  const dispatch = useDispatch();
  const allHours = generateHours(15);
  const { t } = useTranslation();
  const currentUserStoreData = useSelector(currentLocalData);
  const [personName, setPersonName] = React.useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState(null);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const [dayOpenClose, setDayOpenClose] = useState([
    {
      index: 0,
      day: "sunday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }, {
      index: 1,
      day: "monday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }, {
      index: 2,
      day: "tuesday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }, {
      index: 3,
      day: "wednesday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }, {
      index: 4,
      day: "thursday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }, {
      index: 5,
      day: "friday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }, {
      index: 6,
      day: "saturday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }
  ]);
  let [open247, setOpen247] = useState(false);
  let [syncDays, setSyncDays] = useState(false);

  const addTime = (index) => {
    let temp = [...dayOpenClose];
    const prevEndTime = temp[index].time[temp[index].time.length -1].endTime
    let newStartTime = generateHours(15).find(time => parseInt(time.split(':').join('')) > parseInt(prevEndTime.split(':').join('')))
    let newEndTime = generateHours(60).find(time => parseInt(time.split(':').join('')) > parseInt(newStartTime.split(':').join('')))
    temp[index].time.push({
      startTime: newStartTime,
      endTime: newEndTime,
      startTimeError: "",
      endTimeError: ""
    });
    setDayOpenClose(temp);
  };

  const subTime = (index, index2) => {
    let temp = [...dayOpenClose];
    temp[index].time.splice(index2, 1);
    setDayOpenClose(temp);
  }

  const changeTime = (e, type, index, index1) => {
    let temp = [...dayOpenClose];
    if (type === 'start') {
      if (
        index1 > 0 &&
        parseInt(temp[index].time[index1 - 1].endTime.split(':').join('')) > parseInt(e.target.value.split(':').join(''))
      ) {
        temp[index].time[index1].startTimeError = t('manageStore_shiftTimeAreOverlapping');
      } else {
        temp[index].time[index1].startTimeError = '';
      }
      temp[index].time[index1].startTime = e.target.value;
    } else {
      if (
        parseInt(temp[index].time[index1].startTime.split(':').join('')) > parseInt(e.target.value.split(':').join(''))
      ) {
        temp[index].time[index1].endTimeError = t('manageStore_CloseTimeShouldBeGreater');
      } else if ( parseInt(temp[index].time[index1 + 1]?.startTime.split(':').join('')) < parseInt(e.target.value.split(':').join(''))) {
        temp[index].time[index1].endTimeError = t('manageStore_shiftTimeAreOverlapping');
      } else {
        temp[index].time[index1].endTimeError = ''
      }
      temp[index].time[index1].endTime = e.target.value;
    }
    setDayOpenClose(temp);
  }

  const checkOpen274 = (e) => {
    setOpen247(e.target.checked);
    if (e.target.checked) {
      let temp = dayOpenClose.map(workHours => {
        let obj = workHours;
        obj.open = true;
        obj.sync = false;
        obj.time = [{
          startTime: "00:00",
          endTime: "23:59",
          startTimeError: "",
          endTimeError: ""
        }]
        return obj;
      })
      setDayOpenClose(temp);
    }
  }

  const handleDayCheck = (e, index) => {
    const temp = dayOpenClose.map((day, ind) => {
      let obj = day;
      if (index === ind) {
        obj.open = e.target.checked
      }
      return obj
    });
    setDayOpenClose(temp);
  }

  const handleSync = (ind) => {
    setSyncDays(true)
    const checkedTime = dayOpenClose.find((day, index) => index === ind)?.time;
    let temp = dayOpenClose.map((day) => {
      let obj = day;
      obj.time = [...checkedTime];
      return obj;
    });
    setDayOpenClose(temp)
    setSyncDays(false)
  }

  
  const getStoreDetails = () => {
    let params = {
      params: {
        // limit: 20,
        // page: 1,
        sort_by: "id",
        order_by: "desc",
        search: "",
      },
    };
    dispatch(changeLoader(true));
    globalRequest("GET", API.GET_ALL_MY_STORES, {}, params, true, true)
      .then((res) => {
        const data = res?.data;
        if (data.ack == 1) {
          setStores(data.stores);
          setSelectedStores(currentUserStoreData.token.store.id)
        }
        dispatch(changeLoader(false));
      })
      .catch((e) => {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
      });
  };

  const submit = async (e) => {
    e.preventDefault();
    let bool = true;
    let days = [];
    let a = [];
    if (open247) {
      dayOpenClose.forEach((obj, ind) => {
        a.push({
          day: obj.day,
          open: obj.open,
          time: [{
            starttime: '00:00',
            endtime: '24:00'
          }]
        });
      })
    } else {
      dayOpenClose.forEach((obj, ind) => {
        a.push({
          day: obj.day,
          open: obj.open,
          time: obj.time.map((shift) => {
            if (shift.startTimeError !== '' || shift.endTimeError !== '') {
              bool = false;
            }
            return {
              starttime: shift.startTime,
              endtime: shift.endTime
            }
          })
        });
      });
    }
    if (bool) {
      dispatch(changeLoader(true));
      globalRequest(
        "post",
        API.MANAGE_WORKING_HOUR,
        {
          hoursdata: JSON.stringify(a),
          open247: open247 ? 1: 0
        },
        {}, true, true
      ).then(async (res) => {
        let data = res.data;
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: data?.ack == 1 ? "success" : "error",
            message: data?.msg,
          })
        );
        dispatch(changeLoader(false));
        if (data?.ack == 1) {
          await skipReg(1);
          dispatch(changeLocalData());
          dispatch(changePage(PAGES.MANAGE_HOLIDAY));
        }
      }).catch((err) => {
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
        dispatch(changeLoader(false));
      });
    } else {
      dispatch(
        changeSnackbar({
          isOpen: true,
          state: "error",
          message: t('manageStore_shiftTimeAreOverlapping'),
        })
      );
    }
  };

  useEffect(() => {
    getStoreDetails();
  }, []);

  return (
    <>
      <div className="manage-workinghours-wrapper">
        <div className="manage-workinghours-head">
          <h3 className="h3">{t("accountSetup_completeYourAccountSetup")}</h3>
        </div>
        <div className="container-body">
          <Grid container columnSpacing={{ xs: 0, sm: 1, md: 1, lg: 1 }}>
            <Grid item xs={12} sm={12} md={10} mx={"auto"}>
              <form onSubmit={submit}>
                <div className="working-hours-block">
                  <div className="card-white">
                    <h4 className="h4">{t("accountSetup_manageWorkingHours")}</h4>
                    <p className="p2">{t("accountSetup_theWorkingHoursWillMakeYourStoreAutomatically")}</p>
                    <div className="signup-right">
                      <Grid item={true} xs={12} sm={12} md={10} lg={9} xl={7} mr={"auto"}>
                        <Grid item={true} xs={12} sm={5} >
                           <div className="form-group">
                            <FormControl disabled="true">
                              <InputLabel id="demo-multiple-name-label">Stores*</InputLabel>
                              <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                
                                value={selectedStores}
                                onChange={handleChange}
                                input={<OutlinedInput label="Stores" />}
                              >
                                {stores.map((store, index) => (
                                  <MenuItem
                                    key={`store-${index}`}
                                    value={store.id}
                                  >
                                    {getDataByKeyLocale(store.stores_locales, 'business_name')}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} sm={3}>
                        <h6 className="h6 mb-4">{t("accountSetup_SetWorkingHours")}</h6>
                          <div className="checkbox-block">
                            <Checkbox
                              value={open247}
                              checked={open247}
                              onChange={checkOpen274}
                            />
                            <Boxed className="">
                              <span style={{ textTransform: "capitalize" }} className="p2">
                                {t('manageStore_Open247')}
                              </span>
                            </Boxed>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
                    <Grid
                      container
                      columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4 }}
                      className={`${open247?'disable-hours':''}`}
                    >
                      {dayOpenClose.map((obj, ind) => {
                        return (
                          <Grid key={ind} item xs={12} sm={12} md={12} lg={12} mr={"auto"} >
                            <Grid
                              container
                              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}
                            >
                              <Grid
                                item
                                xs={3.6} sm={3} md={3} lg={3} display={"flex"} alignItems={"center"}
                              >
                                <div className="checkbox-block">
                                  <Checkbox
                                    value={obj.open}
                                    onChange={(e) => {handleDayCheck(e, ind)}}
                                    checked={obj.open}
                                  />
                                  <Boxed className="">
                                    <span style={{ textTransform: "capitalize" }} className="p2">{obj.day}</span>
                                  </Boxed>
                                </div>
                              </Grid>
                              <Grid item={true} xs={12} sm={9}>

                              {obj?.time?.map((time, indexC) =>
                                <Grid container={true} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }} mb={1} key={`times-${obj.day}-${indexC}`}>
                                  <Grid item 
                                    xs={4.2} sm={4} md={4} lg={4}>
                                    <div className="form-control clock-picker">
                                      <InputLabel id="openTime">{t("accountSetup_openTime")}</InputLabel>
                                      <Select
                                        disabled={!obj.open}
                                        labelId="openTime"
                                        label={t("accountSetup_openTime")}
                                        value={time.startTime}
                                        onChange={(e) => { changeTime(e, 'start', ind, indexC); }}
                                      >
                                        {allHours.map((obj1, ind) =>  <MenuItem key={ind} value={obj1}> {convertTimeTo12Hour(obj1)} </MenuItem> )}
                                      </Select>
                                      <span className="redspan">{time.startTimeError}</span>
                                    </div>
                                  </Grid>
                                  <Grid item 
                                    xs={4.2} sm={4} md={4} lg={4}>
                                    <div className="form-control clock-picker">
                                      <InputLabel id="closeTime">
                                        {t("accountSetup_closeTime")}
                                      </InputLabel>
                                      <Select
                                        disabled={!obj.open}
                                        labelId="closeTime"
                                        label={t("accountSetup_closeTime")}
                                        value={time.endTime}
                                        onChange={(e) => { changeTime(e, 'end', ind, indexC); }}
                                      >
                                        {allHours.map((obj1, ind) => {
                                          return (
                                            <MenuItem key={ind} value={obj1}>
                                              {convertTimeTo12Hour(obj1)}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                      <span className="redspan">{time.endTimeError}</span>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} sm={2}>
                                    {indexC === 0?
                                      obj?.time[obj?.time.length - 1].endTime !== '23:59'?<IconButton onClick={() => {addTime(ind);}} sx={{mt: 1}}><img src={plusIcon} alt="plus icon"  /></IconButton>: null:
                                    <IconButton onClick={() => {subTime(ind, indexC);}} sx={{mt: 1}}><img src={minusIcon} alt="minus icon"  /></IconButton>}
                                  </Grid>
                                </Grid>)}


                              </Grid>
                            </Grid>

                            <Grid container={true} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }} mb={3}>
                              <Grid item={true} xs={12} sm={3} display={"flex"} alignItems={"center"}></Grid>
                              <Grid item={true} xs={12} sm={9} display={"flex"} alignItems={"center"}>
                              {!syncDays?<span className="p2 mb-0 text-primary"
                                style={{ textTransform: "capitalize", cursor: 'pointer', }}
                                onClick={() => { handleSync(ind) }}
                              >{t('manageStore_syncTo', {day: obj.day})}</span>:<span> {t('manageStore_syncIngTo', {day: obj.day})} </span>}
                              </Grid>
                            </Grid>
                          </Grid>
                          
                        );
                      })}
                    </Grid>
                  </div>
                  <Boxed className="text-right-left">
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ width: "150px", marginTop: "15px" }}
                      className="date-picker-btn"
                    >
                      {t("accountSetup_continue")}
                    </Button>
                  </Boxed>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ManageWorkingHours;
