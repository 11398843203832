import { Boxed } from "../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogContent, List, ListItem, ListItemText } from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg";

const month = [
  "January", "Febuary", "March", "April", "May", "June",
  "July", "Auguest", "September", "October", "November", "Decmber",
];

const StoreList = ({ handleClose, holiday, stores, storeId }) => {

	const dates = (da) => {
    let d = new Date(da);
    let day = d.getDate();
    return `${day < 10 ? `0${day}` : day} ${month[d.getMonth()]} ${d.getFullYear()}`;
  };

  const { t } = useTranslation();

  return (
    <Dialog open={true} onClose={handleClose} className="main-modal maxwidth-430" >
      <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
      <DialogContent>

        <h4 className="h4" style={{ marginBottom: "10px",marginTop: '0px' }}>{holiday.holidayName}</h4>
        <p className="p3" style={{ marginBottom: "16px" }}>{dates(holiday.date)}</p>

				{stores.filter((store) => storeId.includes(store.value.toString())).map((store, index) => 
          <List className="holiday-list">
						<ListItem key={`store-${index}`}>
							<ListItemText
								primary={store.label}
								secondary={store.secondLabel}
							/>
						</ListItem>
					</List>
				)}
        <Boxed>
          <Button type="button" variant="contained" sx={{ width: "100%", marginTop: "20px" }}
						onClick={handleClose}
					> {t("manageStore_close")} </Button>
        </Boxed>
      </DialogContent>
    </Dialog>
  );
};

export default StoreList;
